import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

// NOTE(cg): Tailwind searches code for exact string matches to generate used classes. So we need the full strings
//           and can't dynamically build them. (e.g. `bg-{{ colour }}/10`) :(
const ICON_COLOUR_MAP = {
  'blue': 'fill-blue',
  'green': 'fill-green',
  'purple': 'fill-purple',
  'yellow': 'fill-yellow',
  'red': 'fill-red',
  'gray-600': 'fill-gray-600',
  'gray-800': 'fill-gray-800',
}
const ICON_DEFAULT = 'fill-gray-100';

const ICON_BG_COLOUR_MAP = {
  'blue': 'bg-blue/10',
  'green': 'bg-green/10',
  'purple': 'bg-purple/10',
  'yellow': 'bg-yellow/10',
  'red': 'bg-red/10',
  'gray-600': 'bg-gray-600/10',
  'gray-800': 'bg-gray-800/10',
}
const ICON_BG_DEFAULT = 'bg-gray-100/10';

const HOVER_BG_COLOUR_MAP = {
  'blue': 'hover:bg-blue/5',
  'green': 'hover:bg-green/5',
  'purple': 'hover:bg-purple/5',
  'yellow': 'hover:bg-yellow/5',
  'red': 'hover:bg-red/5',
  'gray-600': 'hover:bg-gray-600/5',
  'gray-800': 'hover:bg-gray-800/5',
}
const HOVER_BG_DEFAULT = 'hover:bg-gray-100/5';

@Directive({
  selector: '[dashStatCardCount]',
})
export class StatCardCountDirective {
  constructor(public templateRef: TemplateRef<unknown>) { }
}

@Directive({
  selector: '[dashStatCardTitle]',
})
export class StatCardTitleDirective {
  constructor(public templateRef: TemplateRef<unknown>) { }
}

@Component({
  selector: 'dash-stat-card',
  templateUrl: './stat-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex items-center px-4 space-x-4 bg-white',
  },
})
export class StatCardComponent implements OnChanges {
  @ContentChild(StatCardCountDirective) countContent?: StatCardCountDirective;
  @ContentChild(StatCardTitleDirective) titleContent?: StatCardTitleDirective;

  @Input() icon: string;
  @Input() extraIconClasses?: string;
  @Input() extraIconBackgroundClasses?: string;
  @Input() colour: string;
  @Input() count?: string | number;
  @Input() extraCount?: string | number;
  @Input() title: string;

  @Input() loading: boolean = false;

  @Input() clickable: boolean;
  @Input() active?: boolean;

  iconBackgroundClasses = ICON_BG_DEFAULT;
  iconSvgClasses = `w-6 h-6 ${ICON_DEFAULT}`;

  @HostBinding('class')
  get hostBindingHoverCursor() {
    const classes: string[] = [];
    if (this.clickable && !this.loading) {
      classes.push('transition-colors');
      classes.push('hover:cursor-pointer');
      classes.push(HOVER_BG_COLOUR_MAP[this.colour] ?? HOVER_BG_DEFAULT);
    }
    return classes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let buildIconClasses = false;
    if ('colour' in changes) {
      buildIconClasses = true;
    }
    if ('extraIconClasses' in changes) {
      buildIconClasses = true;
    }
    if ('extraBackgroundClasses' in changes) {
      buildIconClasses = true;
    }

    if (buildIconClasses) {
      const colour = this.colour;
      const iconBgClass = ICON_BG_COLOUR_MAP[colour] ?? ICON_BG_DEFAULT;
      const iconColorClass = ICON_COLOUR_MAP[colour] ?? ICON_DEFAULT;

      this.iconBackgroundClasses = `${iconBgClass} ${this.extraIconBackgroundClasses}`;
      this.iconSvgClasses = `w-6 h-6 ${iconColorClass} ${this.extraIconClasses}`;
    }
  }
}
