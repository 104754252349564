import { PageableOptions, pageableOptionsToHttpParams } from '@aa/helpers/pageable';
import { FishConfirmation } from '@aa/models/fish-confirmation';
import { Place } from '@aa/models/place';
import {
  IN_PROGRESS,
  UiResponse,
  toFailureUiResponse,
  toUiResponse,
  toUiResponseFromPage,
} from '@aa/models/ui-response';
import { BumpBoard, User } from '@aa/models/user';
import { PlaceOptions, placeOptionsToHttpParams } from '@aa/services/place.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { Page, toPage } from '../models/page';

export type ValidateResponse = {
  [key: string]: string[];
};

export type UserDeleteRequestResponse = {
  url: string;
  confirmation_code: string;
};

export type RequestParams = HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>; };

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static urlTo(user: User | { id: User['id'] } | number): any[] {
    if (typeof user == 'number') {
      return ['/member', user];
    }
    return ['/member', user.id];
  }

  private static buildFormData(body: any, flattenedName?: string, formData?: FormData): FormData {
    if (!formData) {
      formData = new FormData();
    }
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const value = body[key];
        const name = flattenedName ? `${flattenedName}[${key}]` : key;
        if (value instanceof File) {
          formData.append(name, value, value.name);
        } else if (typeof value === 'object') {
          UserService.buildFormData(value, name, formData);
        } else {
          formData.append(name, value);
        }
      }
    }

    return formData;
  }

  constructor(private http: HttpClient) { }

  self(): Observable<User> {
    return this.http.get<User>('/users/self').pipe(
      tap((user) => {
        Sentry.setUser({
          id: `${user.id}`,
          username: user.username,
          email: user.email,
        });
      })
    );
  }

  requestReset(email: string): Observable<HttpResponse<any>> {
    return this.http.put('/users/request-reset', { email }, { observe: 'response' });
  }

  resetPassword(token: string, password?: string): Observable<HttpResponse<any>> {
    return this.http.post('/users/reset-password', { token, password }, { observe: 'response' });
  }

  activate(token: string): Observable<HttpResponse<any>> {
    return this.http.post('/users/activate', { token }, { observe: 'response' });
  }

  validate(body: { [key: string]: string }): Observable<UiResponse<ValidateResponse[]>> {
    return this.http
      .post<ValidateResponse[]>('/users/validate', body, {
        observe: 'response',
      })
      .pipe(map(toUiResponse), catchError(toFailureUiResponse), startWith(IN_PROGRESS<ValidateResponse[]>()));
  }

  get(id: number): Observable<User> {
    return this.http.get<User>(`/users/${id}`);
  }

  create(params: { string: string }): Observable<User> {
    const refuid = localStorage.getItem('refuid');
    if (refuid) {
      params['refuid'] = refuid;
    }
    return this.http.post<User>('/users', params);
  }

  follow(userId: number): Observable<User> {
    return this.http.post<User>(`/users/${userId}/followers`, null);
  }

  unfollow(userId: number): Observable<User> {
    return this.http.delete<User>(`/users/${userId}/followers`);
  }

  getUsers(params?: HttpParams | { [param: string]: string | string[] }): Observable<HttpResponse<User[]>> {
    return this.http.get<User[]>('/users', {
      observe: 'response',
      params,
    });
  }

  page(parameters: any = {}, options: PageableOptions = {}): Observable<UiResponse<Page<User>>> {
    const paramsObject = parameters;
    if (options.page) {
      paramsObject['page'] = `${options.page}`;
    }
    if (options.pageSize) {
      paramsObject['per-page'] = `${options.pageSize}`;
    }

    const params = new HttpParams({ fromObject: paramsObject });
    return this.http
      .get<User[]>(`/users`, { params, observe: 'response' })
      .pipe(
        map(toPage),
        map(toUiResponseFromPage),
        catchError(toFailureUiResponse),
        startWith(IN_PROGRESS<Page<User>>())
      );
  }

  pagePlaces(userId: number, options: PageableOptions = {}): Observable<UiResponse<Page<Place>>> {
    const paramsObject = {};
    if (options.page) {
      paramsObject['page'] = `${options.page}`;
    }
    if (options.pageSize) {
      paramsObject['per-page'] = `${options.pageSize}`;
    }
    if (options.offset) {
      paramsObject['offset'] = `${options.offset}`;
    }

    const params = new HttpParams({ fromObject: paramsObject });
    return this.http
      .get<Place[]>(`/users/${userId}/places`, { params, observe: 'response' })
      .pipe(
        map(toPage),
        map(toUiResponseFromPage),
        catchError(toFailureUiResponse),
        startWith(IN_PROGRESS<Page<Place>>())
      );
  }

  pageFollowing(userId: number, options: PageableOptions = {}): Observable<UiResponse<Page<User>>> {
    const paramsObject = {};
    if (options.page) {
      paramsObject['page'] = `${options.page}`;
    }
    if (options.pageSize) {
      paramsObject['per-page'] = `${options.pageSize}`;
    }
    if (options.offset) {
      paramsObject['offset'] = `${options.offset}`;
    }

    const params = new HttpParams({ fromObject: paramsObject });
    return this.http
      .get<User[]>(`/users/${userId}/following`, {
        params,
        observe: 'response',
      })
      .pipe(
        map(toPage),
        map(toUiResponseFromPage),
        catchError(toFailureUiResponse),
        startWith(IN_PROGRESS<Page<User>>())
      );
  }

  places(userId: number, options: PlaceOptions = {}): Observable<Place[]> {
    const url = `/users/${userId}/places`;
    const params = placeOptionsToHttpParams(options);
    return this.http.get<Place[]>(url, { params });
  }

  followers(userId: number, options: PageableOptions = {}): Observable<User[]> {
    const url = `/users/${userId}/followers`;
    const params = pageableOptionsToHttpParams(options);
    return this.http.get<User[]>(url, { params });
  }

  following(userId: number, options: PageableOptions = {}): Observable<User[]> {
    const url = `/users/${userId}/following`;
    const params = pageableOptionsToHttpParams(options);
    return this.http.get<User[]>(url, { params });
  }

  update(id: number, params: any): Observable<User> {
    const url = `/users/${id}`;
    return this.http.post<User>(url, UserService.buildFormData(params));
  }

  updateSelf(params: any) {
    const url = `/users/self`;
    return this.http.post<User>(url, UserService.buildFormData(params));
  }

  updateType(id: number, type: string): Observable<void> {
    const url = `/users/${id}/type`;
    return this.http.post<void>(url, { type });
  }

  // TODO: Check if `options` type is what it should be
  fish(id: number, options: { place_id?: number }): Observable<FishConfirmation[]> {
    const url = `/users/${id}/fish`;
    const paramsObject = {};
    for (const p in options) {
      if (options.hasOwnProperty(p)) {
        paramsObject[p] = options[p];
      }
    }
    const params = new HttpParams();
    return this.http.get<FishConfirmation[]>(url, { params });
  }

  recruitments(): Observable<User[]> {
    return this.http.get<User[]>(`/users?recruitments=1`);
  }

  recruitmentsHeaders(): Observable<HttpHeaders> {
    return this.http.head(`/users?recruitments=1`, { observe: 'response' }).pipe(map((response) => response.headers));
  }

  viewAccountDeletionStatus(token: string): Observable<HttpResponse<AccountDeletionStatus>> {
    return this.http.get<AccountDeletionStatus>(`/users/delete-request/${token}`, { observe: 'response' });
  }

  abortAccountDeletion(token: string): Observable<HttpResponse<object>> {
    return this.http.delete(`/users/delete-request/${token}`, { observe: 'response' });
  }

  bumpBoards(userId: number): Observable<BumpBoard[]> {
    // TODO: pagination
    const url = `/users/${userId}/bump-boards`;
    return this.http.get<BumpBoard[]>(url);
  }

  requestDelete(): Observable<HttpResponse<UserDeleteRequestResponse>> {
    return this.http.post<UserDeleteRequestResponse>('/users/delete-request', {}, { observe: 'response' });
  }

  indexTokenHistory(userId: number, params?: RequestParams): Observable<HttpResponse<MyCatchTokenExchanged[]>> {
    const url = `/users/${userId}/token-history`;
    return this.http.get<MyCatchTokenExchanged[]>(url, { observe: 'response', params });
  }

  deleteAccount(userId: number, ban: boolean = false) {
    const url = `/users/${userId}`;
    return this.http.delete<HttpResponse<void>>(url, { observe: 'response', params: { ban: +ban } });
  }
}

export type MyCatchTokenExchanged = {
  id: number;
  amount: number;
  exchanged_at: string;
  exchange_reason: string;
  message: string;
  waterbody_id: number | null;
  creel_survey_id: number | null;
};

export interface AccountDeletionStatus {
  days_until_deletion: number;
}
