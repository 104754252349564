<div class="z-10 w-full max-w-md m-auto mt-20 rounded bg-white border-gray-100">
    <h4 class="p-4 border-0 border-b-gray-100">Login with your Angler's Atlas account</h4>
    <form class="p-4 flex flex-col space-y-4">
        <input #email
            [attr.disabled]="loggingIn ? '' : null"
            type="email"
            placeholder="Email"
            class="h-10 px-4 bg-transparent border-gray-100 rounded placeholder:text-gray-300 active:ring-0 focus:ring-0 disabled:bg-gray-25 hover:disabled:cursor-not-allowed disabled:text-gray-300">

        <div class="flex items-center h-10 pr-2 border border-gray-100 rounded"
            [class.bg-gray-25]="loggingIn">
            <input #password
                [attr.disabled]="loggingIn ? '' : null"
                [attr.type]="showingPassword ? 'text' : 'password'"
                placeholder="Password"
                class="w-full h-10 px-4 bg-transparent border-0 placeholder:text-gray-300 active:ring-0 focus:ring-0 disabled:bg-gray-25 hover:disabled:cursor-not-allowed disabled:text-gray-300">
            <svg-icon [name]="showingPassword ? 'eye-close' : 'eye-open'"
                    svgClass="fill-gray-300"
                    class="size-6 hover:cursor-pointer"
                    [attr.title]="showingPassword ? 'Hide password' : 'Show password'"
                    (click)="onClickToggleShowPassword()"></svg-icon>
        </div>
        <button class="btn btn-filled btn-green-light"
                [attr.disabled]="loggingIn ? '' : null"
                (click)="onClickLogin(email.value, password.value)">Login</button>
        <div class="h-10 flex items-center justify-center">
            <a [routerLink]="['/forgot-password']" class="text-green-light text-button">Forgot Password?</a>
        </div>
    </form>
    <p *ngIf="errorMessage"
        class="mt-2 px-4 py-2 bg-red/50 border border-red/75 rounded">{{ errorMessage }}</p>
</div>
<img class="absolute left-0 right-0 bottom-0 w-full z-0 opacity-50" src="assets/facet.svg" />