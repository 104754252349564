<div class="absolute inset-0"
     [class.hidden]="media[index].kind == 'VIDEO'">
  @if (showBlurredImage) {
    <img @fadeInOut [src]="media[index].src + ':100'" class="relative size-full blur object-contain object-center" />
  }
  <div #map class="relative size-full"></div>
</div>
@if (media[index].kind == 'VIDEO') {
  <div class="absolute inset-x-8 inset-y-16 p-8">
    <video controls
           autoplay
           class="w-full h-full object-contain bg-gray-800">
      <source [src]="media[index].src"
              type="video/mp4" />
      Sorry, this browser does not support embedded videos
    </video>
  </div>
}

@if (media.length > 1) {
  <div class="absolute inset-x-2 top-2 flex justify-center drop-shadow">
    <div class="bg-white border border-gray-100 rounded overflow-hidden p-2 flex space-x-2">
      @for (it of media; track it.src) {
        <img class="size-12 object-cover bg-purple-faint border rounded-sm overflow-clip hover:cursor-pointer"
             [src]="it.kind == 'PHOTO' ? it.src + ':100' : 'assets/svg-icon/play.svg'"
             [class.border-gray-100]="$index != index"
             [class.border-purple]="$index == index"
             (click)="onClickMedia($index)" />
      }
    </div>
  </div>
}

@if (notes) {
  <div class="absolute inset-x-4 bottom-4 flex items-center justify-center">
    <p class="bg-white border border-gray-100 rounded overflow-hidden px-4 py-2 drop-shadow">
      {{ notes }}
    </p>
  </div>
}

<button class="absolute top-2 right-2 bg-white p-2 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-100"
        title="Close"
        (click)="onClickClose()">
  <svg-icon name="close" class="size-6" />
</button>

@if (data?.showNextPrev) {
<button class="absolute right-2 bg-white p-2 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-100"
        title="Next"
        (click)="onClickNext()">
  <svg-icon name="chevron-right" class="size-6"/>
</button>
<button class="absolute left-2 bg-white p-2 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-100"
        title="Previous"
        (click)="onClickPrev()">
  <svg-icon name="chevron-left" class="size-6"/>
</button>
}
