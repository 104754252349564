import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiDashboard } from '../../app.service';

@Component({
    selector: 'dash-dashboards-list',
    templateUrl: './dashboards-list.component.html'
})
export class DashboardsListComponent {
    @Input() dashboards: UiDashboard[];

    @Output() clickDashboard = new EventEmitter<number>();

    onClickDashboard(id: number) {
        this.clickDashboard.emit(id);
    }
}