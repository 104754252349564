import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Feature as OpenLayerFeature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import { SimpleMarker } from '@aa/models/simple-marker';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857'
});

@Component({
  selector: 'app-map-markers',
  templateUrl: './map-markers.component.html',
  styleUrls: ['./map-markers.component.scss'],
})
export class MapMarkersComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() markers: SimpleMarker[];

  @Output() selected = new EventEmitter<SimpleMarker>();

  private isReady = false;

  private markerStyle = (feature) => {
    const src = feature.get('image-icon-src');

    const style = new Style({
      image: new Icon({
        size: [36, 36],
        src,
      })
    });

    return style;
  };

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent,
  ) { }

  ngAfterViewInit() {
    const onClick = (feature: OpenLayerFeature) => {
      this.selected.emit(feature.get('marker'));
    }

    const onHover = (feature: OpenLayerFeature) => {
      this.parent.mapContainerElement.nativeElement.style.cursor = !!feature ? 'pointer' : '';
    };

    const layer = new VectorLayer({
      source: new VectorSource(),
      style: this.markerStyle,
    });
    this.parent.addLayer({
      key: MapLayer.Markers,
      layer: layer,
      onClick: onClick,
      onHover: onHover,
      deselectOnClick: true,
    });

    this.isReady = true;
    this.loadMarkers();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.Markers);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isReady) {
      this.loadMarkers();
    }
  }

  private loadMarkers() {
    this.parent.getLayer(MapLayer.Markers).subscribe(layer => {
      if (!layer) { return }

      const source = layer.getSource();
      source.clear();

      const features = this.markers.map(marker => {
        const publicPrivate = marker.is_private ? 'private' : 'public';
        const filename = marker.type.toLowerCase().replace('_', '-');
        const markerIconSrc = `/assets/markers/${publicPrivate}/${filename}.svg`;

        const feature = new OpenLayerFeature({
          geometry: DECODER.readGeometry(marker.geometry),
          name: marker.type,
          marker: marker
        });
        feature.setId('place-markers-' + marker.id);
        feature.set('image-icon-src', markerIconSrc);
        feature.set('layer-id', MapLayer.Markers);

        return feature;
      });

      source.addFeatures(features);
    });
  }
}
