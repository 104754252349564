import { AfterViewInit, Component, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { Feature, Feature as OpenLayerFeature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { CreelSurvey, decodeTrackedGeometry } from '@aa/models/creel-survey';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';

const LOCATION_STYLES = {
  LineString: new Style({
    stroke: new Stroke({
      color: '#4FC0C4',
      width: 3
    })
  }),
  Point: new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({
        color: '#3a8fb6',
        width: 2
      }),
      fill: new Fill({
        color: [81, 162, 200, 0.5]
      })
    })
  })
};

@Component({
  selector: 'app-map-trip-location',
  templateUrl: './map-trip-location.component.html',
  styleUrls: ['./map-trip-location.component.scss'],
})
export class MapTripLocationComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() location: CreelSurvey;

  private isReady = false;

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent
  ) { }

  ngAfterViewInit() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      style: feature => {
        const type = feature.getGeometry().getType();
        const style = LOCATION_STYLES[type];
        return style
      }
    });
    this.parent.addLayer({
      key: MapLayer.TripLocation,
      layer: layer,
    });

    this.isReady = true;
    this.load();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.TripLocation);
  }

  ngOnChanges() {
    if (this.isReady) {
      this.load();
    }
  }

  private load() {
    this.parent.getLayer(MapLayer.TripLocation).subscribe((layer) => {
      const source = layer.getSource();
      if (!source) return;

      source.clear();

      const features: OpenLayerFeature[] = [];

      const liveTrackPoints = decodeTrackedGeometry(this.location);
      for (const segment of liveTrackPoints) {
        const coordinates = segment.map(({ lon, lat }) => transform([lon, lat], 'EPSG:4326', 'EPSG:3857'));
        const feature = new OpenLayerFeature({
          geometry: new LineString(coordinates)
        });
        features.push(feature);
      }

      if (this.location.user_location) {
        const decoder = new GeoJSON({ featureProjection: 'EPSG:3857' });
        const feature = decoder.readFeature(this.location.user_location);
        if (feature instanceof Feature) {
          features.push(feature);
        } else {
          console.warn(`Failed to parse feature for user_location: ${this.location.user_location}`);
        }
      }

      source.addFeatures(features);

      this.parent.fitExtent();
    });
  }
}
