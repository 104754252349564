import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, FixedOffsetZone, Zone } from "luxon";

@Pipe({
  standalone: true,
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  transform(value: string | Date | null): Date | null {
    return toDate(value);
  }
}

@Pipe({
  standalone: true,
  name: 'toDateTime',
})
export class ToDateTimePipe implements PipeTransform {
  transform(value: string | DateTime | Date | null, zone?: string | Zone | null): DateTime | null {
    return toDateTime(value, zone);
  }
}

@Pipe({
  standalone: true,
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  transform(value: DateTime | null, formatOpts?: string | Intl.DateTimeFormatOptions): string | null {
    return formatDateTime(value, formatOpts);
  }
}

export function toDateTime(value: DateTime | Date | string | null, zone?: Zone | string | null): DateTime | null {
  if (value == null) return null;

  let result: DateTime | null = null;

  if (value instanceof DateTime) {
    result = value;
  } else if (typeof value === 'string') {
    if (value.indexOf('T') != -1) {
      result = DateTime.fromISO(value);
    } else {
      result = DateTime.fromSQL(value);
    }
  } else if (value instanceof Date) {
    result = DateTime.fromJSDate(value);
  } else {
    console.warn('ToDateTimePipe failed');
    console.dir(value);
  }

  if (result) {
    if (zone) {
      result = result.setZone(zone);
    } else {
      result = result.setZone(FixedOffsetZone.utcInstance);
    }

    if (!result.isValid) {
      console.warn(result.invalidReason + ': ' + result.invalidExplanation);
      console.dir(result);
    }
  }

  return result;
}

export function formatDateTime(value: DateTime | null, formatOpts?: string | Intl.DateTimeFormatOptions): string | null {
  if (value == null) return null;

  if (formatOpts) {
    if (typeof formatOpts === 'string') {
      return value.toFormat(formatOpts);
    } else {
      return value.toLocaleString(formatOpts)
    }
  }

  return value.toLocaleString();
}

export function toDate(value: string | Date | null): Date | null {
  if (value === null) return null;
  if (value instanceof Date) return value;

  // If there's no time, it's assumed start of day PST/PDT(?)
  // Convert the date string to an ISO8601 string
  const parts = value.split(' ');
  if (parts.length == 1) {
    value = parts[0] + 'T00:00:00-08:00';
  } else {
    value = parts[0] + 'T' + parts[1];
    if (value.charAt(-3) !== ':') {
      value += ':00';
    }
  }

  let match: RegExpMatchArray | null;
  if ((match = value.match(ISO8601_DATE_REGEX))) {
    return isoStringToDate(match);
  }

  return null;
}

//
// "Borrowed" from https://github.com/angular/angular/blob/5babdb22844933170ded27534094b8243296c53f/packages/common/src/i18n/format_date.ts
// TODO: regex X0 -> that's me dead
const ISO8601_DATE_REGEX =
  /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;

/**
 * Converts a date in ISO8601 to a Date.
 * Used instead of `Date.parse` because of browser discrepancies.
 */
export function isoStringToDate(match: RegExpMatchArray): Date {
  const date = new Date(0);
  let tzHour = 0;
  let tzMin = 0;

  // match[8] means that the string contains "Z" (UTC) or a timezone like "+01:00" or "+0100"
  const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
  const timeSetter = match[8] ? date.setUTCHours : date.setHours;

  // if there is a timezone defined like "+01:00" or "+0100"
  if (match[9]) {
    tzHour = Number(match[9] + match[10]);
    tzMin = Number(match[9] + match[11]);
  }
  dateSetter.call(date, Number(match[1]), Number(match[2]) - 1, Number(match[3]));
  const h = Number(match[4] || 0) - tzHour;
  const m = Number(match[5] || 0) - tzMin;
  const s = Number(match[6] || 0);
  // The ECMAScript specification (https://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.11)
  // defines that `DateTime` milliseconds should always be rounded down, so that `999.9ms`
  // becomes `999ms`.
  const ms = Math.floor(parseFloat('0.' + (match[7] || 0)) * 1000);
  timeSetter.call(date, h, m, s, ms);
  return date;
}

