import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    standalone: true,
    selector: 'dash-dropdown',
    templateUrl: './dropdown.component.html',
    imports: [
        CdkMenuModule,
        CommonModule,
        SvgIconComponent,
    ],
})
export class DropdownComponent {
    @ViewChild(CdkMenuTrigger) menuTrigger: CdkMenuTrigger;

    @Input() selectedValue?: string | null;
    @Input() dropdownClass: string;
    @Input() disabled: boolean = false;

    close() {
        this.menuTrigger?.close();
    }
}

@Component({
    standalone: true,
    selector: 'dash-dropdown-option',
    template: `
<span class="w-96 h-10 px-4 flex items-center hover:bg-gray-50 hover:cursor-pointer"
      [class.font-bold]="selected"
      [class.bg-gray-25]="selected"
      cdkMenuItem>
    {{ text }}
</span>
    `,
})
export class DropdownOptionComponent {
    @Input() text: string;
    @Input() selected: boolean;
}
