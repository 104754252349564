import { AuthenticationService } from '@aa/authentication/authentication.service';
import { EmptyMapComponent, MapLayer } from '@aa/map/empty-map/empty-map.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Feature, Map as OlMap } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { COLORS } from '../../app.module';
import { AppService, DashboardWaterbodies, UiDashboard } from '../../app.service';
import { TableColumn, TableColumnFilters, TableComponent } from '../../components/table/table.component';
import { DashboardCatchLengthResponse, DashboardService, DashboardWaterbodyResponse, DashboardWaterbodyResponseCatch } from '../../dashboard.service';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { LengthsHistogramComponent } from '../lengths-histogram/lengths-histogram.component';
import { METRICS, Metric, MetricType } from '../types';

type TripStat = {
  id: number;
  date: string;
  hours: number;
  species: string;
  count: number;
  complete: string;
};

type WaterbodyLink = any[];

type MarkerLayer = {
  visible: boolean;
  numMarkers: number;
  featuresById: Map<number | string, Feature>;
  layer: VectorLayer<Feature>;
};

type MarkerLayerMarker = {
  id: number | string;
  lat?: number;
  lon?: number;
};

const MARKER_FEATURE_PROP_TYPE = 'TYPE';
const MARKER_FEATURE_PROP_SOURCE = 'SOURCE';

const MARKER_KINDS = ['TRIP', 'CATCH'] as const;
type MarkerKind = typeof MARKER_KINDS[number];

const MARKER_KIND_VALUES: { [key in MarkerKind]: number; } = {
  'TRIP': 1,  // 0b01
  'CATCH': 2, // 0b10
};
const MARKER_KIND_ADMIN_VIEW_URL: { [key in MarkerKind]: string; } = {
  'TRIP': 'https://admin.anglersatlas.com/admin/creel-survey/view',
  'CATCH': 'https://admin.anglersatlas.com/admin/catch-log/view',
};

function markerKindFromType(type: number): MarkerKind | null {
  let result: MarkerKind | null = null;
  for (const kind of MARKER_KINDS) {
    const value = MARKER_KIND_VALUES[kind];
    if (value == type) {
      result = kind;
      break;
    }
  }
  return result;
}


@Component({
  selector: 'dash-tournament-region-waterbody-dashboard',
  templateUrl: './tournament-region-waterbody-dashboard.component.html',
})
export class TournamentRegionWaterbodyDashboardComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('mapStatsContainer') mapStatsContainerElementRef: ElementRef<HTMLDivElement>;
  @ViewChild(EmptyMapComponent) mapComponent: EmptyMapComponent;
  @ViewChild('speciesSearch') speciesSearch: ElementRef<HTMLInputElement>;
  @ViewChild(LengthsHistogramComponent) lengthsHistogramComponent: LengthsHistogramComponent;
  @ViewChild('adminControlsInfoDialog') adminControlsInfoDialogTemplateRef: TemplateRef<unknown>;

  loading = true;
  loadingWaterbodies = true;

  activeMapTypeIndex = 0;
  mapTypes: string[];

  activeWaterbodyIndex = -1;
  waterbodyNames: string[] = [];
  private waterbodyLinks: WaterbodyLink[] = [];

  metrics: Metric[] = [
    METRICS['ANGLERS'],
    METRICS['TRIPS'],
    METRICS['ZEROS'],
    METRICS['FISH'],
    METRICS['HOURS_SPENT'],
    { ...METRICS['CATCH_RATE'], title: 'Avg Catch Rate' },
  ];
  metricCounts: { [key in MetricType]?: number } = {
    ANGLERS: 0,
    TRIPS: 0,
    ZEROS: 0,
    FISH: 0,
    HOURS_SPENT: 0,
    CATCH_RATE: 0
  };

  tableData: TripStat[] = null;
  tableColumns: TableColumn<TripStat>[] = [
    { title: 'Trip', property: 'id', sort: 'number', copyable: true },
    // NOTE(cg): Hopefully this works... Sorting a date as a string "yyyy-mm-dd"... Looks like it
    { title: 'Date', property: 'date', sort: 'string' },
    { title: 'Hours', property: 'hours', sort: 'number', numberFormat: '1.0-1' },
    { title: 'Species', property: 'species' },
    { title: 'Catches', property: 'count', sort: 'number' },
    { title: 'Complete', property: 'complete', sort: 'string' },
  ];
  private _tableFilters = new Subject<TableColumnFilters<TripStat> | null>();
  tableFilters = this._tableFilters.asObservable().pipe(debounceTime(150));

  lengthUnits: 'CM' | 'IN' = 'CM';

  navigating = this.appService.navigating(this.route);

  mapHeightPx: number = 0;

  dashboard: UiDashboard;
  waterbodyId: number;
  catchData: DashboardCatchLengthResponse[];

  exportCatchesCsvLink: string | null = null;

  userIsAdmin = false;

  private markerIconsTrip: Style[] = [
    // 0b00: unknown
    new Style({
      image: new Icon({
        src: '/assets/markers/boat-gray.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b01: trip
    new Style({
      image: new Icon({
        src: '/assets/markers/boat-yellow.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b10: catch
    new Style({
      image: new Icon({
        src: '/assets/markers/boat-red.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b11: both
    new Style({
      image: new Icon({
        src: '/assets/markers/boat.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
  ];
  private markerIconsFish: Style[] = [
    // 0b00: unknown
    new Style({
      image: new Icon({
        src: '/assets/markers/fish-gray.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b01: trip
    new Style({
      image: new Icon({
        src: '/assets/markers/fish.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b10: catch
    new Style({
      image: new Icon({
        src: '/assets/markers/fish-red.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
    // 0b11: both
    new Style({
      image: new Icon({
        src: '/assets/markers/fish-purple.png',
        scale: 0.5,
        anchor: [0.5, 0.423],
      }),
    }),
  ];

  markerKinds = MARKER_KINDS;
  markerLayers: { [key in MarkerKind]: MarkerLayer; } = {
    'TRIP': {
      visible: false,
      numMarkers: 0,
      featuresById: new Map(),
      layer: new VectorLayer({
        source: new VectorSource(),
        style: (feature) => {
          const source = feature.get(MARKER_FEATURE_PROP_SOURCE) ?? 0;
          const icon = (source < this.markerIconsTrip.length) ? this.markerIconsTrip[source] : null;
          if (!icon) {
            const id = feature.getId();
            const type = feature.get(MARKER_FEATURE_PROP_TYPE) ?? 0;
            const typeNames = ['???', 'trip', 'catch', 'both!?'];
            const typeName = (type < typeNames.length) ? typeNames[type] : 'Huh?';
            console.log(`Unexpected prop source: ${id} ${typeName}`);
          }
          return icon;
        },
      }),
    },
    'CATCH': {
      visible: false,
      numMarkers: 0,
      featuresById: new Map(),
      layer: new VectorLayer({
        source: new VectorSource(),
        style: (feature) => {
          const source = feature.get(MARKER_FEATURE_PROP_SOURCE) ?? 0;
          const icon = source <= 3 ? this.markerIconsFish[source] : null;
          if (!icon) {
            const id = feature.getId();
            const type = feature.get(MARKER_FEATURE_PROP_TYPE) ?? 0;
            const typeNames = ['???', 'trip', 'catch', 'both!?'];
            const typeName = (type < typeNames.length) ? typeNames[type] : 'Huh?';
            console.log(`Unexpected prop source: ${id} ${typeName}`);
          }

          return icon;
        },
      }),
    },
  };

  private loadedTripMarkers = false;
  private loadedCatchLengthMarkers = false;

  private mapReady = false;

  private boundaryLayer: VectorLayer<Feature>;

  private boundaryGeoJson: string | null = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    private dialog: Dialog,
  ) { }

  ngOnInit() {
    this.mapTypes = this.appService.mapTypes;
    const activeMapTypeIndex = this.mapTypes.indexOf(this.appService.activeMapType);
    if (activeMapTypeIndex != -1) { this.activeMapTypeIndex = activeMapTypeIndex; }

    this.boundaryLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: COLORS['green-super-light'],
          width: 3,
          lineDash: [6, 2],
        }),
        fill: new Fill({
          color: `${COLORS['green-ultra-light']}20`,
        }),
      })
    });

    const dashboard = this.appService.dashboard.pipe(
      tap(() => this.resetState()),
      filter((it) => !!it),
      tap((dashboard) => {
        this.dashboard = dashboard;
        this.lengthUnits = dashboard.defaultUnits;
      }),
      shareReplay(1)
    );
    const waterbodyId = this.route.params.pipe(
      map((it) => parseInt(it['waterbodyId'])),
      shareReplay(1),
      tap((it) => this.waterbodyId = it)
    );

    const waterbodiesSubscription = combineLatest([dashboard, waterbodyId])
      .pipe(
        switchMap(([dashboard, waterbodyId]) => {
          const waterbodies = this.appService.getWaterbodies(dashboard.id);
          return waterbodies.pipe(
            map((response) => ([dashboard.id, waterbodyId, response] as [number, number, DashboardWaterbodies]))
          );
        })
      )
      .subscribe({
        next: ([dashboardId, waterbodyId, { loading, waterbodies }]) => {
          const waterbodyLinks: WaterbodyLink[] = [];
          const waterbodyNames: string[] = [];
          let activeWaterbodyIndex = -1;
          const waterbodiesLength = waterbodies?.length ?? 0;
          for (let i = 0; i < waterbodiesLength; i += 1) {
            const waterbody = waterbodies[i];

            if (waterbody.id == waterbodyId) {
              this.metricCounts['ANGLERS'] = waterbody.anglers;
              this.metricCounts['TRIPS'] = waterbody.trips;
              this.metricCounts['ZEROS'] = waterbody.zeros;
              this.metricCounts['FISH'] = waterbody.catches;
              this.metricCounts['HOURS_SPENT'] = waterbody.hours_out;
              this.metricCounts['CATCH_RATE'] = waterbody.rate;

              activeWaterbodyIndex = i;
            }

            const routerLink = [
              '/dashboards',
              dashboardId,
              'waterbody',
              waterbody.id,
            ];
            waterbodyLinks.push(routerLink);
            waterbodyNames.push(waterbody.name);
          }

          this.waterbodyNames = waterbodyNames;
          this.waterbodyLinks = waterbodyLinks;
          this.activeWaterbodyIndex = activeWaterbodyIndex;

          this.loadingWaterbodies = loading;
        },
        error: (error) => {
          console.error(error);
          this.loadingWaterbodies = false;
        },
      });
    this.subscriptions.push(waterbodiesSubscription);

    //- cg: download catch length histogram data
    const catchLengthsSubscription = combineLatest([dashboard, waterbodyId, this.authService.tryAccessToken()])
      .pipe(
        switchMap(([dashboard, waterbodyId, accessToken]: [UiDashboard, number, string | null]) => {
          const params = [
            `access_token=${accessToken}`,
            `waterbody_id=${waterbodyId}`,
          ];
          this.exportCatchesCsvLink = `${environment.apiUrl}/dashboards/${dashboard.id}/catches-csv?${params.join('&')}`;
          return this.dashboardService.dashboardCatchLengths(dashboard.id, { waterbody_id: waterbodyId });
        })
      )
      .subscribe({
        next: (response) => {
          this.catchData = response;
          this.bindCatchLengthMarkers();
          //- cg: validate that there aren't duplicate catches
          {
            const catchIds = new Map<number | string, number>();
            for (const { catches } of response) {
              for (const it of catches) {
                const id = it.catch_log_id;
                let count = catchIds.get(id) ?? 0;
                catchIds.set(id, count + 1);
              }
            }
            for (const [id, count] of catchIds.entries()) {
              if (count > 1) {
                console.warn("Duplicate catch length", id);
              }
            }
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    this.subscriptions.push(catchLengthsSubscription);

    //- cg: download waterbody data
    const routeDataSubscription = combineLatest([dashboard, waterbodyId])
      .pipe(
        tap(() => this.resetState()),
        switchMap(([dashboard, waterbodyId]) => {
          return this.dashboardService.dashboardWaterbody(dashboard.id, waterbodyId)
        })
      )
      .subscribe({
        next: (data: DashboardWaterbodyResponse) => {
          this.boundaryGeoJson = data.waterbody.boundary;

          const tripIds = new Map<string | number, number>();
          const tableData: TripStat[] = [];
          for (const trip of data.trips) {
            let speciesNames: string[] = [];
            let count = 0;

            for (const c of trip.catches) {
              speciesNames.push(c.species_name);
              count += c.catches;
            }

            const species = (speciesNames.length) ? speciesNames.join(', ') : 'Nothing Caught';

            const stat: TripStat = {
              id: trip.id,
              date: trip.for_date,
              hours: trip.hours_out,
              species: species,
              count: count,
              complete: trip.complete ? 'Yes' : 'No',
            };

            tableData.push(stat);

            count = tripIds.get(trip.id) ?? 0;
            tripIds.set(trip.id, count + 1);
          }
          this.tableData = tableData;

          this.bindTripMarkers(data.trips, data.catches);
          this.maybeBindBoundary();

          this.loading = false;

          for (const [id, count] of tripIds.entries()) {
            if (count > 1) {
              console.warn("Duplicate trip", id);
            }
          }
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        }
      });
    this.subscriptions.push(routeDataSubscription);

    const userSubscription = this.authService.loggedInUser$.subscribe({
      next: (user) => {
        this.userIsAdmin = user?.account_type == 'ADMIN';
      },
      error: (error) => {
        console.error(error);
        this.userIsAdmin = false;
      },
    });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((it) => it.unsubscribe());
    this.subscriptions.length = 0;
  }

  ngAfterViewInit() {
    if (this.mapComponent) { this.initMap(); }
  }

  ngAfterViewChecked() {
    const statsContainerHeight = this.mapStatsContainerElementRef?.nativeElement?.offsetHeight ?? 0;
    if (statsContainerHeight && statsContainerHeight != this.mapHeightPx) {
      setTimeout(() => {
        this.mapHeightPx = statsContainerHeight;
        this.maybeBindBoundary();
      });
    }

    if (this.mapReady && !this.mapComponent) {
      this.mapReady = false;
    } else if (!this.mapReady && this.mapComponent) {
      this.initMap();
    }
  }

  doSearch() {
    const query = this.speciesSearch?.nativeElement?.value;

    let filters: TableColumnFilters<TripStat> | null = null;
    if (query) {
      filters = { species: [query] };
    }

    this._tableFilters.next(filters);
  }

  onClickWaterbody(dropdown: DropdownComponent, index: number) {
    dropdown.close();
    this.activeWaterbodyIndex = index;
    this.router.navigate(this.waterbodyLinks[index]);
  }

  onClickUnits(units: 'CM' | 'IN') {
    if (this.lengthUnits == units) return;

    this.lengthUnits = units;
  }

  onClickMapType(dropdown: DropdownComponent, type: string, index: number) {
    dropdown.close();
    this.activeMapTypeIndex = index;
    this.appService.activeMapType = type as string;
  }

  onClickExportTripData(table: TableComponent<any>) {
    const dashboard = this.dashboard;
    if (!dashboard) { return; }

    const dashboardName = dashboard.name.toLocaleLowerCase();
    const waterbody = this.waterbodyNames[this.activeWaterbodyIndex].toLowerCase();
    const filename = (`${dashboardName}_${waterbody}_trips.csv`).replaceAll(/\s+/g, '-');
    table.downloadCsv(filename);
  }

  private initMap() {
    this.mapComponent.addLayer({
      key: MapLayer.Boundary,
      layer: this.boundaryLayer,
    });
    this.mapReady = true;
    this.maybeBindBoundary();
  }

  onMapReady(map: OlMap) {
    for (const kind in this.markerLayers) {
      const layer = this.markerLayers[kind];

      layer.layer.setZIndex(100);
      layer.layer.setVisible(layer.visible);
      map.addLayer(layer.layer);
    }

    map.on('click', (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const type = feature.get(MARKER_FEATURE_PROP_TYPE) ?? 0;
        const kind = markerKindFromType(type);
        if (kind) {
          const id = feature.getId();
          const url = MARKER_KIND_ADMIN_VIEW_URL[kind] + `?id=${id}`;
          window.open(url, '_blank');
        }
      });
    });
  }

  private maybeBindBoundary() {
    if (!this.mapReady || !this.boundaryGeoJson || this.mapHeightPx === 0) return;

    const decoder = new GeoJSON({
      featureProjection: 'EPSG:3857'
    });

    const feature = new Feature(decoder.readGeometry(this.boundaryGeoJson));

    const source = this.boundaryLayer.getSource();
    source.clear()
    source.addFeature(feature);

    this.mapComponent.fitExtent([MapLayer.Boundary]);
  }

  toggleMarkerLayerVisible(layer: MarkerLayer) {
    const visible = !layer.visible;
    layer.visible = visible;
    layer.layer.setVisible(visible);
  }

  private infoDialogRef: DialogRef | null = null;
  onClickAdminControlsInfoButton() {
    this.infoDialogRef = this.dialog.open(this.adminControlsInfoDialogTemplateRef);
  }
  onClickCloseAdminControlsInfoDialog() {
    this.infoDialogRef?.close();
  }

  private bindTripMarkers(trips: MarkerLayerMarker[], catches?: DashboardWaterbodyResponseCatch[]) {
    const markerKindValueTrip = MARKER_KIND_VALUES['TRIP'];
    const markerLayerTrip = this.markerLayers['TRIP'];

    const markerKindValueCatch = MARKER_KIND_VALUES['CATCH'];
    const markerLayerCatch = this.markerLayers['CATCH'];

    //- cg: trips
    for (const { id, lat, lon } of trips) {
      if (!id || !lat || !lon) { continue; }
      this.buildOrUpdateFeature(id, lat, lon, markerKindValueTrip, markerKindValueTrip, markerLayerTrip.featuresById);
    }
    // - cg: catches
    for (const { id, lat, lon } of catches) {
      if (!id || !lat || !lon) { continue; }
      this.buildOrUpdateFeature(id, lat, lon, markerKindValueCatch, markerKindValueTrip, markerLayerCatch.featuresById);
    }

    this.loadedTripMarkers = true;
    this.maybeBindMarkers();
  }

  private bindCatchLengthMarkers() {
    const markerKindValueTrip = MARKER_KIND_VALUES['TRIP'];
    const markerLayerTrip = this.markerLayers['TRIP'];

    const markerKindValueCatch = MARKER_KIND_VALUES['CATCH'];
    const markerLayerCatch = this.markerLayers['CATCH'];

    for (const data of this.catchData) {
      for (const { catch_log_id, creel_survey_id, catch_log_lat, catch_log_lon, creel_survey_lat, creel_survey_lon } of data.catches) {
        if (creel_survey_id && creel_survey_lat && creel_survey_lon) {
          this.buildOrUpdateFeature(creel_survey_id, creel_survey_lat, creel_survey_lon, markerKindValueTrip, markerKindValueCatch, markerLayerTrip.featuresById);
        }
        if (catch_log_id && catch_log_lat && catch_log_lon) {
          this.buildOrUpdateFeature(catch_log_id, catch_log_lat, catch_log_lon, markerKindValueCatch, markerKindValueCatch, markerLayerCatch.featuresById);
        }
      }
    }
    this.loadedCatchLengthMarkers = true;
    this.maybeBindMarkers();
  }

  private maybeBindMarkers() {
    if (this.loadedTripMarkers && this.loadedCatchLengthMarkers) {
      for (const kind in this.markerLayers) {
        const markerLayer = this.markerLayers[kind];
        const layer = markerLayer.layer;

        const features: Feature[] = [];
        for (const feature of markerLayer.featuresById.values()) {
          features.push(feature);
        }
        layer.getSource().addFeatures(features);

        markerLayer.numMarkers = features.length;
      }
    }
  }

  private buildOrUpdateFeature(
    id: number | string,
    lat: number,
    lon: number,
    typeKindValue: number,
    sourceKindValue: number,
    featuresById: Map<number | string, Feature>
  ) {
    let feature = featuresById.get(id);
    if (!feature) {
      const geometry = new Point(fromLonLat([lon, lat]));
      feature = new Feature({ geometry });
      feature.setId(id);
    }

    const type = feature.get(MARKER_FEATURE_PROP_TYPE) ?? 0;
    feature.set(MARKER_FEATURE_PROP_TYPE, type | typeKindValue);
    const source = feature.get(MARKER_FEATURE_PROP_SOURCE) ?? 0;
    feature.set(MARKER_FEATURE_PROP_SOURCE, source | sourceKindValue);

    featuresById.set(id, feature);
  }

  private resetState() {
    this.exportCatchesCsvLink = null;
    //- cg: reset map marker state
    this.loadedTripMarkers = false;
    this.loadedCatchLengthMarkers = false;
    for (const kind in this.markerLayers) {
      const markerLayer = this.markerLayers[kind];
      markerLayer.featuresById.clear();
      markerLayer.numMarkers = 0;
      markerLayer.layer.getSource().clear();
    }
  }
}


