<div #overlayElement class="ol-popup">
  <div *ngIf="selectedCatchLog">
    <i class="material-icons close"
      (click)="clearSelected()">cancel</i>
    <div class="photo-wrapper">
      <img src="{{selectedCatchLog.photo}}:100">
    </div>
    <div class="padding">
      <div class="fish-name">
        {{ selectedCatchLog.fish_name }}
      </div>
      <div class="date-caught" *ngIf="selectedCatchLog.caught_at">
        {{ selectedCatchLog.caught_at | toDateTime | dateTime:'longDate'}}
      </div>
    </div>
  </div>
</div>
