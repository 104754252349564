import { AuthenticationService } from '@aa/authentication/authentication.service';
import { MAP_TILES } from '@aa/map/map.module';
import { toDateTime } from '@aa/pipes/to-date.pipe';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { DashboardService, DashboardsResponse, DashboardWaterbodiesResponse } from './dashboard.service';
import { Dashboard, DashboardKind } from '@aa/models/dashboard-access';

export type UiDashboardLink = {
  title: string;
  iconName: string;
  routerLink: any[];
};

export type UiDashboard = {
  id: number;
  name: string;
  type: string;
  dateFrom: DateTime | null;
  dateTo: DateTime | null;
  logoUrl: string | null;
  timezone: string | null;
  timezones: string[];
  defaultUnits: 'CM' | 'IN';
  boundary: string;
  links: UiDashboardLink[];
  starred: boolean;
  regionIds: number[];
  hasHourlyAccess: boolean;
};

function dashboardKindToLink(id: number, kind: DashboardKind): UiDashboardLink|null {
  let link: UiDashboardLink | null = null;
  switch (kind) {
    case 'REGION':
      link = {
        title: 'Region',
        iconName: 'map',
        routerLink: ['/dashboards', id],
      };
      break;

    case 'WATERBODY':
      link = {
        title: 'Waterbody',
        iconName: 'waterbody',
        routerLink: ['/dashboards', id, 'waterbody'],
      };
      break;

    case 'POPULATION':
      link = {
        title: 'Population Estimate',
        iconName: 'fish',
        routerLink: ['/dashboards', id, 'population'],
      };
      break;

    case 'BENCHMARK':
      link = {
        title: 'Length Analysis',
        iconName: 'length',
        routerLink: ['/dashboards', id, 'length'],
      };
      break;

    case 'BLOTCHY':
      link = {
        title: 'Blotchy Bass',
        iconName: 'fish',
        routerLink: ['/dashboards', id, 'blotchy'],
      };
      break;

    case 'HOURLY':
      break;

    default:
      console.error(`Unexpected dashboard kind: ${kind}`);
      break;
  }

  return link;
}

export type DashboardWaterbodies = {
  id: number;
  loading: boolean;
  waterbodies: DashboardWaterbodiesResponse[];
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _dashboards = new BehaviorSubject<UiDashboard[]>([]);
  private _dashboardsLoading = new BehaviorSubject<boolean>(false);
  private _dashboard = new BehaviorSubject<UiDashboard | null>(null);

  private idToDashboard = new Map<number, UiDashboard>();
  private idToWaterbodiesSubject = new Map<number, BehaviorSubject<DashboardWaterbodies>>();

  // TODO: figure out share() and whatnot. Want a single observable active for the entire app's life that components can subscribe to.
  dashboards: Observable<UiDashboard[]> = this._dashboards.asObservable();
  dashboardsLoading: Observable<boolean> = this._dashboardsLoading.asObservable();

  private dashboardSorter: ((a: UiDashboard, b: UiDashboard) => number) = (a, b) => {
    //- cg: starred dashboards first
    let result = (b.starred ? 1 : 0) - (a.starred ? 1 : 0);
    //- cg: then by id. i.e. most recently added
    if (result == 0) { result = b.id - a.id; }
    return result;
  };

  navigating(from: ActivatedRoute): Observable<boolean> {
    let navigating = false;
    return this.router.events.pipe(
      map((event) => {
        if (event instanceof NavigationStart) {
          navigating = true;

          // Check if any child route handles navigation. If any do, then they deal with it and we ignore it
          let route = from.firstChild;
          while (route) {
            const routeData = route?.snapshot?.data;
            const routeHandlesNavigation = routeData && 'handlesNavigationAnimation' in routeData && routeData['handlesNavigationAnimation'];
            if (routeHandlesNavigation) {
              navigating = false;
              break;
            }

            route = route.firstChild;
          }
        }

        const navigationIsOver = event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel;
        if (navigationIsOver) {
          navigating = false;
        }

        return navigating;
      })
    );
  }

  // TODO: Rename to `activeDashboard`??
  get dashboard(): Observable<UiDashboard | null> {
    return this._dashboard.asObservable();
  }

  get dashboardSidebarVisible(): boolean {
    return storageGetBoolean(STORAGE_KEY_SHOWING_SIDEBAR);
  }
  set dashboardSidebarVisible(value: boolean) {
    storageSetBoolean(STORAGE_KEY_SHOWING_SIDEBAR, value);
  }

  get idsVisible(): boolean {
    return storageGetBoolean(STORAGE_KEY_IDS_VISIBLE);
  }
  set idsVisible(value: boolean) {
    storageSetBoolean(STORAGE_KEY_IDS_VISIBLE, value);
  }

  get mapTypes(): string[] {
    return MAP_TILES.map((it) => it.name);
  }
  get activeMapType(): string {
    return storageGetString(STORAGE_KEY_MAP_TYPE) ?? this.mapTypes[0];
  }
  set activeMapType(type: string) {
    storageSetString(STORAGE_KEY_MAP_TYPE, type);
  }

  constructor(
    private authService: AuthenticationService,
    private dashboardService: DashboardService,
    private router: Router
  ) {
    //- cg: migrate old starred events, delete at some point in the future... - July 15, 2024
    {
      const storage = window.localStorage;
      for (let i = 0; i < storage.length; i += 1) {
        const prevKey = storage.key(i);
        // dashboard.TOURNAMENT.144.starred
        const parts = prevKey.split('.');
        if (parts.length != 4) { continue; }
        const [_, _type, id, suffix] = parts;
        if (suffix != 'starred') { continue; }
        const newKey = dashboardStarredStorageKeyFrom(Number.parseInt(id));
        const prev = storageGetBoolean(prevKey);
        storageSetBoolean(newKey, prev);
        storage.removeItem(prevKey);
      }
    }

    this.authService.loggedInUser$
      .pipe(
        filter((it) => !!it),
        switchMap((user) => {
          this._dashboardsLoading.next(true);
          return this.dashboardService.dashboards(user.id);
        })
      )
      .subscribe({
        next: (response) => this.bindDashboards(response),
        error: (error) => {
          console.error(error);
          this._dashboardsLoading.next(false);
        },
      });
  }

  selectDashboard(id: number, navigate: boolean) {
    this.downloadWaterbodies(id);

    const dashboard = this.idToDashboard.get(id);
    if (!dashboard) {
      this._dashboards
        .pipe(filter((it) => it.length > 0), take(1))
        .subscribe({
          next: () => this.setActiveDashboard(this.idToDashboard.get(id), navigate),
          error: console.error,
        });
    } else {
      this.setActiveDashboard(dashboard, navigate);
    }
  }

  dashboardToggleStar(id: number) {
    const key = dashboardStarredStorageKeyFrom(id);
    const starred = storageGetBoolean(key);
    storageSetBoolean(key, !starred);

    const dashboardsMap = this.idToDashboard;
    const dashboard = dashboardsMap.get(id);
    dashboard.starred = !starred;
    dashboardsMap.set(id, dashboard);

    const dashboards: UiDashboard[] = [];
    for (const it of dashboardsMap.values()) {
      dashboards.push(it);
    }
    dashboards.sort(this.dashboardSorter);

    this._dashboards.next(dashboards);
  }

  getRegionIds(dashboardId: number): Observable<number[]> {
    return this._dashboards.asObservable().pipe(
      map((it) => it.find(({ id }) => id == dashboardId)),
      filter((it) => !!it),
      take(1),
      map((it) => it.regionIds),
    );
  }

  getWaterbodies(id: number): Observable<DashboardWaterbodies> {
    this.downloadWaterbodies(id);
    // TODO: cache this!? Is this the right way? Is it expensive to create new observables?!
    const observable = this.idToWaterbodiesSubject.get(id).asObservable();
    return observable;
  }

  downloadWaterbodies(id: number) {
    const subjects = this.idToWaterbodiesSubject;
    let subject = subjects.get(id);
    if (!subject) {
      subject = new BehaviorSubject({ id, loading: true, waterbodies: [] });
      subjects.set(id, subject);

      this.dashboardService.dashboardWaterbodies(id, {}).subscribe({
        next: (response) => {
          subject.next({ id, loading: false, waterbodies: response });
        },
        error: (error) => {
          // TODO: return error?
          subject.next({ id, loading: false, waterbodies: [] });
          console.error(error);
        },
      });
    }
  }

  logout() {
    this.idsVisible = false;
    this._dashboards.next([]);
    this._dashboardsLoading.next(false);
    this._dashboard.next(null);
    this.authService.requestLogout();
  }

  private bindDashboards(response: DashboardsResponse) {
    const dashboards: UiDashboard[] = response
      .map((dashboard) => {
        const ui = uiFromDashboard(dashboard);
        this.idToDashboard.set(ui.id, ui);
        return ui;
      })
      .sort(this.dashboardSorter);

    this._dashboards.next(dashboards);
    this._dashboardsLoading.next(false);
  }

  private setActiveDashboard(dashboard: UiDashboard | null, navigate: boolean) {
    if (!dashboard) return;

    this._dashboard.next(dashboard);
    if (navigate) {
      this.router.navigate(dashboard.links[0].routerLink);
    }
  }
}

const STORAGE_KEY_SHOWING_SIDEBAR = 'dash:showing_sidebar';
const STORAGE_KEY_MAP_TYPE = 'dash:map_type';
const STORAGE_KEY_IDS_VISIBLE = 'dash:ids_visible';

export function storageSetBoolean(key: string, value: boolean) {
  try {
    localStorage?.setItem(key, value ? '1' : '0');
  } catch (e) {
    console.error(e);
  }
}
export function storageGetBoolean(key: string): boolean {
  let result = false;
  try {
    const value = localStorage?.getItem(key);
    if (value == '1') {
      result = true;
    }
  } catch (e) {
    console.error(e);
  }
  return result;
}

export function storageSetString(key: string, value: string) {
  try {
    localStorage?.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
}
export function storageGetString(key: string): string | null {
  let result: string | null = null;
  try {
    result = localStorage?.getItem(key);
  } catch (e) {
    console.error(e);
  }
  return result;
}

export function storageSetInt(key: string, value: number) {
  try {
    localStorage?.setItem(key, value.toString());
  } catch (e) {
    console.error(e);
  }
}
export function storageGetInt(key: string): number | null {
  let result: number | null = null;
  try {
    const value = localStorage?.getItem(key);
    if (value) {
      result = parseInt(value);
    }
  } catch (e) {
    console.error(e);
  }
  return result;
}

export function dashboardStorageKeyFrom(id: number, prop: string): string {
  return `dashboard.${id}.${prop}`;
}

export function dashboardStarredStorageKeyFrom(id: number): string {
  return dashboardStorageKeyFrom(id, 'starred');
}

function uiFromDashboard(dashboard: Dashboard): UiDashboard {
  let hasHourlyAccess = false;
  const regionIds: number[] = [];

  const links: UiDashboardLink[] = [];
  for (const access of dashboard.access) {
    if (access == 'HOURLY') { hasHourlyAccess = true; }
    const link = dashboardKindToLink(dashboard.id, access);
    if (link) { links.push(link); }
  }
  if (dashboard.dashboard_boundary_logical_boundaries?.length) {
    const link: UiDashboardLink = {
      title: 'Subregion',
      iconName: 'layers',
      routerLink: ['/dashboards', dashboard.id, 'region'],
    };
    links.splice(1, 0, link);

    for (const { id } of dashboard.dashboard_boundary_logical_boundaries) {
      regionIds.push(id);
    }
  }

  const logoUrl = dashboard.logo_url ? `${dashboard.logo_url}:100` : null;
  const starredKey = dashboardStarredStorageKeyFrom(dashboard.id);
  const starred = storageGetBoolean(starredKey);
  const hasTournaments = (dashboard.tournaments?.length ?? 0) > 0;
  const hasRegions = (dashboard.logical_boundaries?.length ?? 0) > 0;
  let type = 'Dashboard';
  if (hasTournaments) {
    type = 'Event Dashboard';
  } else if (hasRegions) {
    type = 'Region Dashboard';
  }

  const timezones: string[] = [];
  {
    const uniqueTimezones = new Map<string, void>();
    uniqueTimezones.set(dashboard.iana_timezone);
    for (const it of dashboard.tournaments) {
      uniqueTimezones.set(it.iana_timezone ?? 'UTC');
    }
    // TODO: ... regions could have multiple timezones...
    //for (const it of dashboard.logical_boundaries) {
    //}
    for (const it of uniqueTimezones.keys()) {
      timezones.push(it);
    }
  }

  const result: UiDashboard = {
    id: dashboard.id,
    name: dashboard.name,
    type,
    dateFrom: toDateTime(dashboard.date_from, dashboard.iana_timezone),
    dateTo: toDateTime(dashboard.date_to, dashboard.iana_timezone),
    logoUrl: logoUrl,
    timezone: dashboard.iana_timezone,
    timezones,
    defaultUnits: dashboard.default_units,
    boundary: dashboard.boundary,
    links,
    starred,
    regionIds,
    hasHourlyAccess,
  };
  return result;
}

