<div class="w-full min-h-screen overflow-y-auto bg-gray-800 flex flex-col">
    <!-- header -->
    <div class="w-full h-20 px-8 flex items-center space-x-4">
        <a [routerLink]="['/']">
            <img class="shrink-0 h-11" src="assets/mycatch/logo.png" />
        </a>
        <div class="grow"></div>
        <div *ngIf="(user | async); else login" class="flex items-center space-x-4">
            <span class="shrink-0 text-button text-white">
                {{ (user | async) | fullnameOrUsername:false:false }}
            </span>
            <a [routerLink]="['/dashboards']">
                <img class="shrink-0 w-9 h-9 outline outline-1 outline-gray-100 bg-white rounded-full" [src]="(user | async)?.avatar_url" />
            </a>
        </div>
        <ng-template #login>
            <a class="btn btn-outlined btn-white" [routerLink]="['/login']">Log In</a>
        </ng-template>
    </div>

    <!-- page content -->
    <div id="page-content" class="grow bg-gradient-to-t from-gray-300 flex flex-col">
        <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <div class="w-full bg-gray-700 px-12 pt-12 pb-4 flex flex-col space-y-5">
        <div class="flex space-x-20">
            <img src="assets/mycatch/logo.png" class="shrink-0 h-16" />
            <div class="grow"></div>
            <div class="shrink-0 flex flex-col space-y-2 text-white/50">
                <h5 class="text-white">Get in Touch</h5>
                <span class="flex space-x-2 items-center">
                    <svg-icon name="email" class="size-6" svgClass="fill-white/50"></svg-icon>
                    <a href="mailto:sean@anglersatlas.com?subject=MyCatch%20Dashboards">sean&#64;anglersatlas.ca</a>
                </span>
            </div>
            <div class="shrink-0 flex flex-col space-y-2 text-white/50">
                <h5 class="text-white">Social</h5>
                <span class="flex space-x-2 items-center">
                    <svg-icon name="facebook" class="size-6" svgClass="fill-white/50"></svg-icon>
                    <a href="https://www.facebook.com/MyCatchbyAnglersAtlas">Facebook</a>
                </span>
                <span class="flex space-x-2 items-center">
                    <svg-icon name="instagram" class="size-6" svgClass="fill-white/50"></svg-icon>
                    <a href="https://www.instagram.com/mycatchbyanglersatlas/">Instagram</a>
                </span>
                <span class="flex space-x-2 items-center">
                    <svg-icon name="youtube" class="size-6" svgClass="fill-white/50"></svg-icon>
                    <a href="https://www.youtube.com/channel/UCtM_EgUt5vNdqD8nqlSpaDg">Youtube</a>
                </span>
            </div>
        </div>
        <div class="w-full bg-gray-500" style="height: 1px"></div>
        <span class="text-white/50">© {{ currentYear }} MyCatch. All rights reserved.</span>
    </div>
</div>
