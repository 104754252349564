import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@aa/authentication/authentication.service';

@Component({
    selector: 'dash-landing',
    templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit {

    user = this.authService.loggedInUser$;

    currentYear = 2022;

    constructor(private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.currentYear = (new Date()).getFullYear();
    }
}
