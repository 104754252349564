import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@aa/authentication/authentication.service';
import { User } from '@aa/models/user';
import { UserService } from '@aa/services/user.service';

@Component({
  selector: 'dash-landing-login',
  templateUrl: './login.component.html',
  host: {
    class: 'relative grow w-full face-gradient flex flex-col',
  }
})
export class LoginComponent {
  loggingIn = false;
  errorMessage: string | null = null;
  showingPassword = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
  }

  onClickLogin(email: string, password: string) {
    if (this.loggingIn) return;
    this.loggingIn = true;
    this.errorMessage = null;

    this.authService
      .login(email, password)
      .pipe(
        switchMap((response) => {
          if (!response?.ok) throw new Error();
          return this.userService.self();
        }),
      )
      .subscribe({
        next: (user: User | null) => {
          this.authService.setUser(user);
          this.loggingIn = false;
          if (user) {
            this.router.navigate(['/dashboards']);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          if (error?.error && 'message' in error.error) {
            this.errorMessage = error.error.message;
          } else if ('message' in error) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Failed to login';
          }

          this.loggingIn = false;
        },
      });
  }

  onClickToggleShowPassword() {
    this.showingPassword = !this.showingPassword;
  }
}

