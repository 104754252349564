import { Inject, Injectable, InjectionToken } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { take } from 'rxjs/operators';

export const SVG_BASE_HREF = new InjectionToken<string>('aa-svg-base-href');
export const SVG_LIST = new InjectionToken<string[]>('aa-svg-list');

@Injectable({
  providedIn: 'root'
})
export class SvgPreloaderService {

  constructor(
    @Inject(SVG_BASE_HREF) private svgBaseHref: string,
    @Inject(SVG_LIST) private svgs: string[],
    private svgRegistry: SvgIconRegistryService,
  ) { }

  loadIcons() {
    for (const svg of this.svgs) {
      const url = `${this.svgBaseHref}/${svg}.svg`;
      this.svgRegistry.loadSvg(url, svg)?.pipe(take(1)).subscribe();
    }
  }
}
