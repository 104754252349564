import {of as observableOf,  Observable, ObservableInput } from 'rxjs';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Page } from '@aa/models/page';

export class UiResponse<T> {
  isLoading = false;
  isSuccessful = false;
  result?: T;
  errorMessage?: string;
  headers?: HttpHeaders;
}

export const toUiResponse = <T>(
  response: HttpResponse<T>,
  _: number
): UiResponse<T> => uiResponseFromHttpResponse(response);

export const toUiResponseFromPage = <T>(
  page: Page<T>,
  _: number
): UiResponse<Page<T>> => uiResponseFromPage(page);

export const toFailureUiResponse = <T>(
  err: any,
  _: Observable<UiResponse<T>>
): ObservableInput<UiResponse<T>> => observableOf(FAILURE<T>(err as string));

export function IN_PROGRESS<T>(): UiResponse<T> {
  const result = new UiResponse<T>();
  result.isLoading = true;
  return result;
}

export function SUCCESS<T>(value?: T): UiResponse<T> {
  const result = new UiResponse<T>();
  result.result = value;
  result.isSuccessful = true;
  return result;
}

export function FAILURE<T>(message: string): UiResponse<T> {
  const result = new UiResponse<T>();
  result.errorMessage = message;
  return result;
}

// PRIVATE

function uiResponseFromHttpResponse<T>(
  response: HttpResponse<T>
): UiResponse<T> {
  const result = new UiResponse<T>();
  result.result = response.body;
  result.headers = response.headers;
  result.isSuccessful = response.ok;
  // result.errorMessage = ; // TODO: what do?
  return result;
}

function uiResponseFromPage<T>(page: Page<T>): UiResponse<Page<T>> {
  const result = new UiResponse<Page<T>>();
  result.result = page;
  result.headers = page.headers;
  result.isSuccessful = true;
  return result;
}

