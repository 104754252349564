import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { User } from '@aa/models/user';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isUrlRelative = req.url.charAt(0) === '/';
    const isLoggedIn = this.authenticationService.isLoggedIn();
    const isOauthRequest = req.url.match(/oauth/);

    if (isLoggedIn && isUrlRelative && !isOauthRequest) {
      return this.authorizeRequestAndHandle(req, next)
        .pipe(
          this.catchAuthErrorAndRefresh(req, next),
          this.handleResponse(),
        );
    }

    return next.handle(req).pipe(this.handleResponse());
  }

  private catchAuthErrorAndRefresh(request: HttpRequest<any>, next: HttpHandler): OperatorFunction<HttpEvent<any>, HttpEvent<any> | Observable<HttpEvent<any>>> {
    return catchError<HttpEvent<any>, Observable<HttpEvent<any>>>((error) => {
      if (error instanceof HttpErrorResponse && (error.status == 401 || error.status == 403)) {
        return this.authenticationService.refresh().pipe(
          switchMap(() => this.authorizeRequestAndHandle(request, next))
        );
      }

      throw error;
    });
  }

  private handleResponse(): MonoTypeOperatorFunction<HttpEvent<any>> {
    return tap<HttpEvent<any>>((event) => {
      if (!(event instanceof HttpResponse)) return;

      const response: HttpResponse<any> = event;
      if (response.status === 401 && !response.url.endsWith('users/self')) {
        // TODO: How to dynamically tell this garbage what to do?
        // this.router.navigate(['login'], { replaceUrl: true });
      }

      const auth = this.authenticationService;
      if (response.status === 200 && !!auth.userSnapshot) {
        const matcher = new RegExp(`\/v1\/users\/(?:self|${auth.userSnapshot.id})$`);
        if (response.url.match(matcher)) {
          const user = response.body as User;
          if (!!user) {
            auth.setUser(user);
          }
        }
      }
    });
  }

  private authorizeRequestAndHandle(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.authenticationService.tryAccessToken().pipe(
      switchMap((token) => {
        let authedRequest = request;
        if (token) {
          authedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
        }

        return next.handle(authedRequest);
      })
    );
  }

}
