<div class="bg-white border border-gray-100 rounded overflow-clip max-h-full flex flex-col">
  <div class="flex-shrink-0 h-11 border-b bg-white border-b-gray-100 text-sm font-bold gap-2 grid grid-cols-{{ displayedColumns.length }} items-center">
    @for (column of columns; track column.title) {
      <div
        class="flex space-x-1 items-center select-none truncate"
        [class.justify-end]="!$first"
        [class.pl-4]="$first"
        [class.pr-4]="$last"
        [class.hover:cursor-pointer]="column.sort"
        [class.hover:underline]="column.sort"
        (click)="onClickColumnHeader($index)"
      >
        {{ column.title }}
        @if (column.sort) {
          @switch (columnSort?.columnProperty == column.property ? columnSort.sort : null) {
            @case ('ASC') {
              <svg-icon name="solid-arrow-up" svgClass="size-6 fill-gray-600" />
            }
            @case ('DESC') {
              <svg-icon name="solid-arrow-down" svgClass="size-6 fill-gray-600" />
            }
            @default {
              <svg-icon name="solid-arrow-up-down" svgClass="size-6 fill-gray-100" />
            }
          }
        }
      </div>
    }
  </div>
  @if (dataSource.length) {
    <cdk-virtual-scroll-viewport [itemSize]="rowHeightPx()" [style.height.px]="tableHeightPx()">
      <div
        *cdkVirtualFor="let element of dataSource; let odd = odd; let index = index"
        class="h-11 text-sm hover:bg-gray-50 border-gray-100 grid gap-2 grid-cols-{{ displayedColumns.length }} items-center"
        [class.bg-gray-25]="odd"
        [class.bg-white]="!odd"
        [class.hover:bg-gray-50]="elementsClickable || canClickRow(element)"
        [class.hover:cursor-pointer]="canClickRow(element)"
        [class.border-t]="borderAboveRowIndex !== undefined && index == borderAboveRowIndex"
        (click)="onClickRow(element)"
      >
        @for (column of columns; track column.title) {
          <div
            class="group relative"
            [class.text-left]="$first"
            [class.text-right]="!$first"
            [class.pl-4]="$first"
            [class.pr-4]="$last"
            [class.pr-6]="!$first && !$last && column.sort"
            [class.hover:cursor-pointer]="column.clickable"
            [class.hover:underline]="column.clickable"
            (click)="onClickElement(element, column.property)"
            [attr.title]="column.customContent ? null : column.property ? element[column.property] ?? null : element[element._index]"
          >
            @if (column.customContent) {
              <ng-container *ngTemplateOutlet="content.templateRef; context: { $implicit: element, column: column }" />
            } @else {
              <div class="truncate">
                @if (column.numberFormat) {
                  {{ (column.property ? element[column.property] ?? column.nullValue : element[element._index]) | number: column.numberFormat }}
                } @else {
                  {{ column.property ? element[column.property] ?? column.nullValue : element[element._index] }}
                }
              </div>
              @if (column.copyable) {
                <div class="hidden absolute inset-y-0 left-0 right-0 mx-auto group-hover:flex items-center justify-center">
                  <button
                    class="rounded-full bg-purple px-4 py-2 flex items-center justify-center gap-1"
                    [class.left-4]="$first"
                    title="Copy"
                    (click)="onClickCopyElement(element, column.property)"
                  >
                    <svg-icon name="copy" class="size-4" svgClass="fill-white w-4 h-4" />
                    @if (copiedIndex() == element._index) {
                      <span class="transition ease-in-out text-white text-button">COPIED!</span>
                    }
                  </button>
                </div>
              }
            }
          </div>
        }
      </div>
    </cdk-virtual-scroll-viewport>
  } @else {
    <h4 class="text-center p-4" [innerHtml]="noDataMessage ?? 'No Data'"></h4>
  }
</div>
