import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Directive, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, WritableSignal, computed, input, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export type TableColumnSortOrder = 'ASC' | 'DESC';

export type TableColumnSortFunc<DataType> = (a: DataType, b: DataType, order: TableColumnSortOrder) => number;
export type TableColumnSortLike<DataType> = 'number' | 'string' | TableColumnSortFunc<DataType>;

export type TableRowsClickableFunc<DataType> = (it: DataType) => boolean;
export type TableRowsClickableKind<DataType> = boolean | TableRowsClickableFunc<DataType>;

export type TableColumn<DataType> = {
  title: string;

  property?: keyof DataType;
  nullValue?: string;

  sort?: TableColumnSortLike<DataType>;
  clickable?: boolean;

  copyable?: true;
  customContent?: true;

  numberFormat?: string;
};

export type TableClickElementEvent<DataType> = {
  element: DataType[keyof DataType];
  index: number;
};

// TODO: filters on non-string type columns?
export type TableColumnFilters<DataType> = { [key in keyof DataType]?: DataType[key][] };

type TableColumnSort<DataType> = {
  columnProperty: keyof DataType,
  sort: TableColumnSortOrder;
};

type WithIndex<DataType> = DataType & { _index: number; };

@Directive({
  selector: "[dashTableCustomContent]",
})
export class TableCustomContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) { }
}

export function downloadCsv<DataType>(
  document: Document,
  filename: string,
  columns: TableColumn<DataType>[],
  data: DataType[]
) {
  const csv = csvFromColumnsAndData(columns, data);
  const blob = new Blob([csv], { type: 'text/csv' });

  const url = window.URL;
  const objectUrl = url.createObjectURL(blob);

  const link: HTMLAnchorElement = document.createElement('a');
  link.download = filename;
  link.href = objectUrl;
  link.dispatchEvent(new MouseEvent('click'));
  setTimeout(() => url.revokeObjectURL(objectUrl));
}

export function csvFromColumnsAndData<DataType>(
  columns: TableColumn<DataType>[],
  data: DataType[]
): string {
  const parts = new Array<string>(columns.length);

  let csv = '';

  //- cg: header
  columns.forEach((column, columnIndex) => {
    parts[columnIndex] = `"${column.title}"`;
  });
  csv += parts.join(',') + '\n';
  //- cg: data
  data.forEach((row) => {
    columns.forEach((column, columnIndex) => {
      const prop = row[column.property];

      let part = '';
      if (typeof prop === 'string') {
        if (prop) {
          part = `"${prop}"`;
        }
      } else if (typeof prop === 'number' || prop) {
        part = prop.toString();
      }

      parts[columnIndex] = part;
    });
    csv += parts.join(',') + '\n';
  });

  return csv;
}

@Component({
  selector: 'dash-table',
  templateUrl: './table-virtual.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<DataType> implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  @Input() data: DataType[] = [];
  @Input() columns: TableColumn<DataType>[];
  @Input() filters: TableColumnFilters<DataType>;

  rowHeightPx = input(44);
  numRows = input(10);

  private numItems: WritableSignal<number> = signal(0);

  tableHeightPx = computed(() => {
    const numItems = this.numItems();
    const numRows = this.numRows();
    const rowHeight = this.rowHeightPx();
    const totalHeight = numItems * rowHeight;
    const maxHeight = numRows * rowHeight;
    const height = Math.min(totalHeight, maxHeight);
    return height;
  });


  @Input() borderAboveRowIndex?: number;

  // TODO: is it possible to check subscribers on `clickRow` and set from that?
  @Input() rowsClickable: TableRowsClickableKind<DataType> = false;

  @Output() clickElement = new EventEmitter<TableClickElementEvent<DataType>>();
  @Output() clickRow = new EventEmitter<number>();

  @ContentChild(TableCustomContentDirective) content: TableCustomContentDirective;

  dataSource: WithIndex<DataType>[] = [];
  extraData?: DataType[] | null = null;

  elementsClickable: boolean = false;

  displayedColumns: string[] = [];

  columnSort: TableColumnSort<DataType> | null = null;

  noDataMessage: SafeHtml | null = null;

  constructor(private sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document) {
  }

  canClickRow(row: DataType): boolean {
    const clickable = this.rowsClickable;
    if (typeof clickable === "boolean") {
      return clickable;
    } else if (typeof clickable === "function") {
      return clickable(row);
    }

    return false;
  }

  min(a: number, b: number): number {
    return Math.min(a, b);
  }

  ngOnChanges(changes: SimpleChanges) {
    let shouldRefresh = false;

    if ('data' in changes) {
      shouldRefresh = true;
    }

    if ('columns' in changes) {
      const prevSort = this.columnSort;
      let newSort: TableColumnSort<DataType> | null = null;

      let elementsClickable = false;

      const displayedColumns: string[] = [];
      for (const column of this.columns) {
        if (prevSort && prevSort.columnProperty == (column.property ?? column.title)) {
          newSort = prevSort;
        }

        if (column.clickable) {
          elementsClickable = true;
        }

        displayedColumns.push(column.property?.toString() ?? column.title);
      }

      this.elementsClickable = elementsClickable;
      this.displayedColumns = displayedColumns;
      this.columnSort = newSort;

      shouldRefresh = true;
    }

    if ('filters' in changes) {
      shouldRefresh = true;
    }

    if (shouldRefresh) {
      this.applyFiltersAndSorts();
    }
  }

  onClickColumnHeader(index: number) {
    const column = this.columns[index];
    // TODO: Is there a reason other than sorting to click a header?
    if (!column.sort) return;

    let prevSort = this.columnSort;
    // only keep `prevSort` if it's for the same column
    if (prevSort && prevSort.columnProperty != column.property) {
      prevSort = null;
    }

    let sort: TableColumnSort<DataType> | null = null;
    switch (prevSort?.sort) {
      case 'ASC':
        // Do nothing, just clear the sort by leaving `sort` null
        break;
      case 'DESC':
        sort = {
          columnProperty: column.property,
          sort: 'ASC',
        };
        break;
      default:
        sort = {
          columnProperty: column.property,
          sort: 'DESC',
        };
        break;
    }
    this.columnSort = sort;

    this.applyFiltersAndSorts();
  }

  onClickElement(element: WithIndex<DataType>, property: keyof DataType) {
    this.clickElement.emit({ element: element[property], index: element._index });
  }

  copiedIndex = signal(-1);
  copiedIndexTimeout: any;

  async onClickCopyElement(element: WithIndex<DataType>, property: keyof DataType) {
    const text = property ? (element[property] ?? null) : element[element._index];
    if (!text) return;

    try {
      await navigator.clipboard.writeText(text)
      this.copiedIndex.set(element._index);
      clearTimeout(this.copiedIndexTimeout);
      this.copiedIndexTimeout = setTimeout(() => this.copiedIndex.set(-1), 1000);
    } catch (error) {
      console.error(error);
    }
  }

  onClickRow(row: WithIndex<DataType>) {
    if (this.canClickRow(row)) {
      this.clickRow.emit(row._index);
    }
  }

  downloadCsv(filename: string) {
    downloadCsv(this.document, filename, this.columns, this.dataSource);
  }

  // Take the original data and
  private applyFiltersAndSorts() {
    // NOTE(cg): We persist the original index of the data so if the order of it changes we what original data item it points to.
    //  Used for a click listener that doesn't send all element's properties over for the table  (???)
    const finalData: WithIndex<DataType>[] = [];

    const filters = this.filters;
    const hasFilters = filters && Object.keys(filters).length > 0;
    const filterValues: [keyof DataType, DataType[keyof DataType]][] = [];

    const data = this.data;
    for (let index = 0; index < data.length; index += 1) {
      const item = data[index];

      let shouldInclude = !hasFilters;
      for (const prop in filters) {
        const values = filters[prop];
        for (const value of values) {
          filterValues.push([prop, value]);

          if (typeof value === 'string') {
            const index = (item[prop] as unknown as string).toLowerCase().indexOf(value.toLowerCase());
            if (index != -1) {
              shouldInclude = true;
            }
          } else if (item[prop] == value) {
            shouldInclude = true;
          }
        }
        if (!shouldInclude) {
          break;
        }
      }

      if (shouldInclude) {
        finalData.push({ ...item, _index: index });
      }
    }

    if (filterValues.length) {
      const [prop, value] = filterValues[0];
      let columnTitle: string = '???';
      for (const column of this.columns) {
        if (column.property == prop) {
          columnTitle = column.title;
          break;
        }
      }

      // TODO: Support multiple filters...
      this.noDataMessage = this.sanitizer.bypassSecurityTrustHtml(`No Angler Data for <b>${columnTitle}</b> matching <b>${value}</b>`);
    }

    const columnSort = this.columnSort;
    if (columnSort && columnSort.sort) {
      for (const column of this.columns) {
        if (column.property != columnSort.columnProperty) continue;
        if (!column.sort) break;

        let comparator: ((a: DataType, b: DataType) => number);
        // TODO: creating a comparator for every column!??!
        switch (column.sort) {
          case 'number':
            comparator = (a, b) => {
              const aNumber = a[column.property] as unknown as number;
              const bNumber = b[column.property] as unknown as number;
              switch (columnSort.sort) {
                case 'ASC': return aNumber - bNumber;
                case 'DESC': return bNumber - aNumber;
              }
            };
            break;

          case 'string':
            comparator = (a, b) => {
              const aString = a[column.property]?.toString();
              const bString = b[column.property]?.toString();

              // TODO: is this right? doesn't depend on sort order
              if (!aString) {
                return 1;
              } else if (!bString) {
                return -1;
              } else if (!aString || !bString) {
                return 0;
              } else {
                switch (columnSort.sort) {
                  case 'ASC': return aString.localeCompare(bString);
                  case 'DESC': return bString.localeCompare(aString);
                }
              }
            };
            break;

          default:
            comparator = (a, b) => {
              return (column.sort as TableColumnSortFunc<DataType>)(a, b, columnSort.sort);
            }
            break;
        }

        finalData.sort(comparator);

        break;
      }
    }

    this.numItems.set(finalData.length);
    this.dataSource = finalData;
    // NOTE(cg): CdkVirtualScrollViewport doesn't listen for changes to its datasource, so we have to manually make it check the viewport size so it's properly sized.
    //  https://github.com/angular/components/issues/10117
    setTimeout(() => this.cdkVirtualScrollViewport?.checkViewportSize());
  }
}

