<div class="flex my-4 gap-x-4">
    <h3 class="truncate flex items-center gap-x-2">
        Hourly Breakdown
        @if (loading) {
	    <svg-icon name="spinner" svgClass="animate-spin size-4" />
        }
    </h3>
    @if (!loading) {
        <div class="grow flex space-x-2 items-center">
          <div class="h-10 px-4 border border-gray-100 rounded-full flex items-center space-x-2 bg-white">
            <div
              class="flex items-center justify-center hover:cursor-pointer hover:bg-gray-100 hover:rounded-full"
              [title]="isPlaying ? 'Pause' : 'Play'"
              (click)="onClickPlayPause()"
            >
              <svg-icon [name]="isPlaying ? 'pause' : 'play'" class="size-6" [svgClass]="!isPlaying ? 'fill-green' : null"></svg-icon>
            </div>
            <span class="text-body-small text-gray-400">0</span>
            <input type="range" [min]="0" [max]="numDays - 1" [ngModel]="selectedDay" (ngModelChange)="onChangeDay($event, true, false)" />
            <span class="text-body-small text-gray-400">{{ numDays - 1 }} days</span>
          </div>
          <div>
            {{ indexToDay.get(selectedDay) | toDateTime | dateTime: dateTimeFormat }}
          </div>
        </div>
    } @else {
        <div class="grow"></div>
    }
    <dash-dropdown #dropdown
                   class="shrink-0 w-full max-w-xs"
                   [selectedValue]="selectedTimezone">
        @for (timezone of allTimezones; track timezone) {
	    <dash-dropdown-option [text]="timezone"
                                  [selected]="selectedTimezone == timezone"
                                  (click)="onSelectTimezone(dropdown, timezone)" />
        }
        <dash-dropdown-option text="UTC"
                              [selected]="selectedTimezone == 'UTC'"
                              (click)="onSelectTimezone(dropdown, 'UTC')" />
    </dash-dropdown>
</div>

<div class="mt-2 grid grid-cols-3 gap-x-2">
    @if (loading && firstLoad) {
        <div class="grow shrink-0 border border-gray-100 rounded overflow-hidden bg-white p-4 flex flex-col gap-y-4">
            <h3 class="animate-pulse w-1/2 bg-gray-100 rounded text-transparent">.</h3>
            <div class="animate-pulse aspect-square size-full rounded bg-gray-100"></div>
        </div>
        <div class="grow shrink-0 border border-gray-100 rounded overflow-hidden bg-white p-4 flex flex-col gap-y-4">
             <h3 class="animate-pulse w-1/2 bg-gray-100 rounded text-transparent">.</h3>
             <div class="animate-pulse aspect-square size-full rounded bg-gray-100"></div>
        </div>
        <div class="grow shrink-0 border border-gray-100 rounded overflow-hidden bg-white p-4 flex flex-col gap-y-4">
            <h3 class="animate-pulse w-1/2 bg-gray-100 rounded text-transparent">.</h3>
            <div class="animate-pulse aspect-square size-full rounded bg-gray-100"></div>
        </div>
    } @else {
        @for (chart of charts; track chart.title; let chartIdx = $index) {
            <div class="relative grow shrink-0 border border-gray-100 rounded overflow-hidden bg-white p-4 flex flex-col gap-y-4">
                <h4 class="grow">{{ chart.data.datasets[selectedDay].label }}</h4>
                <canvas #chartComp="base-chart" baseChart
			type="polarArea"
			[data]="chart.data"
			[options]="chart.options"
			[plugins]="chart.plugins"
		></canvas>
            </div>
        }
    }
</div>
