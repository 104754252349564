import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { ChartData, ChartDataset, ChartOptions, Plugin } from "chart.js";
import { ReplaySubject, Subscription, distinctUntilChanged, map, switchMap } from "rxjs";
import { COLORS } from "../../app.module";
import { DashboardService, Params } from "../../dashboard.service";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { BaseChartDirective } from "ng2-charts";
import { formatNumber } from "@angular/common";
import { DateTime } from "luxon";

type HourlyBreakdownChart = {
  title: string;
  data: ChartData<'polarArea'>;
  options: ChartOptions<'polarArea'>;
  plugins: [Plugin<'polarArea'>];
};

const CHART_DATA_LABELS = [
  '00:00 - 01:00',
  '01:00 - 02:00',
  '02:00 - 03:00',
  '03:00 - 04:00',
  '04:00 - 05:00',
  '05:00 - 06:00',
  '06:00 - 07:00',
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00',
  '23:00 - 24:00',
];

// TODO: when changing timezone, is it possible to animate the chart "spinning" into correct position

@Component({
  selector: 'dash-hourly-breakdown',
  templateUrl: './hourly-breakdown.component.html',
})
export class HourlyBreakdownComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(BaseChartDirective) chartComponents: QueryList<BaseChartDirective>;

  @Input({ required: true }) dashboardId: number;
  @Input() logicalBoundaryId?: number;
  @Input() waterbodyId?: number;
  @Input() timezones: string[] = [];

  selectedTimezone: string;
  allTimezones: string[] = [];

  loading = true;
  firstLoad = true;

  charts: HourlyBreakdownChart[] = [];

  isPlaying = false;
  playingTimer: number;

  indexToDay = new Map<number, string>();
  selectedDay = -1;
  numDays = 0;

  dateTimeFormat = DateTime.DATE_MED;

  onClickPlayPause() {
    this.isPlaying = !this.isPlaying;
    if (this.isPlaying) {
        let firstRun = true;
        const timerHandler: TimerHandler = () => {
            let day = this.selectedDay + 1;
            if (day >= this.indexToDay.size) {
                if (firstRun) {
                    day = 0;
                } else {
                    this.isPlaying = false;
                    clearInterval(this.playingTimer);
                    return;
                }
            }
            this.onChangeDay(day, false, false);
            this.selectedDay = day;

            firstRun = false;
        };
        this.playingTimer = setInterval(timerHandler, 500);
    } else {
        clearInterval(this.playingTimer);
    }
  }

  onChangeDay(day: number, stopTimer: boolean, force: boolean) {
    if (stopTimer) {
      this.isPlaying = false;
      clearInterval(this.playingTimer);
    }

    if (!force && this.selectedDay == day) return;

    this.selectedDay = day;

    const numDays = this.indexToDay.size;
    for (let chartIdx = 0; chartIdx < this.charts.length; chartIdx += 1) {
      for (let dayIdx = 0; dayIdx < numDays; dayIdx += 1) {
        this.charts[chartIdx].data.datasets[dayIdx].hidden = dayIdx != day;
      }
    }

    this.chartComponents.forEach((it) => it.update());
  }

  private requestSubject = new ReplaySubject<void>(1);
  private requests = this.requestSubject.asObservable().pipe(
    map(() => {
      const params: Params = {
        dashboardId: this.dashboardId,
        timezone: this.selectedTimezone,
      };
      if (this.waterbodyId) {
        params['waterbody_id'] = this.waterbodyId;
      }
      if (this.logicalBoundaryId) {
        params['logical_boundary_id'] = this.logicalBoundaryId;
      }
      return params;
    }),
    distinctUntilChanged(),
    switchMap((params) => {
      this.loading = true;
      return this.dashboardService.hourly(this.dashboardId, params);
    }));

  private subscription = Subscription.EMPTY;

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.subscription = this.requests.subscribe({
      next: (response) => {
        this.indexToDay.clear();

        const days = Object.keys(response);
        const visibleIdx = (days.length - 1);
        this.numDays = days.length;
        this.selectedDay = visibleIdx;

        const hoursDatasets = new Array<ChartDataset<'polarArea'>>(24);
        const catchDatasets = new Array<ChartDataset<'polarArea'>>(24);
        const crateDatasets = new Array<ChartDataset<'polarArea'>>(24);

        for (let dayIdx = 0; dayIdx < days.length; dayIdx += 1) {
          const day = days[dayIdx];

          this.indexToDay.set(dayIdx, day);

          let totalHours = 0;
          let totalCatches = 0;

          const cpue = new Array<number>(24);
          let cpueAvg = 0;
          for (let hour = 0; hour < cpue.length; hour += 1) {
            const hoursFished = response[day].hours[hour];
            const catches = response[day].catches[hour];
            const c = (hoursFished > 0) ? (catches / hoursFished) : 0;

            cpue[hour] = c;
            cpueAvg += c;

            totalHours += hoursFished;
            totalCatches += catches;
          }

          cpueAvg = cpueAvg / cpue.length;

          hoursDatasets[dayIdx] = { label: `Hours (${totalHours.toFixed(1)})`, data: response[day].hours, hidden: dayIdx != visibleIdx };
          catchDatasets[dayIdx] = { label: `Catches (${totalCatches})`, data: response[day].catches, hidden: dayIdx != visibleIdx };
          crateDatasets[dayIdx] = { label: `Catch Rate (${cpueAvg.toFixed(2)})`, data: cpue, hidden: dayIdx != visibleIdx };
        }

        this.charts = [
          {
            title: 'Hours',
            data: { labels: CHART_DATA_LABELS, datasets: hoursDatasets },
            options: this.buildChartOptions('1.0-1'),
            plugins: [AA_HOURLY_PLUGIN],
          },
          {
            title: 'Catches',
            data: { labels: CHART_DATA_LABELS, datasets: catchDatasets },
            options: this.buildChartOptions('1.0-0'),
            plugins: [AA_HOURLY_PLUGIN],
          },
          {
            title: 'Catch Rate',
            data: { labels: CHART_DATA_LABELS, datasets: crateDatasets },
            options: this.buildChartOptions('1.0-2'),
            plugins: [AA_HOURLY_PLUGIN],
          },
        ];

        this.loading = false;
        this.firstLoad = false;
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
      },
    });
  }

  ngOnDestroy() {
    this.requestSubject.complete();
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('timezones' in changes) {
      const lastSelectedTimezone = this.selectedTimezone;
      let hasSelectedTimezone = false;
      const timezones = new Map<string, void>();
      for (const it of this.timezones) {
        if (!this.selectedTimezone) { this.selectedTimezone = it; }
        if (lastSelectedTimezone && lastSelectedTimezone == it) { hasSelectedTimezone = true; }
        if (it == 'UTC') { continue; }
        timezones.set(it);
      }
      this.allTimezones.length = 0;
      for (const it of timezones.keys()) {
        this.allTimezones.push(it);
      }
      if (!hasSelectedTimezone) {
        this.selectedTimezone = this.allTimezones.length ? this.allTimezones[0] : 'UTC';
      }
    }
    if ('dashboardId' in changes || 'waterbodyId' in changes || 'logicalBoundaryId' in changes) {
      this.requestSubject.next();
    }
  }

  onSelectTimezone(dropdown: DropdownComponent, timezone: string) {
    dropdown.close();

    if (this.selectedTimezone != timezone) {
      this.selectedTimezone = timezone;
      this.requestSubject.next();
    }
  }

  private buildChartOptions(numberFormat: string): ChartOptions<'polarArea'> {
    const options: ChartOptions<'polarArea'> = {
      elements: {
        arc: {
          backgroundColor: COLORS['purple-faint'],
          borderColor: COLORS['purple'],
          borderWidth: 1,
        },
      },
      scales: {
        r: {
          pointLabels: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    options.plugins['aa-hourly-breakdown'] = { numberFormat };
    return options;
  }

}

const AA_HOURLY_PLUGIN: Plugin<'polarArea'> = {
  id: 'aa-hourly-breakdown',
  beforeLayout: (chart, _, options) => {
    const { ctx } = chart;

    const numberFormat = options['numberFormat'] ?? '1.0-0';

    if (chart.chartArea) {
      const padding = 20;
      chart.chartArea.top += padding;
      chart.chartArea.bottom -= padding;
      chart.chartArea.left += padding;
      chart.chartArea.right -= padding;
    }

    //- cg: measure dataset text
    const dataset = chart.data.datasets[0].data;
    let width = 0;
    for (let hour = 0; hour < 24; hour += 1) {
      const data = dataset[hour];
      if (data > 0) {
        const text = formatNumber(data, 'en-US', numberFormat);
        const m = ctx.measureText(text);
        width = Math.max(width, m.width);
      }
    }

    chart.options.layout.padding = width + 8;
  },
  afterDraw: (chart, _, options) => {
    const numberFormat = options['numberFormat'] ?? '1.0-0';

    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.strokeStyle = COLORS['gray-800'];

    const cx = chart.width / 2;
    const cy = chart.height / 2;
    const rx = chart.width / 2 - 2;
    const ry = chart.height / 2 - 2;

    const dataset = chart.data.datasets[0].data;

    //- cg: draw the circle outline
    ctx.beginPath();
    ctx.ellipse(cx, cy, rx, ry, 0, 0, 2 * Math.PI);
    ctx.stroke();
    const halfTickLength = 4;
    for (let hour = 0; hour < 24; hour += 1) {
      //- cg: draw ticks
      {
        const angle = (hour / 24) * 2 * Math.PI - (Math.PI / 2);

        //- cg: tick line
        {
          const x0 = cx + (rx - halfTickLength) * Math.cos(angle);
          const y0 = cy + (ry - halfTickLength) * Math.sin(angle);
          const x1 = cx + (rx + halfTickLength) * Math.cos(angle);
          const y1 = cy + (ry + halfTickLength) * Math.sin(angle);

          ctx.strokeStyle = COLORS['gray-800'];
          ctx.beginPath();
          ctx.moveTo(x0, y0);
          ctx.lineTo(x1, y1);
          ctx.stroke();
        }
        //- cg: tick label
        {
          const text = (hour == 0 ? '0/24' : hour).toString();
          const m = ctx.measureText(text);
          const w2 = m.width / 2;
          const h2 = (m.actualBoundingBoxAscent + m.actualBoundingBoxDescent) / 2;
          const offset = 4 + Math.sqrt(w2 * w2 + h2 * h2);
          const x = cx + (rx - halfTickLength - offset) * Math.cos(angle);
          const y = cy + (ry - halfTickLength - offset) * Math.sin(angle);
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = COLORS['gray-400'];
          ctx.fillText(text, x, y);
        }
      }
      //- cg: draw radial "border" lines
      {
        const angle = (hour / 24) * (2 * Math.PI) - (Math.PI / 2);

        const x0 = cx + rx * Math.cos(angle);
        const y0 = cy + ry * Math.sin(angle);

        ctx.strokeStyle = COLORS['gray-50'];
        ctx.beginPath();
        ctx.moveTo(cx, cy);
        ctx.lineTo(x0, y0);
        ctx.stroke();
      }
      //- cg: draw data values around edge
      {
        const data = dataset[hour];
        if (data > 0) {
          const flip = (hour >= 12) ? -1 : 1;

          // const text = data.toFixed(3);
          const text = formatNumber(data, 'en-US', numberFormat);
          const offset = -4;
          const angle = ((hour + 0.5) / 24) * 2 * Math.PI - (Math.PI / 2);
          const x = cx + (rx + offset) * Math.cos(angle);
          const y = cy + (ry + offset) * Math.sin(angle);

          ctx.save();
          ctx.translate(x, y);
          ctx.rotate(angle);
          ctx.scale(flip, flip);
          ctx.textAlign = (hour < 12) ? 'right' : 'left';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = COLORS['gray-600'];
          ctx.fillText(text, 0, 0);
          ctx.restore();
        }
      }
    }

    ctx.restore();
  },
};
