import { AuthenticationService } from '@aa/authentication/authentication.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';

// TODO: Better error message styling
// TODO: Navigating to a dashboard shows the same loading indicator as the dashboards list loading indicator. YUCK

@Component({
    selector: 'dash-dashboards',
    templateUrl: './dashboards.component.html',
    host: {
        class: 'relative grow w-full facet-gradient flex flex-col',
    }
})
export class DashboardsComponent {
    loggedIn = this.authService.isLoggedIn$;

    logs: boolean[] = [];

    dashboardsLoading = this.appService.dashboardsLoading;
    dashboards = this.appService.dashboards;

    navigating = this.appService.navigating(this.route);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appService: AppService,
        private authService: AuthenticationService,
    ) {
    }

    onClickLogout() {
        this.appService.logout();
        this.router.navigate(['/']);
    }

    onClickToggleStarred(id: number) {
        this.appService.dashboardToggleStar(id);
    }
}
