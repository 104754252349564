import { AuthenticationService } from "@aa/authentication/authentication.service";
import { formatDateTime, toDateTime } from "@aa/pipes/to-date.pipe";
import { DIALOG_DATA, Dialog, DialogConfig, DialogRef } from "@angular/cdk/dialog";
import { formatNumber } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, signal } from "@angular/core";
import { ChartData, ChartDataset, ChartEvent, ChartOptions } from "chart.js";
import { DateTime } from "luxon";
import { BaseChartDirective } from "ng2-charts";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { CHART_FONT, CHART_FONT_COLOR, CHART_SCALES_FONT, COLORS } from "../../app.module";
import { UiDashboard } from "../../app.service";
import { DashboardCatchLengthDataRangeResponse, DashboardCatchLengthResponse } from "../../dashboard.service";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { MediaData, MediaDialogComponent, MediaDialogData } from "../media-dialog/media-dialog.component";
import { TableColumn, TableComponent } from "../table/table.component";

export type CatchItem = {
  catch_log_id: number | string;
  creel_survey_id: number | string;
  length: number;
  caught_at: string;
  measurement_photo_url?: string;
};

export type ChartDataRange = {
  min: number;
  max: number;
  avg: number;
};

type IdTableRow = { id: number | string; url: string; };

@Component({
  selector: 'dash-lengths-histogram',
  templateUrl: './lengths-histogram.component.html',
})
export class LengthsHistogramComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;
  @ViewChild('idsDialog') totalsIdsDialogTemplateRef: TemplateRef<unknown>;

  @Input() dashboard: UiDashboard;
  @Input() catchData: DashboardCatchLengthResponse[];
  @Input() catchDataRange: DashboardCatchLengthDataRangeResponse | null = null;
  @Input() lengthUnits: 'CM' | 'IN' = 'CM';
  @Input() waterbodyName: string | null = null;

  @Output() lengthUnitsChange = new EventEmitter<'CM' | 'IN'>();

  activeChartIndex = 0;
  chartNames: string[];

  datasetIndexToSpeciesId = new Map<number, string>();

  // dataset index -> bucket index -> array of catches
  catchesByIndex: Map<number, CatchItem[]>[] = [];
  nextPrevBucketIdxByBucketIdx = new Map<number, { next: number; prev: number; }>();

  outlierCatches: CatchItem[][] = [];

  chartDataRanges: ChartDataRange[] = [];
  chartData: ChartData<'bar', number[], string> | null = null;
  chartOptions: ChartOptions<'bar'> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          color: CHART_FONT_COLOR,
          font: CHART_SCALES_FONT,
        },
        ticks: {
          align: 'start',
          color: CHART_FONT_COLOR,
          font: CHART_FONT,
          callback: (_, index) => {
            const label = this.chartData.labels[index];
            const count = this.chartData.datasets[this.activeChartIndex].data[index];
            const tick = count ? label : '';

            return tick;
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Fish',
          color: CHART_FONT_COLOR,
          font: { ...CHART_FONT, lineHeight: 1.4 },
        },
        ticks: {
          color: CHART_FONT_COLOR,
          font: CHART_FONT,
          callback: (value) => {
            let tick = '';
            if (Number.isInteger(value)) {
              tick = value.toString();
            }
            return tick;
          }
        }
      },
    },
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (items) => items[0].label,
          label: (item) => {
            let label = item.formattedValue;
            label += ' catch';
            if (item.raw != 1) {
              label += 'es';
            }
            return label;
          }
        },
      },
    },
  };

  private catchesDataSubject = new ReplaySubject<{ data: CatchesData; index: number; }>(1);
  private catchesDialogRef: DialogRef | null = null;
  private catchesDialogDatasetIndex = 0;
  private catchesDialogIndex = 0;

  idsTableData: IdTableRow[] = [];
  idsTableColumns: TableColumn<IdTableRow>[] = [
    { title: 'Id', property: 'id', sort: 'string' },
    { title: '', property: 'url', customContent: true },
  ];
  idsDialogTitle: string = '';
  private idsDialogRef: DialogRef | null = null;

  copiedIds = signal(false);
  copiedIdsTimeout: any;

  userIsAdmin = false;
  private userSubscription = Subscription.EMPTY;

  constructor(private dialog: Dialog, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.userSubscription = this.authService.loggedInUser$.subscribe({
      next: (user) => {
        this.userIsAdmin = user?.account_type == 'ADMIN';
      },
      error: (error) => {
        console.error(error);
        this.userIsAdmin = false;
      },
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.activeChartIndex = 0;
    this.buildHistogram();
    this.baseChart?.render();
  }

  onSelectChart(dropdown: DropdownComponent | null, index: number) {
    dropdown?.close();
    if (this.activeChartIndex == index) return;

    const prev = this.activeChartIndex;
    this.activeChartIndex = index;
    if (this.chartData) {
      this.baseChart.hideDataset(prev, true);
      this.baseChart.hideDataset(index, false);
    }
  }

  onChartClick(e: { event?: ChartEvent; active?: {}[]; }) {
    const first = e.active?.[0];
    if (first) {
      const datasetIndex = first["datasetIndex"];
      const index = first["index"];
      this.openCatchesDialog(datasetIndex, index, null);
    }
  }

  onClickUnits(units: 'CM' | 'IN') {
    if (this.lengthUnits == units) return;
    this.lengthUnitsChange.emit(units);
  }

  onClickViewInvalidCatches() {
    const datasetIndex = this.activeChartIndex;

    const dashboard = this.dashboard;
    if (!dashboard) { return };

    const { species_name } = this.catchData[datasetIndex];
    const label = 'Invalid Catches';

    const title = `${species_name}, ${label}`;

    const parts: string[] = [
      dashboard.name.toLocaleLowerCase(),
      this.waterbodyName?.toLocaleLowerCase(),
      species_name,
      label,
    ];
    const filename = parts
      .filter((it) => !!it)
      .map((it) => it.replaceAll('-', ' '))
      .join('_')
      .replaceAll(/\s+/g, '-') + '.csv';

    const data: CatchesData = {
      title,
      filename,
      data: this.outlierCatches[datasetIndex],
    };
    let catchesDataIndex: number | null = null;
    this.catchesDataSubject.next({ data, index: catchesDataIndex });

    if (!this.catchesDialogRef) {
      const units = this.lengthUnits.toLowerCase();
      const dataProvider = this.catchesDataSubject.asObservable();
      const dialogData: CatchesDialogData = {
        dataProvider,
        units,
        species: species_name,
        dashboardTimezone: dashboard.timezone,
        showNextPrev: false,
        onClickNext: () => { },
        onClickPrev: () => { },
      };
      this.catchesDialogRef = TournamentRegionWaterbodyDashboardDialogComponent.open(this.dialog, dialogData);
      this.catchesDialogRef.closed.subscribe(() => { this.catchesDialogRef = null; });
    }
  }

  openCatchesDialog(datasetIndex: number, index: number, something: 'start' | 'end' | null) {
    const dashboard = this.dashboard;
    if (!dashboard) { return };

    this.catchesDialogDatasetIndex = datasetIndex;
    this.catchesDialogIndex = index;

    const { species_name } = this.catchData[datasetIndex];
    const label = this.chartData.labels[index];

    const title = `${species_name}, ${label}`;

    const parts: string[] = [
      dashboard.name.toLocaleLowerCase(),
      this.waterbodyName?.toLocaleLowerCase(),
      species_name,
      label,
    ];
    const filename = parts
      .filter((it) => !!it)
      .map((it) => it.replaceAll('-', ' '))
      .join('_')
      .replaceAll(/\s+/g, '-') + '.csv';

    const data: CatchesData = {
      title,
      filename,
      data: this.catchesByIndex[datasetIndex].get(index),
    };
    let catchesDataIndex: number | null = null;
    if (something) {
      if (data.data.length == 0 || something == 'start') {
        catchesDataIndex = 0;
      } else {
        catchesDataIndex = (data.data.length - 1);
      }
    }
    this.catchesDataSubject.next({ data, index: catchesDataIndex });

    if (!this.catchesDialogRef) {
      const showNextPrev = this.nextPrevBucketIdxByBucketIdx.size > 1;
      const units = this.lengthUnits.toLowerCase();
      const dataProvider = this.catchesDataSubject.asObservable();
      const dialogData: CatchesDialogData = {
        dataProvider,
        units,
        species: species_name,
        dashboardTimezone: dashboard.timezone,
        showNextPrev,
        onClickNext: this.onClickNextCatchesDataset.bind(this),
        onClickPrev: this.onClickPrevCatchesDataset.bind(this),
      };
      this.catchesDialogRef = TournamentRegionWaterbodyDashboardDialogComponent.open(this.dialog, dialogData);
      this.catchesDialogRef.closed.subscribe(() => { this.catchesDialogRef = null; });
    }
  }

  private onClickNextCatchesDataset() {
    const { next } = this.nextPrevBucketIdxByBucketIdx.get(this.catchesDialogIndex);
    this.openCatchesDialog(this.catchesDialogDatasetIndex, next, 'start');
  }

  private onClickPrevCatchesDataset() {
    const { prev } = this.nextPrevBucketIdxByBucketIdx.get(this.catchesDialogIndex);
    this.openCatchesDialog(this.catchesDialogDatasetIndex, prev, 'end');
  }

  buildHistogram() {
    const dashboard = this.dashboard;
    const data = this.catchData;
    const dataRange = this.catchDataRange;
    if (!dashboard || !data) { return; }

    const color = COLORS['blue'];
    const hoverColor = COLORS['purple'];
    const datasets: ChartDataset<'bar', number[]>[] = [];
    const catchesByIndex: Map<number, CatchItem[]>[] = [];
    const speciesDataRanges: ChartDataRange[] = [];
    const outlierCatches: CatchItem[][] = [];

    const chartNames: string[] = [];

    const unit = this.lengthUnits;

    const lengthDivisor = (unit === 'CM') ? 1 : 2.54;

    // TODO: date filter

    //- cg: calculate min/max/avg for each species
    let lengthMax = Number.MIN_VALUE;
    let firstDay = dashboard.dateFrom?.startOf('day');
    let lastDay = dashboard.dateTo?.endOf('day');
    for (const { date_from, date_to, catches } of data) {
      const from = toDateTime(date_from, dashboard.timezone).startOf('day');
      const to = toDateTime(date_to, dashboard.timezone).endOf('day');
      firstDay = firstDay ? (from < firstDay ? from : firstDay) : from;
      lastDay = lastDay ? (to > lastDay ? to : lastDay) : to;

      const speciesDataRange: ChartDataRange = { min: Number.MAX_VALUE, max: Number.MIN_VALUE, avg: 0 };
      for (const it of catches) {
        if (it.outlier) { continue; }

        const length = it.length / lengthDivisor;
        speciesDataRange.min = Math.min(speciesDataRange.min, length);
        speciesDataRange.max = Math.max(speciesDataRange.max, length);
        speciesDataRange.avg += length;
      }
      speciesDataRange.avg /= catches.length;

      lengthMax = Math.max(lengthMax, speciesDataRange.max);

      speciesDataRange.min = Number(speciesDataRange.min.toFixed(1));
      speciesDataRange.max = Number(speciesDataRange.max.toFixed(1));
      speciesDataRange.avg = Number(speciesDataRange.avg.toFixed(1));
      speciesDataRanges.push(speciesDataRange);
    }
    //- cg: override length max if we're given a data range
    if (dataRange?.max) {
      lengthMax = dataRange.max / lengthDivisor;
    }
    lengthMax = Number(lengthMax.toFixed(1));

    //- cg: size buckets to whole inches
    let bucketCount = dataRange?.bucket_count;
    let bucketWidth = dataRange?.bucket_width ?? 1;
    if (!bucketCount) {
      const bucketCountExtra = (lengthMax % bucketWidth !== 0) ? 1 : 0;
      const bucketCountAdjusted = Math.ceil(lengthMax / bucketWidth) + bucketCountExtra;
      const bucketCountAdjustedExtra = (((bucketCountAdjusted - 1) * bucketWidth) >= lengthMax) ? -1 : 0;
      bucketCount = bucketCountAdjusted + bucketCountAdjustedExtra + 1; // +1 to include an extra bucket on the right for padding
    }

    //- cg: build histogram buckets
    let maxCount = 0;
    for (let i = 0; i < data.length; i += 1) {
      const { species_id, species_name, catches } = data[i];

      const catchesByBucket = new Map<number, CatchItem[]>();
      catchesByIndex.push(catchesByBucket);

      const trips = new Map<number | string, void>();

      const buckets = new Array<number>(bucketCount);
      for (let i = 0; i < buckets.length; i += 1) { buckets[i] = 0; }

      const outliers: CatchItem[] = [];

      for (const it of catches) {
        trips.set(it.creel_survey_id);

        const length = it.length / lengthDivisor;

        const item: CatchItem = {
          creel_survey_id: it.creel_survey_id,
          catch_log_id: it.catch_log_id,
          length: Number(length.toFixed(3)),
          caught_at: formatDateTime(toDateTime(it.caught_at, dashboard.timezone), DateTime.DATETIME_MED),
          measurement_photo_url: it.measurement_photo_url,
        };

        if (!it.outlier) {
          const index = Math.floor(length);
          const count = buckets[index] + 1;
          buckets[index] = count;

          maxCount = Math.max(maxCount, count);

          const items = catchesByBucket.get(index) ?? [];
          items.push(item);
          catchesByBucket.set(index, items);
        } else {
          outliers.push(item);
        }
      }

      const totalCaught = catches.length;
      const totalOutliers = outliers.length;
      const totalTrips = trips.size;

      let label = `${species_name} (${totalCaught} catch`
      if (totalCaught != 1) { label += 'es'; }
      label += `, ${totalTrips} trip`;
      if (totalTrips != 1) { label += 's'; }
      label += ')';
      if (totalOutliers != 0) { label += '*'; }

      this.datasetIndexToSpeciesId.set(i, species_id);

      datasets.push({
        label,
        data: buckets,
        hidden: i != this.activeChartIndex,
        backgroundColor: color,
        borderWidth: 0,
        hoverBackgroundColor: hoverColor,
        hoverBorderWidth: 0,
        barPercentage: .95,
        categoryPercentage: .95,
      });

      chartNames.push(label);
      outlierCatches.push(outliers);
    }

    maxCount = dataRange?.max_y ?? maxCount;

    this.chartOptions.scales['y']['suggestedMax'] = maxCount + 1;

    const unitsLower = unit.toLowerCase();
    const text = `Length (${unitsLower})`;
    this.chartOptions.scales['x']['title']['text'] = text;

    const labelFromValue = (value: number) => formatNumber(value, 'en-US', '1.0-1');

    const labels = new Array<string>(bucketCount);
    for (let i = 0; i < labels.length; i += 1) {
      const min = bucketWidth * i;
      const max = bucketWidth * (i + 1);
      const a = labelFromValue(min);
      const b = labelFromValue(max);
      labels[i] = `${a} - ${b} ${unitsLower}`;
    }

    //- cg: build a map of bucket index to next/prev bucket index since there can be gaps
    const bucketIndices: number[] = [];
    if (catchesByIndex.length) {
      for (const idx of catchesByIndex[0].keys()) { bucketIndices.push(idx); }
    }
    bucketIndices.sort((a, b) => (a - b));
    this.nextPrevBucketIdxByBucketIdx.clear();
    for (let idx = 0; idx < bucketIndices.length; idx += 1) {
      const bucketIdx = bucketIndices[idx];
      const next = bucketIndices[(idx + 1) % bucketIndices.length];
      const prev = bucketIndices[(idx - 1 + bucketIndices.length) % bucketIndices.length];
      this.nextPrevBucketIdxByBucketIdx.set(bucketIdx, { next, prev });
    }

    this.chartDataRanges = speciesDataRanges;
    this.chartData = { labels, datasets };
    this.catchesByIndex = catchesByIndex;
    this.outlierCatches = outlierCatches;

    this.chartNames = chartNames;
  }

  onClickOpenIdsDialog(kind: 'creel-survey' | 'catch-log') {
    const speciesId = this.datasetIndexToSpeciesId.get(this.activeChartIndex);

    let title: string[] = [];

    const uniqueIds = new Map<number | string, void>();

    const data: IdTableRow[] = [];
    for (const { species_id, species_name, catches } of this.catchData) {
      if (species_id != speciesId) { continue; }

      title.push(species_name);

      for (const { catch_log_id, creel_survey_id } of catches) {
        const id = kind == 'creel-survey' ? creel_survey_id : catch_log_id;
        if (!id) { continue; }

        uniqueIds.set(id, null);

        const url = `https://admin.anglersatlas.com/admin/${kind}/view?id=${id}`;
        data.push({ id, url });
      }
      break;
    }

    title.push(kind == 'creel-survey' ? 'Trips' : 'Catches');
    title.push(`(${uniqueIds.size})`);

    this.idsDialogTitle = title.join(' ');
    this.idsTableData = data;
    this.idsDialogRef = this.dialog.open(this.totalsIdsDialogTemplateRef);
  }

  onClickCloseIdsDialog() {
    this.idsDialogRef?.close();
  }

  async onClickCopyIds() {
    const text = this.idsTableData.map((it) => it.id).join("\n");
    if (!text) return;

    try {
      await navigator.clipboard.writeText(text)
      this.copiedIds.set(true);
      clearTimeout(this.copiedIdsTimeout);
      this.copiedIdsTimeout = setTimeout(() => this.copiedIds.set(false), 1000);
    } catch (error) {
      console.error(error);
    }
  }

}

type CatchesData = {
  title: string;
  filename: string;
  data: CatchItem[];
};

type CatchesDialogData = {
  dataProvider: Observable<{ data: CatchesData; index: number | null; }>;
  units: string;
  species: string;
  dashboardTimezone: string | null;
  showNextPrev: boolean;
  onClickNext: () => void,
  onClickPrev: () => void,
};

@Component({
  selector: 'dash-tournament-region-waterbody-dashboard-dialog',
  template: `
<div class="flex flex-col space-y-2 bg-white border border-gray-100 rounded p-2 shadow-lg">
    <div class="flex items-center justify-between">
        <h3 class="grow truncate">{{ title }}</h3>
        <button class="p-2 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-100"
                title="Close"
                (click)="onClickClose()">
            <svg-icon name="close" class="size-6" />
        </button>
    </div>
    <dash-table #table [data]="data" [columns]="tableColumns">
      <ng-template let-element dashTableCustomContent>
        @if (element.measurement_photo_url) {
          <div class="flex justify-end">
            <img class="h-10 aspect-square object-cover bg-purple-faint border border-gray-100 rounded-sm overflow-clip hover:cursor-pointer"
                 [src]="element.measurement_photo_url + ':100'"
                 title="Measurement Photo"
                 (click)="onClickMedia(element._index)">
          </div>
        }
      </ng-template>
    </dash-table>
    <button class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
            type="button"
            (click)="onClickExportData(table)">
        Export Data
    </button>
</div>
`
})
export class TournamentRegionWaterbodyDashboardDialogComponent implements OnInit, OnDestroy {

  static open(dialog: Dialog, data: CatchesDialogData): DialogRef<TournamentRegionWaterbodyDashboardDialogComponent> {
    const config = { width: "80%", data };
    const ref = dialog.open<TournamentRegionWaterbodyDashboardDialogComponent>(TournamentRegionWaterbodyDashboardDialogComponent, config);
    return ref;
  }

  title: string;
  filename: string;
  data: CatchItem[];

  tableColumns: TableColumn<CatchItem>[];

  private mediaDataSubject = new ReplaySubject<MediaData>(1);
  private mediaDialogRef: DialogRef | null = null;
  private mediaIndex: number = 0;

  private subscription = Subscription.EMPTY;

  constructor(
    @Inject(DIALOG_DATA) private dialogData: CatchesDialogData,
    private dialogRef: DialogRef<TournamentRegionWaterbodyDashboardDialogComponent>,
    private dialog: Dialog,
  ) {
    this.tableColumns = catchTableColumnsForUnit(dialogData.units);
  }

  ngOnInit() {
    this.subscription = this.dialogData.dataProvider.subscribe(({ data, index }) => {
      this.data = data.data;
      this.title = data.title;
      this.filename = data.filename;
      if (index !== null) { this.onClickMedia(index); }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickClose() {
    this.dialogRef.close();
  }

  onClickExportData(table: TableComponent<any>) {
    table.downloadCsv(this.filename);
  }

  onClickMedia(index: number) {
    this.mediaIndex = index;

    const item = this.data[index];

    const media: MediaData["media"] = [
      { kind: 'PHOTO', src: item.measurement_photo_url },
    ];
    const notes: string = `${item.length}${this.dialogData.units} ${this.dialogData.species}  |  ${item.caught_at}`;
    this.mediaDataSubject.next({ media, index: 0, notes });

    if (!this.mediaDialogRef) {
      const mediaProvider = this.mediaDataSubject.asObservable();
      const data: MediaDialogData = {
        mediaProvider,
        showNextPrev: this.dialogData.showNextPrev || this.data.length > 1,
        onClickNext: this.onClickNext.bind(this),
        onClickPrev: this.onClickPrev.bind(this),
      };
      const config: DialogConfig<MediaDialogData> = {
        backdropClass: "bg-gray-800/90",
        data,
      };
      this.mediaDialogRef = this.dialog.open(MediaDialogComponent, config);
      this.mediaDialogRef.closed.subscribe(() => { this.mediaDialogRef = null; });
    }
  }

  private onClickNext() {
    const next = this.mediaIndex + 1;
    if (next < this.data.length) {
      this.onClickMedia(next);
    } else if (!this.dialogData.showNextPrev) {
      this.onClickMedia(0);
    } else {
      this.dialogData.onClickNext();
    }
  }

  private onClickPrev() {
    const prev = this.mediaIndex - 1;
    if (prev >= 0) {
      this.onClickMedia(prev);
    } else if (!this.dialogData.showNextPrev) {
      this.onClickMedia(this.data.length - 1);
    } else {
      this.dialogData.onClickPrev();
    }
  }
}

export function catchTableColumnsForUnit(units: string): TableColumn<CatchItem>[] {
  const columns: TableColumn<CatchItem>[] = [
    { title: 'Trip', property: 'creel_survey_id', sort: 'number', copyable: true },
    { title: "Catch", property: "catch_log_id", sort: "number", copyable: true },
    { title: "Caught At", property: "caught_at", sort: "string" },
    { title: `Length (${units})`, property: 'length', sort: 'number', numberFormat: '1.2-2' },
    { title: 'Measurement Photo', property: 'measurement_photo_url', customContent: true },
  ];
  return columns;
}

