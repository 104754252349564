import { AuthenticationService } from '@aa/authentication/authentication.service';
import { EmptyMapComponent, MapLayer, zIndexFromMapLayer } from '@aa/map/empty-map/empty-map.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Feature, Map as OlMap } from 'ol';
import { unByKey } from 'ol/Observable';
import { easeOut } from 'ol/easing';
import { EventsKey } from 'ol/events';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { fromLonLat } from 'ol/proj';
import RenderEvent from 'ol/render/Event';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { WebGLStyle } from 'ol/style/webgl';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { COLORS } from '../../app.module';
import { AppService, DashboardWaterbodies, UiDashboard } from '../../app.service';
import { DashboardCatchLengthDataRangeResponse, DashboardCatchLengthResponse, DashboardService, DashboardWaterbodiesResponse, DashboardWaterbodiesResponseTotals, DashboardWaterbodiesTotalsResponse, Params } from '../../dashboard.service';
import { Dashboard } from '@aa/models/dashboard-access';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { LengthsHistogramComponent } from '../lengths-histogram/lengths-histogram.component';
import { TableClickElementEvent, TableColumn, TableComponent, TableRowsClickableFunc } from '../table/table.component';
import { METRICS, METRIC_TYPES, Metric, MetricType } from '../types';

// TODO: "Recenter" map button

export function lerp(from: number, to: number, t: number): number {
    return ((1 - t) * from) + (to * t);
}

const METRIC_TYPE_TO_ICON: { [key in MetricType]: string } = {
    'WATERBODIES': 'waves',
    'ANGLERS': 'person',
    'TRIPS': 'boat',
    'ZEROS': 'zero',
    'FISH': 'fish',
    'HOURS_SPENT': 'clock',
    'CATCH_RATE': 'hook',
};

const METRIC_TYPE_TO_WATERBODY_STAT_PROPERTY: { [key in MetricType]: keyof WaterbodyStat } = {
    'WATERBODIES': null,
    'ANGLERS': 'anglers',
    'TRIPS': 'trips',
    'ZEROS': 'zeros',
    'FISH': 'fish',
    'HOURS_SPENT': 'hoursSpent',
    'CATCH_RATE': 'catchRate',
};

type WaterbodyStat = {
    id: number;
    name: string;
    lat: number;
    lon: number;
    anglers: number;
    trips: number;
    zeros: number;
    fish: number;
    hoursSpent: number;
    catchRate: number;
    ids?: { [key: string]: number; };
};

export const FEATURE_TYPES = [
    'WATERBODY',
    'LOGICAL_BOUNDARY',
] as const;
export type FeatureType = typeof FEATURE_TYPES[number];

const FEATURE_PROPERTY_WATERBODY_ID = 'waterbodyId';
const FEATURE_PROPERTY_NAME = 'name';
const FEATURE_PROPERTY_TYPE = 'type';
const FEATURE_PROPERTY_METRIC_TYPE = 'metricType';
const FEATURE_PROPERTY_RADIUS = 'metricRadius';

type IdTableRow = { id: string; catches: number; url: string; };

@Component({
    selector: 'dash-tournament-region-dashboard',
    templateUrl: './tournament-region-dashboard.component.html',
})
export class TournamentRegionDashboardComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @ViewChild('mapStatsContainer') mapStatsContainerElementRef: ElementRef<HTMLDivElement>;
    @ViewChild(EmptyMapComponent) mapComponent: EmptyMapComponent;
    @ViewChild(LengthsHistogramComponent) lengthsHistogramComponent: LengthsHistogramComponent;
    @ViewChild('totalsIdsDialog') totalsIdsDialogTemplateRef: TemplateRef<unknown>;
    @ViewChild('totalsInfoDialog') totalsInfoDialogTemplateRef: TemplateRef<unknown>;

    activeRegionIndex = -1;
    regionNames: string[] = [];
    private regionLinks: any[] = [];

    delays: string[] = [
        'delay-0',
        'delay-[25ms]',
        'delay-[50ms]',
        'delay-[75ms]',
        'delay-[100ms]',
        'delay-[125ms]',
        'delay-[150ms]',
    ];

    activeMapTypeIndex = 0;
    mapTypes: string[];

    loadingWaterbodies = true;
    loadingBoundaries = true;
    loadingTotals = true;
    loadingExtraTotals = true;

    dashboard = this.appService.dashboard;
    navigating = this.appService.navigating(this.route);

    dashboardId: number | null = null;
    regionId: number | null = null;
    lengthUnits: 'CM' | 'IN' = 'CM';
    catchData: DashboardCatchLengthResponse[] = [];
    catchDataRange: DashboardCatchLengthDataRangeResponse = null;

    subtitle: string;

    metrics: Metric[] = [
        METRICS['WATERBODIES'],
        METRICS['ANGLERS'],
        METRICS['TRIPS'],
        METRICS['ZEROS'],
        METRICS['FISH'],
        METRICS['HOURS_SPENT'],
        METRICS['CATCH_RATE'],
    ];
    metricCounts: { [key in MetricType]: number } = {
        WATERBODIES: 0,
        ANGLERS: 0,
        TRIPS: 0,
        ZEROS: 0,
        FISH: 0,
        HOURS_SPENT: 0,
        CATCH_RATE: 0
    };
    activeMetricIndex = 0;
    activeMetric: Metric | null = null;

    tableData: WaterbodyStat[] = null;
    tableColumns: TableColumn<WaterbodyStat>[] = [
        { title: 'Waterbody', property: 'name', sort: 'string', clickable: true },
        { title: '# of Anglers', property: 'anglers', sort: 'number' },
        { title: '# of Trips', property: 'trips', sort: 'number' },
        { title: '# of Zero Trips', property: 'zeros', sort: 'number' },
        { title: '# of Fish Caught', property: 'fish', sort: 'number' },
        { title: 'Hours Fished', property: 'hoursSpent', sort: 'number', numberFormat: '1.0-1' },
        { title: 'Catch Rate', property: 'catchRate', sort: 'number', numberFormat: '1.0-1' },
    ];

    totalsTableData: WaterbodyStat[] = [];
    totalsTableColumns: TableColumn<WaterbodyStat>[] = [
        { title: '', property: 'name' },
        { title: '# of Anglers', property: 'anglers' },
        { title: '# of Trips', property: 'trips' },
        { title: '# of Zero Trips', property: 'zeros' },
        { title: '# of Fish Caught', property: 'fish' },
        { title: 'Hours Fished', property: 'hoursSpent', numberFormat: '1.0-1' },
        { title: 'Catch Rate', property: 'catchRate', numberFormat: '1.0-1' },
    ];
    totalsTableRowsClickable: TableRowsClickableFunc<WaterbodyStat> = (it) => {
        const length = it.ids ? Object.keys(it.ids).length : 0;
        const result = length > 0;
        return result;
    };

    idsTableData: IdTableRow[] = [];
    idsTableColumns: TableColumn<IdTableRow>[] = [
        { title: 'Trip Id', property: 'id', sort: 'string' },
        { title: 'Catches', property: 'catches', sort: 'number' },
        { title: '', property: 'url', customContent: true },
    ];

    mapHeightPx: number = 0;

    exportCatchesCsvLink: string | null = null;

    idsDialogTitle: string = '';
    private idsDialogRef: DialogRef | null = null;

    private dashboardName: string | null = null;
    regionName: string | null = null;

    private mapReady = false;
    private map: OlMap | null = null;

    private hoveredWaterbodyId: number | null = null;

    private waterbodyLayer: VectorLayer<Feature>;
    private waterbodyHoverLayer: VectorLayer<Feature>;
    private metricLayer: WebGLPointsLayer<VectorSource>;
    private boundaryLayer: VectorLayer<Feature>;

    private metricSource = new VectorSource();

    private waterbodyMetricMinMax: { [key in MetricType]: { min: number; max: number; } } = {
        'WATERBODIES': { min: Number.MAX_VALUE, max: 0 },
        'ANGLERS': { min: Number.MAX_VALUE, max: 0 },
        'TRIPS': { min: Number.MAX_VALUE, max: 0 },
        'ZEROS': { min: Number.MAX_VALUE, max: 0 },
        'FISH': { min: Number.MAX_VALUE, max: 0 },
        'HOURS_SPENT': { min: Number.MAX_VALUE, max: 0 },
        'CATCH_RATE': { min: Number.MAX_VALUE, max: 0 },
    };
    private waterbodyIdToTableDataIndex: { [key: number]: number } = {};
    private waterbodyIdToAnimation: { [key: number]: EventsKey } = {};

    private metricToStyle: { [key in MetricType]?: Style } = {};
    private metricLayerStyle: WebGLStyle;

    private waterbodiesSubject = new Subject<[number, number | null]>();
    private boundarySubject = new Subject<[number, number | null]>();
    private catchLengthsSubject = new Subject<[number, number | null]>();
    private totalsSubject = new Subject<[number, number | null]>();
    private extraTotalsSubject = new Subject<[number, number | null]>();

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appService: AppService,
        private authService: AuthenticationService,
        private dashboardService: DashboardService,
        private dialog: Dialog,
    ) {
    }

    ngOnInit() {
        // preload marker icon images so there's not a flash of nothing, when changing selected metric and the new icon loads in
        const iconSrcs: { type: MetricType | null; src: string; }[] = METRIC_TYPES.map((type) => {
            const icon = METRIC_TYPE_TO_ICON[type];
            const src = `/assets/markers/${icon}.png`;
            return { type, src };
        });
        iconSrcs.push({ type: null, src: '/assets/markers/layers.png' });
        for (const { type, src } of iconSrcs) {
            const img: HTMLImageElement = new Image();
            img.onload = () => {
                window.createImageBitmap(img, { resizeQuality: 'high' })
                    .then((bitmap) => {
                        const style = new Style({
                            image: new Icon({
                                img: bitmap,
                                size: [bitmap.width, bitmap.height],
                                // NOTE(cg): scale 50% since we're loading 2x sized icons
                                scale: 0.5,
                                anchor: [0.5, 0.423],
                            }),
                            zIndex: zIndexFromMapLayer(MapLayer.Metrics),
                        });
                        this.metricToStyle[type] = style;
                    });
            };
            img.src = src;
        }

        //- cg: build metric layer styles
        const circleFillColor: WebGLStyle['circle-fill-color'] = ['match', ['get', FEATURE_PROPERTY_METRIC_TYPE]];
        const circleStrokeColor: WebGLStyle['circle-stroke-color'] = ['match', ['get', FEATURE_PROPERTY_METRIC_TYPE]];
        for (const type of METRIC_TYPES) {
            const metric = METRICS[type];
            const color = COLORS[metric.colour];
            circleFillColor.push(type, `${color}22`);
            circleStrokeColor.push(type, `${color}55`);
        }
        circleFillColor.push('#000'); // fallback
        circleStrokeColor.push('#000'); // fallback
        this.metricLayerStyle = {
            'circle-radius': ['get', FEATURE_PROPERTY_RADIUS],
            'circle-stroke-width': 2,
            'circle-fill-color': circleFillColor,
            'circle-stroke-color': circleStrokeColor,
        };

        this.mapTypes = this.appService.mapTypes;
        const activeMapTypeIndex = this.mapTypes.indexOf(this.appService.activeMapType);
        if (activeMapTypeIndex != -1) { this.activeMapTypeIndex = activeMapTypeIndex; }

        this.boundaryLayer = new VectorLayer({
            source: new VectorSource(),
            style: new Style({
                stroke: new Stroke({
                    color: COLORS['green-super-light'],
                    width: 1.5,
                    lineDash: [10, 5],
                }),
                fill: new Fill({
                    color: `${COLORS['green-ultra-light']}20`,
                }),
                zIndex: zIndexFromMapLayer(MapLayer.Boundary),
            }),
            // TODO: visibility toggle
            // visible: false,
        })
        this.waterbodyLayer = new VectorLayer({
            source: new VectorSource(),
            style: (feature) => {
                const type = feature.get(FEATURE_PROPERTY_TYPE) as FeatureType;
                const metric = type == 'WATERBODY' ? (this.activeMetric?.type ?? 'WATERBODIES') : null;
                const style = this.metricToStyle[metric];
                return style;
            },
        });
        this.waterbodyHoverLayer = new VectorLayer({
            source: new VectorSource(),
            style: (feature) => this.buildTextStyleForWaterbody(feature as Feature),
        });

        const boundarySubscription = this.boundarySubject.asObservable()
            .pipe(
                switchMap(([dashboardId, regionId]) => this.dashboardService.dashboard(dashboardId).pipe(map((it) => ([it, regionId])))),
            )
            .subscribe({
                next: ([dashboard, regionId]: [Dashboard, number | null]) => {
                    this.loadingBoundaries = false;
                    this.bindBoundary(dashboard, regionId);
                    this.maybeZoomMapToDashboardBoundary();
                },
                error: (error) => {
                    this.loadingBoundaries = false;
                    console.error(error);
                },
            });
        this.subscriptions.push(boundarySubscription);

        const catchLengthsSubscription = this.catchLengthsSubject.asObservable()
            .pipe(
                switchMap(([dashboardId, regionId]) => {
                    const params: Params = {};
                    if (regionId !== null) { params['logical_boundary_id'] = regionId; }
                    const catchData = this.dashboardService.dashboardCatchLengths(dashboardId, params);
                    const catchDataRange = regionId ? this.dashboardService.dashboardCatchLengthsDataRange(dashboardId, { unit: 'in' }) : of(null);
                    return combineLatest([catchData, catchDataRange]);
                })
            )
            .subscribe({
                next: ([catchData, catchDataRange]: [DashboardCatchLengthResponse[], DashboardCatchLengthDataRangeResponse|null]) => {
                    this.catchData = catchData;
                    this.catchDataRange = catchDataRange;
                },
                error: (error) => {
                    this.catchData = [];
                    console.error(error);
                },
            });
        this.subscriptions.push(catchLengthsSubscription);

        const waterbodiesSubscription = this.waterbodiesSubject.asObservable()
            .pipe(
                switchMap(([dashboardId, regionId]) => {
                    const waterbodies: Observable<DashboardWaterbodies> = (regionId !== null)
                        ? this.dashboardService.dashboardWaterbodies(dashboardId, { logical_boundary_id: regionId })
                            .pipe(
                                map((response) => ({ id: dashboardId, loading: false, waterbodies: response })),
                                startWith({ id: dashboardId, loading: true, waterbodies: [] })
                            )
                        : this.appService.getWaterbodies(dashboardId);
                    return waterbodies;
                }),
                filter(({ loading }) => !loading)
            )
            .subscribe({
                next: ({ waterbodies }) => {
                    this.loadingWaterbodies = false;
                    this.buildWaterbodyData(waterbodies);
                    this.maybeZoomMapToDashboardBoundary();
                },
                error: (error) => {
                    this.loadingWaterbodies = false;
                    console.error(error);
                },
            });
        this.subscriptions.push(waterbodiesSubscription);

        const totalsSubscription = this.totalsSubject.asObservable()
            .pipe(
                switchMap(([dashboardId, regionId]) => {
                    const params: Params = {};
                    if (regionId !== null) { params['logical_boundary_id'] = regionId; }
                    const response = this.dashboardService.dashboardWaterbodiesTotals(dashboardId, params);
                    return response;
                }),
            )
            .subscribe({
                next: (response) => {
                    this.bindTotals(response);
                    this.loadingTotals = false;
                },
                error: (error) => {
                    this.loadingTotals = false;
                    console.error(error);
                },
            });
        this.subscriptions.push(totalsSubscription);

        const extraTotalsSubscription = this.extraTotalsSubject.asObservable()
            .pipe(
                switchMap(([dashboardId, regionId]) => {
                    const params: Params = { extra: '1' };
                    if (regionId !== null) { params['logical_boundary_id'] = regionId; }
                    const response = this.dashboardService.dashboardWaterbodiesTotals(dashboardId, params);
                    return response;
                }),
            )
            .subscribe({
                next: (response) => {
                    this.bindTotalsTableData(response);
                    this.loadingExtraTotals = false;
                },
                error: (error) => {
                    console.error(error);
                    this.loadingExtraTotals = false;
                },
            });
        this.subscriptions.push(extraTotalsSubscription);

        const subscription = combineLatest([
            this.route.parent.paramMap.pipe(map((it) => Number.parseInt(it.get('dashboardId')))),
            this.route.paramMap.pipe(map((it) => (it.has('regionId') ? Number.parseInt(it.get('regionId')) : null))),
            this.appService.dashboard.pipe(filter((it) => !!it)),
            this.authService.tryAccessToken(),
        ]).subscribe(([dashboardId, regionId, dashboard, accessToken]: [number, number | null, UiDashboard, string | null]) => {
            this.dashboardId = dashboardId;
            this.regionId = regionId;
            this.dashboardName = dashboard.name;
            this.regionName = null;
            this.lengthUnits = dashboard.defaultUnits;

            this.exportCatchesCsvLink = null;
            if (accessToken) {
                const params: string[] = [`access_token=${accessToken}`];
                if (regionId) { params.push(`logical_boundary_id=${regionId}`); }
                this.exportCatchesCsvLink = `${environment.apiUrl}/dashboards/${dashboard.id}/catches-csv?${params.join('&')}`;
            }

            //- cg: reset some state
            this.activeRegionIndex = -1;
            this.regionNames.length = 0;
            this.regionLinks.length = 0;

            this.waterbodyMetricMinMax = {
                'WATERBODIES': { min: Number.MAX_VALUE, max: 0 },
                'ANGLERS': { min: Number.MAX_VALUE, max: 0 },
                'TRIPS': { min: Number.MAX_VALUE, max: 0 },
                'ZEROS': { min: Number.MAX_VALUE, max: 0 },
                'FISH': { min: Number.MAX_VALUE, max: 0 },
                'HOURS_SPENT': { min: Number.MAX_VALUE, max: 0 },
                'CATCH_RATE': { min: Number.MAX_VALUE, max: 0 },
            };
            this.waterbodyIdToTableDataIndex = {};
            this.waterbodyIdToAnimation = {};

            this.waterbodyLayer.getSource().clear();
            this.boundaryLayer.getSource().clear();
            this.metricSource.clear();

            const ids: [number, number | null] = [dashboardId, regionId];
            this.waterbodiesSubject.next(ids);
            this.boundarySubject.next(ids);
            this.catchLengthsSubject.next(ids);
            this.totalsSubject.next(ids);
            this.extraTotalsSubject.next(ids);
        });
        this.subscriptions.push(subscription);
    }

    private bindBoundary(dashboard: Dashboard, regionId: number | null) {
        const decoder = new GeoJSON({ featureProjection: 'EPSG:3857' });

        const source = this.waterbodyLayer.getSource();

        //- cg: bind entire dashboard boundary, or specific region boundary
        let boundaryGeoJson: string = null;
        for (let idx = 0; idx < dashboard.dashboard_boundary_logical_boundaries?.length; idx += 1) {
            const region = dashboard.dashboard_boundary_logical_boundaries[idx];

            if (region.id == regionId) {
                this.regionName = region.name;
                boundaryGeoJson = region.boundary;
                this.activeRegionIndex = idx;
            }

            //- cg: show region markers if we're not specifically viewing a region
            if (regionId == null) {
                const feature = new Feature(decoder.readGeometry(region.centroid));
                feature.setId(region.id);
                feature.set(FEATURE_PROPERTY_TYPE, 'LOGICAL_BOUNDARY');
                feature.set(FEATURE_PROPERTY_NAME, region.name);
                source.addFeature(feature);
            } else {
                const link = ['/dashboards', dashboard.id, 'region', region.id];
                this.regionNames.push(region.name);
                this.regionLinks.push(link);
            }
        }
        if (!boundaryGeoJson) {
            boundaryGeoJson = dashboard.boundary;
        }
        if (boundaryGeoJson) {
            const feature = new Feature(decoder.readGeometry(boundaryGeoJson));
            this.boundaryLayer.getSource().addFeature(feature);
        }

        this.maybeZoomMapToDashboardBoundary();
    }

    private bindTotals(response: DashboardWaterbodiesTotalsResponse) {
        const totals = response.totals;

        this.metricCounts['WATERBODIES'] = totals.waterbodies;
        this.metricCounts['ANGLERS'] = totals.anglers;
        this.metricCounts['TRIPS'] = totals.trips;
        this.metricCounts['ZEROS'] = totals.zeros;
        this.metricCounts['FISH'] = totals.catches;
        this.metricCounts['HOURS_SPENT'] = totals.hours_out;
        this.metricCounts['CATCH_RATE'] = totals.rate;
        this.activeMetric = this.metrics[0];
    }

    private bindTotalsTableData(response: DashboardWaterbodiesTotalsResponse) {
        const totalsTableData: WaterbodyStat[] = [];
        const knowns = response.known;
        const unknowns = response.unknown;
        const invalid = response.invalid;
        const totals = response.totals_including_invalid;

        totalsTableData.push(
            {
                id: null,
                name: "Valid Trips",
                lat: null,
                lon: null,
                anglers: knowns.anglers,
                trips: knowns.trips,
                zeros: knowns.zeros,
                fish: knowns.catches,
                hoursSpent: knowns.hours_out,
                catchRate: knowns.rate,
                ids: knowns.ids,
            },
            {
                id: null,
                name: "Valid Trips; No Waterbody",
                lat: null,
                lon: null,
                anglers: unknowns.anglers,
                trips: unknowns.trips,
                zeros: unknowns.zeros,
                fish: unknowns.catches,
                hoursSpent: unknowns.hours_out,
                catchRate: unknowns.rate,
                ids: unknowns.ids,
            },
            {
                id: null,
                name: 'Invalid Trips',
                lat: null,
                lon: null,
                anglers: invalid.anglers,
                trips: invalid.trips,
                zeros: invalid.zeros,
                fish: invalid.catches,
                hoursSpent: invalid.hours_out,
                catchRate: invalid.rate,
                ids: invalid.ids,
            },
            {
                id: null,
                name: "Total",
                lat: null,
                lon: null,
                anglers: totals.anglers,
                trips: totals.trips,
                zeros: totals.zeros,
                fish: totals.catches,
                hoursSpent: totals.hours_out,
                catchRate: totals.rate,
                ids: totals.ids,
            },
        );

        validateTotals('known', knowns);
        validateTotals('unknown', unknowns);
        validateTotals('invalid', invalid);

        this.totalsTableData = totalsTableData;

        this.maybeZoomMapToDashboardBoundary();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((it) => it.unsubscribe());
        this.subscriptions.length = 0;
    }

    ngAfterViewInit() {
        if (this.mapComponent) { this.initMap(); }
    }

    ngAfterViewChecked(): void {
        const statsContainerHeight = this.mapStatsContainerElementRef?.nativeElement?.offsetHeight ?? 0;
        if (statsContainerHeight && statsContainerHeight != this.mapHeightPx) {
            setTimeout(() => {
                this.mapHeightPx = statsContainerHeight;
            });
        }

        if (this.mapReady && !this.mapComponent) {
            this.mapReady = false;
        } else if (!this.mapReady && this.mapComponent) {
            this.initMap();
        }
    }

    onMapReady(map: OlMap) {
        this.map = map;

        this.metricLayer = new WebGLPointsLayer({
            source: this.metricSource,
            style: this.metricLayerStyle,
            // disableHitDetection: true,
        });
        map.addLayer(this.metricLayer);

        // Show metric label when hovering over waterbody (e.g. "Clear Lake: Trips 120")
        this.map.on('pointermove', (e) => {
            if (e.dragging) { return; }

            const layer = this.waterbodyHoverLayer;
            const source = layer.getSource();

            const pixel = this.map.getEventPixel(e.originalEvent);

            this.waterbodyLayer.getFeatures(pixel)
                .then((features) => {
                    const feature = features.length ? features[0] : null;
                    const waterbodyId = feature?.getId() as number;

                    if (waterbodyId != this.hoveredWaterbodyId) {
                        this.hoveredWaterbodyId = waterbodyId;

                        source.clear();
                        if (feature) {
                            source.addFeature(feature as Feature<Geometry>);
                        }
                    }
                })
        });
    }

    onClickStat(index: number) {
        this.activeMetricIndex = index;
        this.activeMetric = this.metrics[index];

        this.waterbodyLayer.changed();

        this.animateMetricToStat(this.activeMetric);

        this.mapComponent.render();
    }

    onClickElement(event: TableClickElementEvent<WaterbodyStat>) {
        const data = this.tableData[event.index];
        this.router.navigate(['/dashboards', this.dashboardId, 'waterbody', data.id], { relativeTo: this.route });
    }

    onClickRegion(dropdown: DropdownComponent, idx: number) {
        dropdown.close();
        this.activeRegionIndex = idx;
        this.router.navigate(this.regionLinks[idx]);
    }

    onClickTotalsTableRow(rowIndex: number) {
        const row = this.totalsTableData[rowIndex];
        const ids = row.ids ?? [];
        const data: IdTableRow[] = [];
        for (const id in ids) {
            const catches = ids[id];
            const url = `https://admin.anglersatlas.com/admin/creel-survey/view?id=${id}`;
            data.push({ id, catches, url });
        }
        this.idsTableData = data;
        this.idsDialogTitle = `${row.name} (${data.length})`;
        this.idsDialogRef = this.dialog.open(this.totalsIdsDialogTemplateRef);
    }

    onClickCloseIdsDialog() {
        this.idsDialogRef?.close();
    }

    copiedIds = signal(false);
    copiedIdsTimeout: any;

    async onClickCopyIds() {
        const text = this.idsTableData.map((it) => it.id).join("\n");
        if (!text) return;

        try {
            await navigator.clipboard.writeText(text)
            this.copiedIds.set(true);
            clearTimeout(this.copiedIdsTimeout);
            this.copiedIdsTimeout = setTimeout(() => this.copiedIds.set(false), 1000);
        } catch (error) {
            console.error(error);
        }
    }

    private infoDialogRef: DialogRef | null = null;
    onClickTotalsInfoButton() {
        this.infoDialogRef = this.dialog.open(this.totalsInfoDialogTemplateRef);
    }
    onClickCloseTotalsInfoDialog() {
        this.infoDialogRef?.close();
    }

    onClickMapType(dropdown: DropdownComponent, type: string, index: number) {
        dropdown.close();
        this.activeMapTypeIndex = index;
        this.appService.activeMapType = type;
    }

    onClickExportData(table: TableComponent<any>, summary: boolean) {
        let filename = this.dashboardName.toLowerCase().replaceAll(' ', '-') + '-region';
        filename += !!summary ? '-summary' : '';
        if (summary) {
            filename += '-summary';
        }
        filename += '.csv';
        table.downloadCsv(filename);
    }

    private initMap() {
        this.mapComponent.addLayer({
            key: MapLayer.Boundary,
            layer: this.boundaryLayer,
        });
        this.mapComponent.addLayer({
            key: MapLayer.Markers,
            layer: this.waterbodyLayer,
            onClick: (feature) => this.onClickMarker(feature),
            deselectOnClick: true,
        });
        this.mapComponent.addLayer({
            key: MapLayer.HighlightMarker,
            layer: this.waterbodyHoverLayer,
        });

        this.mapReady = true;
        this.maybeZoomMapToDashboardBoundary();
    }

    onClickUnits(units: 'CM' | 'IN') {
        if (this.lengthUnits == units) return;
        this.lengthUnits = units;
    }

    private onClickMarker(feature: Feature) {
        // NOTE(cg): The waterbody that is hovered over isn't always the same as the feature we get here (z-index issue?),
        //  so use the hovered id if it's available.
        const type = feature.get(FEATURE_PROPERTY_TYPE) as FeatureType;
        const id = this.hoveredWaterbodyId ?? feature.getId();
        const dashboard = type == 'WATERBODY' ? 'waterbody' : 'region';
        this.router.navigate(['/dashboards', this.dashboardId, dashboard, id], { relativeTo: this.route });
    }

    private buildWaterbodyData(waterbodies: DashboardWaterbodiesResponse[]) {
        const source = this.waterbodyLayer.getSource();

        const tableData: WaterbodyStat[] = [];
        for (const waterbody of waterbodies ?? []) {
            if (!waterbody.lat || !waterbody.lon) {
                console.info(`No lat/lon for ${waterbody.name} (${waterbody.id})`);
                continue;
            }

            const feature = new Feature({
                geometry: new Point(fromLonLat([waterbody.lon, waterbody.lat])),
            });
            feature.setId(waterbody.id);
            feature.set(FEATURE_PROPERTY_NAME, waterbody.name);
            feature.set(FEATURE_PROPERTY_TYPE, 'WATERBODY')
            source.addFeature(feature);

            const metric = feature.clone();
            metric.setId(`${feature.getId()}-metric`);
            metric.set(FEATURE_PROPERTY_WATERBODY_ID, feature.getId());
            metric.set('radius', 0);
            this.metricSource.addFeature(metric);

            const stat: WaterbodyStat = {
                id: waterbody.id,
                name: waterbody.name,
                lat: waterbody.lat,
                lon: waterbody.lon,
                anglers: waterbody.anglers,
                trips: waterbody.trips,
                zeros: waterbody.zeros,
                fish: waterbody.catches,
                hoursSpent: waterbody.hours_out,
                catchRate: waterbody.rate,
            };

            const updateMinMax = (type: MetricType, value: number) => {
                const current = this.waterbodyMetricMinMax[type];
                this.waterbodyMetricMinMax[type].min = Math.min(current.min, value);
                this.waterbodyMetricMinMax[type].max = Math.max(current.max, value);
            };

            updateMinMax('ANGLERS', stat.anglers);
            updateMinMax('TRIPS', stat.trips);
            updateMinMax('ZEROS', stat.zeros);
            updateMinMax('FISH', stat.fish);
            updateMinMax('HOURS_SPENT', stat.hoursSpent);
            updateMinMax('CATCH_RATE', stat.catchRate);

            this.waterbodyIdToTableDataIndex[stat.id] = tableData.length;

            tableData.push(stat);
        }

        this.tableData = tableData;
    }

    private maybeZoomMapToDashboardBoundary() {
        if (!this.mapReady || this.loadingWaterbodies || this.loadingBoundaries) { return; }
        this.mapComponent.fitExtent([MapLayer.Boundary]);
    }

    private waterbodyTextStyle: Style | null = null;
    private buildTextStyleForWaterbody(feature: Feature): Style | null {
        const metric = this.activeMetric;
        if (metric == null) return null;

        if (this.waterbodyTextStyle == null) {
            this.waterbodyTextStyle = new Style({
                text: new Text({
                    font: 'bold 14px Cabin',
                    offsetX: 20,
                    textAlign: 'left',
                    backgroundFill: new Fill({
                        color: 'white',
                    }),
                    backgroundStroke: new Stroke({
                        color: COLORS['gray-100'],
                        width: 1,
                    }),
                    padding: [4, 8, 4, 8],
                }),
            });
        }

        let text = feature.get(FEATURE_PROPERTY_NAME);
        const type = feature.get(FEATURE_PROPERTY_TYPE) as FeatureType;
        if (type == 'WATERBODY' && metric.type != 'WATERBODIES') {
            const id = feature.getId();
            const tableDataIndex = this.waterbodyIdToTableDataIndex[id];
            const waterbodyStatProperty = METRIC_TYPE_TO_WATERBODY_STAT_PROPERTY[metric.type];
            const metricValue = this.tableData[tableDataIndex][waterbodyStatProperty] as number;
            const metricString = metricValue == 0 ? 'No' : metricValue.toString();
            let suffix: string;
            switch (metric.type) {
                case 'ANGLERS':
                    suffix = 'Angler';
                    if (metricValue != 1) suffix += 's';
                    break;
                case 'TRIPS':
                    suffix = 'Trip';
                    if (metricValue != 1) suffix += 's';
                    break;
                case 'ZEROS':
                    suffix = 'Zero Trip';
                    if (metricValue != 1) suffix += 's';
                    break;
                case 'FISH':
                    suffix = 'Fish Caught';
                    break;
                case 'HOURS_SPENT':
                    suffix = 'Hours Fished';
                    break;
                case 'CATCH_RATE':
                    suffix = 'Catch Rate';
                    break;
            }
            text += `: ${metricString} ${suffix}`;
        }

        this.waterbodyTextStyle.getText().setText(text);

        return this.waterbodyTextStyle;
    }

    // TODO: use style based on stat!?
    private animateMetricToStat(stat: Metric) {
        for (const id in this.waterbodyIdToAnimation) {
            const key = this.waterbodyIdToAnimation[id];
            unByKey(key);
        }

        const duration = 300;
        const start = Date.now();

        const tableData = this.tableData;
        const features = this.metricSource.getFeatures();
        for (const feature of features) {
            feature.set(FEATURE_PROPERTY_METRIC_TYPE, stat.type);

            const id = feature.get(FEATURE_PROPERTY_WATERBODY_ID);

            const prevListenerKey = this.waterbodyIdToAnimation[id];
            if (prevListenerKey) {
                unByKey(prevListenerKey);
            }

            const radiusFrom = feature.get(FEATURE_PROPERTY_RADIUS) || 0;
            const radiusTo = this.getMetricRadiusForStatType(id, stat.type, tableData) || 0;

            const animate = (event: RenderEvent) => {
                const frameState = event.frameState;
                const elapsed = frameState.time - start;

                const t = Math.min(elapsed / duration, 1);
                const radius = Math.max(lerp(radiusFrom, radiusTo, easeOut(t)), 0);
                feature.set(FEATURE_PROPERTY_RADIUS, radius);

                if (t == 1) {
                    const listenerKey = this.waterbodyIdToAnimation[id];
                    if (listenerKey) {
                        unByKey(listenerKey);
                    }

                    delete this.waterbodyIdToAnimation[id];
                }

                this.map.render();
            };

            // TODO: requestAnimationFrame

            const listenerKey = this.mapComponent.tileLayer.on('postrender', animate) as EventsKey;
            this.waterbodyIdToAnimation[id] = listenerKey;
        }

        this.map.render();
    }

    private getMetricRadiusForStatType(id: number, type: MetricType, tableData: WaterbodyStat[]): number | null {
        const tableDataIndex = this.waterbodyIdToTableDataIndex[id];
        const waterbodyStatProperty = METRIC_TYPE_TO_WATERBODY_STAT_PROPERTY[type];
        const metric = tableData[tableDataIndex][waterbodyStatProperty] as number;
        if (metric == 0) return 0;

        const metricRadiusMin = 12;
        const metricRadiusMax = 74;
        let radius: number;
        if (metric <= 1) {
            radius = metricRadiusMin;
        } else {
            const max = this.waterbodyMetricMinMax[type].max;
            radius = lerp(metricRadiusMin + 1, metricRadiusMax, metric / max);
        }

        return radius;
    }
}


function validateTotals(name: string, totals: DashboardWaterbodiesResponseTotals) {
    if (totals.ids) {
        let numIds = 0;
        const ids = new Map<number | string, number>();
        for (const id in totals.ids) {
            const count = ids.get(id) ?? 0;
            ids.set(id, count + 1);

            numIds += 1;
        }
        if (numIds != totals.trips) {
            console.error(`${name} (${totals.trips}), mismatch trip count (${numIds})`);
        }
        for (const [id, count] of ids.entries()) {
            if (count > 1) {
                console.error(`${name}, duplicate trip ${id}`);
            }
        }
    }
}
