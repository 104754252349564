import { AfterViewInit, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Stroke, Style } from 'ol/style';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';
import { Feature } from 'ol';

export function makeBoundaryStyles(color: string) {
  return {
    Polygon: new Style({
      stroke: new Stroke({
        color,
        width: 3,
      }),
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color,
        width: 3,
      }),
    }),
    LineString: new Style({
      stroke: new Stroke({
        color,
        width: 3,
      }),
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color,
        width: 3,
      }),
    }),
    Point: new Style({
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color,
          width: 3,
        }),
      }),
    }),
  }
}

export const BOUNDARY_MAP_STYLES = makeBoundaryStyles('#4FA2C8');

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857',
});

@Component({
  selector: 'app-map-boundary',
  templateUrl: './map-boundary.component.html',
  styleUrls: ['./map-boundary.component.scss'],
})
export class MapBoundaryComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() boundary: string;
  @Input() boundaries: string[];
  @Input() fitExtent = true;
  @Input() isVisible = true;

  private isReady = false;

  constructor(@Inject(EmptyMapComponent) private parent: EmptyMapComponent) {}

  ngAfterViewInit() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      style: (feature) => {
        return BOUNDARY_MAP_STYLES[feature.getGeometry().getType()];
      },
    });
    this.parent.addLayer({ key: MapLayer.Boundary, layer: layer });
    this.isReady = true;
    this.load();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.Boundary);
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.isReady) {
      this.load();
    }
  }

  private load() {
    this.parent.getLayer(MapLayer.Boundary).subscribe((layer) => {
      const source = layer.getSource();
      source.clear();

      if (this.boundary) {
        const boundary = DECODER.readFeature(this.boundary);
        if (boundary instanceof Feature) {
          source.addFeature(boundary);
        } else {
          console.warn(`Failed to decode boundary: ${this.boundary}`);
        }
      }

      if (this.boundaries) {
        this.boundaries.forEach((element) => {
          const boundary = DECODER.readFeature(element);
          if (boundary instanceof Feature) {
            source.addFeature(boundary);
          } else {
            console.warn(`Failed to decode boundary: ${element}`);
          }
        });
      }

      if (this.fitExtent) {
        this.parent.fitExtent();
      }

      layer.setVisible(this.isVisible);
    });
  }
}
