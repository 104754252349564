import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@aa/models/user';

interface PartialUser {
  first_name?: User['first_name'];
  last_name?: User['last_name'];
  username?: User['username'];
  display_name?: User['display_name'];
}

@Pipe({
  standalone: true,
  name: 'fullnameOrUsername',
})
export class FullnameOrUsernamePipe implements PipeTransform {
  transform(
    user: PartialUser | undefined | null,
    short: boolean = false,
    acceptDisplayName: boolean = true,
  ): string | undefined | null {
    return fullnameOrUsername(user, short, acceptDisplayName);
  }
}

@Pipe({
  standalone: true,
  name: "fullNameWithDisplayName",
})
export class FullNameWithDisplayName implements PipeTransform {
  private static titlecase = new TitleCasePipe();

  transform(user: PartialUser | undefined | null): string | undefined | null {
    return fullNameWithDisplayName(user, FullNameWithDisplayName.titlecase);
  }
}

export function fullNameWithDisplayName(user: PartialUser | undefined | null, titlecase?: TitleCasePipe): string | undefined | null {
  if (user == null) return null;
  if (user == undefined) return undefined;

  let result = "";

  const first = user.first_name?.toString()?.trim();
  const last = user.last_name?.toString()?.trim();
  const display = user.display_name?.toString()?.trim();
  const username = user.username?.toString()?.trim();

  if (first && last) {
    result = `${first} ${last}`;
    if (titlecase) {
      result = titlecase.transform(result);
    }
  }

  if (display) {
    if (result && result.toLowerCase() != display.toLowerCase()) {
      result += ` (${display})`;
    } else {
      result = display;
    }
  }

  if (!result && username) {
    result = username;
  }

  return result;
}

export function fullnameOrUsername(
  user: PartialUser | undefined | null,
  short: boolean = false,
  acceptDisplayName: boolean = true,
): string | undefined | null {
  if (user == null) {
    return null;
  }
  if (user == undefined) {
    return undefined;
  }

  if (user.display_name && acceptDisplayName) {
    return user.display_name.toString().trim();
  }

  if (user.first_name && user.last_name) {
    const first = user.first_name.toString().trim();
    const last = user.last_name.toString().trim();

    if (short) {
      return (
        first[0].toUpperCase() +
        first.substring(1).toLowerCase() +
        ' ' +
        last[0].toUpperCase() +
        '.'
      );
    } else {
      return first + ' ' + last;
    }
  }

  return user.username?.toString()?.trim();
}
