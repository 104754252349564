@if (navigating | async) {
  <div class="my-4 w-full flex justify-center">
    <svg-icon name="spinner" svgClass="animate-spin size-20" />
  </div>
} @else {
  <h4 class="text-gray-400">{{ subtitle }}</h4>

  <div class="flex justify-end my-4 items-center">
    @if (regionNames.length) {
      <dash-dropdown #waterbodyDropdown class="shrink-0 w-full max-w-xs" [selectedValue]="activeRegionIndex != -1 ? regionNames[activeRegionIndex] : ''">
        @for (region of regionNames; track region) {
          <dash-dropdown-option [text]="region" [selected]="$index == activeRegionIndex" (click)="onClickRegion(waterbodyDropdown, $index)" />
        }
      </dash-dropdown>
      <div class="grow"></div>
    }
    <dash-dropdown #mapTypeDropdown class="shrink-0 w-full max-w-xs" [selectedValue]="activeMapTypeIndex != -1 ? mapTypes[activeMapTypeIndex] : ''">
      @for (type of mapTypes; track type) {
        <dash-dropdown-option [text]="type" [selected]="$index == activeMapTypeIndex" (click)="onClickMapType(mapTypeDropdown, type, $index)" />
      }
    </dash-dropdown>
  </div>
  <div class="flex border border-gray-100 rounded overflow-hidden">
    <div #mapStatsContainer class="bg-white shrink-0 grow w-full max-w-xs flex flex-col relative">
      @for (metric of metrics; track metric.title) {
        <dash-stat-card
          [icon]="metric.icon"
          [colour]="metric.colour"
          [count]="metricCounts[metric.type]"
          [title]="metric.title"
          [clickable]="true"
          [active]="activeMetricIndex == $index"
          [loading]="loadingTotals"
          class="w-full h-28 border-b-gray-100 transition-opacity {{ delays[$index] }}"
          [class.opacity-0]="loadingTotals"
          [class.border-b]="!$last"
          (click)="onClickStat($index)"
        />
      }
      @if (loadingTotals) {
        <div class="absolute inset-0 flex flex-col items-center justify-center gap-y-2">
          <p class="h4">Loading Totals</p>
          <svg-icon name="spinner" svgClass="animate-spin size-20"></svg-icon>
        </div>
      }
    </div>
    <div class="grow bg-gray-50 relative">
      <div [style.height.px]="mapHeightPx">
        <app-empty-map
          [mapType]="mapTypes[activeMapTypeIndex]"
          [interactionRestrictionsEnabled]="false"
          [additionalPadding]="[15, 15, 15, 15]"
          (mapReady)="onMapReady($event)"
        />
      </div>

      @if (loadingWaterbodies) {
        <div class="absolute size-full inset-0 bg-gray-800/30 flex items-center justify-center animate-pulse">
          <svg-icon name="spinner" svgClass="animate-spin size-14 fill-white" />
        </div>
      }
      @if (activeMetric?.legendTitle) {
        <div class="absolute bottom-4 right-4 bg-white rounded border border-gray-100 px-4 py-3 flex items-center space-x-4">
          <div class="w-7 h-7 rounded-full border" [ngClass]="activeMetric.legendMarkerClass"></div>
          <span class="text-body-small">{{ activeMetric.legendTitle }}</span>
        </div>
      }
    </div>
  </div>

  @if (catchData.length) {
    <div class="h-14"></div>
    <dash-lengths-histogram
      [dashboard]="dashboard | async"
      [catchData]="catchData"
      [catchDataRange]="catchDataRange"
      [lengthUnits]="lengthUnits"
      (lengthUnitsChange)="onClickUnits($event)"
      />
  }

  @if ((dashboard | async)?.hasHourlyAccess) {
    <div class="h-14"></div>
    <dash-hourly-breakdown
			[dashboardId]="(dashboard | async).id"
			[timezones]="(dashboard | async).timezones"
			[logicalBoundaryId]="regionId"
			/>
  }

  @if (loadingWaterbodies) {
    <div class="h-14"></div>
    <div class="w-full flex items-center space-x-2">
      <h3 class="shrink-0">All Stats by Waterbody</h3>
      <svg-icon name="spinner" svgClass="animate-spin size-4"></svg-icon>
    </div>
  } @else if (tableData) {
    <div class="h-14"></div>
    <div class="w-full flex items-center space-x-2">
      <h3 class="grow">All Stats by Waterbody</h3>
      <button
        class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
        type="button"
        (click)="onClickExportData(allStatsTable, false)"
      >
        Export Data
      </button>
      @if (exportCatchesCsvLink) {
        <a
          [href]="exportCatchesCsvLink"
          class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
          type="button"
        >
          Export Catch Data
        </a>
      }
    </div>
    <div class="h-4"></div>
    <dash-table #allStatsTable [data]="tableData" [columns]="tableColumns" (clickElement)="onClickElement($event)" />
  }
  @if (loadingExtraTotals) {
    <div class="h-10"></div>
    <div class="w-full flex items-center space-x-2">
      <h3 class="shrink-0">Total Trips Summary</h3>
      <svg-icon name="spinner" svgClass="animate-spin size-4"></svg-icon>
    </div>
  } @else if (totalsTableData?.length) {
    <div class="h-10"></div>
    <div class="w-full flex items-center space-x-2">
      <h3 class="shrink-0">Total Trips Summary</h3>
      <button
        class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
        type="button"
        title="Info"
        (click)="onClickTotalsInfoButton()"
      >
        <svg-icon name="info" class="size-6" />
      </button>
      <div class="grow"></div>
      <button
        class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
        type="button"
        (click)="onClickExportData(summaryTable, true)"
      >
        Export Data
      </button>
    </div>
    <div class="h-4"></div>
    <dash-table
      #summaryTable
      [data]="totalsTableData"
      [columns]="totalsTableColumns"
      [borderAboveRowIndex]="totalsTableData.length - 1"
      [rowsClickable]="totalsTableRowsClickable"
      (clickRow)="onClickTotalsTableRow($event)"
    />
  }
}

<ng-template #totalsIdsDialog>
  <div class="bg-white border border-gray-100 rounded overflow-hidden px-4 py-2 flex flex-col gap-y-4 w-[800px] max-w-full">
    <div class="w-full flex items-center space-x-2">
      <h3 class="grow">{{ idsDialogTitle }}</h3>
      <button
        class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
        type="button"
        title="Close"
        (click)="onClickCloseIdsDialog()"
      >
        <svg-icon name="close" class="size-6" />
      </button>
    </div>
    <dash-table [data]="idsTableData" [columns]="idsTableColumns">
      <ng-template dashTableCustomContent let-element>
        <a [href]="element.url" target="_blank">View on admin.anglersatlas.com</a>
      </ng-template>
    </dash-table>
    <button
      class="h-10 truncate bg-purple text-white border rounded px-4 py-2 text-button hover:bg-purple-dark disabled:bg-gray-50 disabled:text-gray-300"
      type="button"
      (click)="onClickCopyIds()"
    >
      @if (copiedIds()) {
        Copied!
      } @else {
        Copy All Ids
      }
    </button>
  </div>
</ng-template>

<ng-template #totalsInfoDialog>
  <div class="bg-white border border-gray-100 rounded overflow-hidden px-4 py-2 flex flex-col gap-y-6">
    <div class="w-full flex items-center space-x-2">
      <h3 class="grow">Info</h3>
      <button
        class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
        type="button"
        title="Close"
        (click)="onClickCloseTotalsInfoDialog()"
      >
        <svg-icon name="close" class="size-6" />
      </button>
    </div>
    <div class="max-w-lg rounded border border-gray-100 px-2 pt-1 pb-2 relative">
      <h5>Valid Trips</h5>
      <span class="absolute top-0 right-0 bg-green/10 rounded-bl px-2 py-1">Shown on dashboard</span>
      <p class="mt-2 ml-4 leading-snug">A trip is considered valid when:</p>
      <ul class="mt-2 ml-4">
        <li>- has a duration between 15 minutes and 24 hours</li>
        <li>- has a catch rate not exceeding 15</li>
        <li>- has location data that that intersects with a waterbody</li>
        <li>- the user tracked their trip (gps coordinates along their route), has catches or the user specified they caught nothing</li>
      </ul>
    </div>
    <div class="max-w-lg rounded border border-gray-100 px-2 pt-1 pb-2 relative">
      <h5>Valid Trips; Unknown Waterbody</h5>
      <span class="absolute top-0 right-0 bg-red/10 rounded-bl px-2 py-1"><b>NOT</b> shown on dashboard</span>
      <p class="mt-2 ml-4 leading-snug">Valid trips that don't intersect with a waterbody.<br />Some reasons include:</p>
      <ul class="mt-2 ml-4">
        <li>- we don't have the waterbody in our databse</li>
        <li>- the user recorded their trip when they got home</li>
        <li>- the user was testing the app</li>
      </ul>
      <p class="mt-2 ml-4 leading-snug">
        We review these trips and add new waterbodies, causing those trips to be moved to the valid list, as well as manually invalidate trips as needed.
      </p>
    </div>
    <div class="max-w-lg rounded border border-gray-100 px-2 pt-1 pb-2 relative">
      <h5>Invalid Trips</h5>
      <span class="absolute top-0 right-0 bg-red/10 rounded-bl px-2 py-1"><b>NOT</b> shown on dashboard</span>
      <p class="mt-2 ml-4">
        A trip is invalid if it fails any of the checks described in the valid trips or,<br />
        has been manually reviewed and marked as invalid.
      </p>
    </div>
    <div class="h-4"></div>
  </div>
</ng-template>
