import { Component } from '@angular/core';
import { AuthenticationService } from '@aa/authentication/authentication.service';

@Component({
    selector: 'dash-home',
    templateUrl: './home.component.html',
})
export class HomeComponent {
    isLoggedIn = this.authService.isLoggedIn$;
    constructor(private authService: AuthenticationService) {
    }
}
