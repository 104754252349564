import { Component, Inject, OnDestroy, AfterViewInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';
import { CatchLog } from '@aa/models/creel-survey';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Icon } from 'ol/style';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { take } from 'rxjs';

const FORMAT = new GeoJSON({ featureProjection: 'EPSG:3857' });

@Component({
  selector: 'app-map-model-catch-logs',
  templateUrl: './map-model-catch-logs.component.html',
  styleUrls: ['./map-model-catch-logs.component.scss'],
})
export class MapModelCatchLogsComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() logs: CatchLog[];

  @Output() selected = new EventEmitter<CatchLog>();

  private ready = false;


  constructor(@Inject(EmptyMapComponent) private parent: EmptyMapComponent) {
  }

  ngAfterViewInit() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        image: new Icon({
          size: [34, 34],
          src: '/assets/markers/catch-log.svg'
        })
      })
    });

    this.parent.addLayer({
      key: MapLayer.ModelCatchLogs,
      layer: layer,
      onClick: (it) => { this.selected.emit(it.get('catchLog')); },
      deselectOnClick: true,
    });

    this.ready = true;
    this.bindMarkers();
  }

  ngOnChanges() {
    this.bindMarkers();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.ModelCatchLogs);
  }

  private bindMarkers() {
    if (!this.ready) { return; }

    this.parent.getLayer(MapLayer.ModelCatchLogs)
      .pipe(take(1))
      .subscribe((layer) => {

        const source = layer.getSource();
        source.clear();

        for (const log of this.logs) {
          if (!log.exact_location) { continue; }

          const id = 'tripCatchLog-' + log.id;
          const geometry = FORMAT.readGeometry(log.exact_location);

          const feature = new Feature(geometry);
          feature.setId(id);
          feature.set('catchLog', log, true);

          source.addFeature(feature);
        }
      });
  }
}
