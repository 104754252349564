<div class="flex my-4">
  <h3 class="grow truncate">Catch Length Distribution</h3>
  <!-- TODO: stop from changing size depending on selection -->
  <dash-dropdown #dropdown class="shrink-0" [selectedValue]="activeChartIndex != -1 ? chartNames[activeChartIndex] : ''">
    @for (name of chartNames; track name) {
      <dash-dropdown-option [text]="name" [selected]="$index == activeChartIndex" (click)="onSelectChart(dropdown, $index)" />
    }
  </dash-dropdown>
</div>
<div class="border border-gray-100 rounded overflow-hidden bg-white p-4">
  <div class="w-full pl-20 flex text-blue font-medium text-xl">
    <span>Minimum: {{ chartDataRanges[activeChartIndex].min }}</span>
    <span class="grow text-center">Average: {{ chartDataRanges[activeChartIndex].avg }}</span>
    <span>Maximum: {{ chartDataRanges[activeChartIndex].max }}</span>
  </div>
  <canvas baseChart type="bar" [data]="chartData" [options]="chartOptions" (chartClick)="onChartClick($event)"></canvas>
  <div class="flex" [class.justify-between]="userIsAdmin" [class.justify-end]="!userIsAdmin">
    @if (userIsAdmin) {
      <div class="flex w-40 border border-gray-100 rounded bg-white overflow-hidden text-gray-400 text-sm">
        <button
          class="transition-colors grow px-4 py-2 flex items-center justify-center hover:cursor-pointer hover:bg-purple-faint hover:text-purple text-button"
          (click)="onClickOpenIdsDialog('creel-survey')"
        >
          Trips
        </button>
        <button
          class="transition-colors grow px-4 py-2 flex items-center justify-center hover:cursor-pointer hover:bg-purple-faint hover:text-purple text-button"
          (click)="onClickOpenIdsDialog('catch-log')"
        >
          <span>Catches</span>
        </button>
      </div>
    }

    <div class="flex items-center gap-x-2">
      @if (outlierCatches[activeChartIndex].length) {
        <button class="px-4 py-2 transition font-semibold text-[14px] leading-[130%] hover:cursor-pointer"
                (click)="onClickViewInvalidCatches()">
          View Outliers ({{outlierCatches[activeChartIndex].length}})
        </button>
      }

      <div class="flex w-40 border border-gray-100 rounded bg-gray-25 overflow-hidden text-gray-200 text-sm">
        <button
          class="transition-colors grow px-4 py-2 flex items-center justify-center hover:cursor-pointer active:bg-purple/25"
          [class.hover:bg-purple-faint]="lengthUnits != 'CM'"
          [class.hover:text-purple]="lengthUnits != 'CM'"
          [class.hover:font-bold]="lengthUnits != 'CM'"
          [class.text-white]="lengthUnits == 'CM'"
          [class.bg-purple]="lengthUnits == 'CM'"
          (click)="onClickUnits('CM')"
        >
          CM
        </button>
        <button
          class="transition-colors grow px-4 py-2 flex items-center justify-center hover:cursor-pointer active:bg-purple/25"
          [class.hover:bg-purple-faint]="lengthUnits != 'IN'"
          [class.hover:text-purple]="lengthUnits != 'IN'"
          [class.hover:font-bold]="lengthUnits != 'IN'"
          [class.text-white]="lengthUnits == 'IN'"
          [class.bg-purple]="lengthUnits == 'IN'"
          (click)="onClickUnits('IN')"
        >
          <span>IN</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #idsDialog>
  <div class="bg-white border border-gray-100 rounded overflow-hidden px-4 py-2 flex flex-col gap-y-4">
    <div class="w-full flex items-center space-x-2">
      <h3 class="grow">{{ idsDialogTitle }}</h3>
      <button
        class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
        type="button"
        title="Close"
        (click)="onClickCloseIdsDialog()"
      >
        <svg-icon name="close" class="size-6" />
      </button>
    </div>
    <dash-table [data]="idsTableData" [columns]="idsTableColumns">
      <ng-template dashTableCustomContent let-element>
        <a [href]="element.url" target="_blank">View on admin.anglersatlas.com</a>
      </ng-template>
    </dash-table>
    <button
      class="h-10 truncate bg-purple text-white border rounded px-4 py-2 text-button hover:bg-purple-dark disabled:bg-gray-50 disabled:text-gray-300"
      type="button"
      (click)="onClickCopyIds()"
    >
      @if (copiedIds()) {
        Copied!
      } @else {
        Copy All Ids
      }
    </button>
  </div>
</ng-template>
