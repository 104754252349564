import { Pipe, PipeTransform } from '@angular/core';
import { round } from '@aa/helpers/strings';

@Pipe({
  standalone: true,
  name: 'lengthUnit',
})
export class LengthUnitsPipe implements PipeTransform {
    transform(value: number, unit: 'CM' | 'IN'): string {
        const converted = convertLengthInCentimetersToUnit(value, unit);
        return `${converted} ${unit.toLowerCase()}`;
    }
}

export function convertLengthInCentimetersToUnit(lengthInCm: number, unit: 'CM' | 'IN', roundResult: boolean = true): number {
    let result = lengthInCm;
    if (unit == 'IN') {
        result *= 0.393701;
    }

    if (roundResult) {
        result = round(result, 1);
    }

    return result;
}

