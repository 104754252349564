import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { SvgPreloaderService, SVG_BASE_HREF, SVG_LIST } from './svg-preloader.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    SvgPreloaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: SvgPreloaderService) => {
        return () => service.loadIcons();
      },
      deps: [SvgPreloaderService],
      multi: true,
    }
  ]
})
export class SvgPreloaderModule {
  static forRoot(baseHref: string, icons: string[]): ModuleWithProviders<SvgPreloaderModule> {
    return {
      ngModule: SvgPreloaderModule,
      providers: [
        {
          provide: SVG_BASE_HREF,
          useValue: baseHref,
        },
        {
          provide: SVG_LIST,
          useValue: icons,
        },
        SvgPreloaderService,
      ],
    };
  }
}
