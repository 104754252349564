import { animate, state, style, transition, trigger } from "@angular/animations";
import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SvgIconComponent } from "angular-svg-icon";
import { Map, View } from "ol";
import { getCenter } from "ol/extent";
import ImageLayer from "ol/layer/Image";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import { Observable, Subscription } from "rxjs";

export type MediaDialogMedia = {
  kind: "PHOTO" | "VIDEO";
  src: string;
};

export type MediaData = {
  index: number;
  media: MediaDialogMedia[];
  notes?: string;
};

export type MediaDialogData = {
  mediaProvider: Observable<MediaData>;
  showNextPrev?: boolean;
  onClickNext?: () => void;
  onClickPrev?: () => void;
};

@Component({
  standalone: true,
  selector: "dash-media-dialog",
  templateUrl: "./media-dialog.component.html",
  imports: [
    CommonModule,
    SvgIconComponent,
    NgOptimizedImage,
  ],
  animations: [
    trigger('fadeInOut', [
      state('*', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(100)]),
      transition(':leave', [animate(200)]),
    ]),
  ],
})
export class MediaDialogComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild("map") mapElementRef: ElementRef<HTMLElement>;

  index: number;
  media: MediaDialogMedia[] = [];
  notes?: string;

  showBlurredImage = false;

  private mapTargetSet = false;
  private map: Map;
  private imageLayer: ImageLayer<Static>;

  private subscription = Subscription.EMPTY;

  constructor(
    @Inject(DIALOG_DATA) public data: MediaDialogData,
    private ref: DialogRef<MediaDialogComponent>
  ) {
  }

  ngOnInit() {
    this.imageLayer = new ImageLayer();
    this.map = new Map({
      layers: [this.imageLayer],
      controls: [],
    });

    this.subscription = this.data.mediaProvider.subscribe((data) => {
      this.index = data.index;
      this.media = data.media;
      this.notes = data.notes;
      this.onClickMedia(this.index, true);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.maybeSetMapTarget();
  }

  ngAfterViewChecked() {
    this.maybeSetMapTarget();
  }

  private maybeSetMapTarget() {
    if (this.mapTargetSet) return;

    const el = this.mapElementRef?.nativeElement;
    if (el && el.offsetWidth && el.offsetHeight) {
      this.map.setTarget(el);
      this.mapTargetSet = true;

      this.onClickMedia(this.index, true);
    }
  }

  onClickMedia(index: number, force: boolean = false) {
    if (!force && this.index == index) return;

    this.index = index;
    const media = this.media[index];
    const isPhoto = media.kind == "PHOTO";
    if (isPhoto) {
      const image = new Image();
      image.onload = () => {
        const extent = [0, 0, image.naturalWidth, image.naturalHeight];

        const projection = new Projection({
          code: `blotchy-image-${index}`,
          units: "pixels",
          extent,
        });

        const view = new View({
          projection,
          center: getCenter(extent),
          zoom: 2,
          maxZoom: 8,
        });

        const source = new Static({
          url: media.src,
          projection,
          imageExtent: extent,
        });
        source.on('imageloadend', () => {
          this.showBlurredImage = false;
        });

        this.imageLayer.setSource(source);
        this.map.setView(view);
      };

      image.src = media.src;
    }
    this.showBlurredImage = isPhoto;
  }

  onClickClose() {
    this.ref.close();
  }

  onClickNext() {
    const f = this.data.onClickNext;
    if (f) { f(); }
  }

  onClickPrev() {
    const f = this.data.onClickPrev;
    if (f) { f(); }
  }
}
