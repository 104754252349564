export function propertiesAreTheSame(
  property: string,
  a: any,
  b: any
): boolean {
  if (a.hasOwnProperty(property) !== b.hasOwnProperty(property)) {
    return false;
  }
  return a[property] === b[property];
}

export function clone<T>(obj: T): T {
  const result = obj ? { ...obj } : obj;
  return result;
}

export function cloneArray<T>(
  array: T[] | undefined | null,
  cloneFunc: (it: T) => T = (it) => clone(it),
): T[] | undefined | null {
  if (array == null) return array;

  const type = array.length ? typeof array[0] : null;
  if (type === 'string' || type === 'number' || type === 'boolean') {
    return array.slice();
  }

  return array.map((it) => cloneFunc(it));
}


/**
 * From T, pick a set of properties whose keys are in the union K, and make all properties in T optional that are not in the union K
 * i.e. Make all properties optional, except for ones you define
 */
export type PartialExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>