import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmptyMapComponent } from './empty-map/empty-map.component';
import { MapBoundaryComponent } from './map-boundary/map-boundary.component';
import { MapCatchLogsComponent } from './map-catch-logs/map-catch-logs.component';
import { MapCreateMarkerComponent } from './map-create-marker/map-create-marker.component';
import { MapHighlightMarkerComponent } from './map-highlight-marker/map-highlight-marker.component';
import { MapHighlightPlaceComponent } from './map-highlight-place/map-highlight-place.component';
import { MapIsLoadingComponent } from './map-is-loading/map-is-loading.component';
import { MapMarkersComponent } from './map-markers/map-markers.component';
import { MapModelCatchLogsComponent } from './map-model-catch-logs/map-model-catch-logs.component';
import { MapNamedPointComponent } from './map-named-point/map-named-point.component';
import { MapOverlayComponent } from './map-overlay/map-overlay.component';
import { MapPlacesComponent } from './map-places/map-places.component';
import { MapRcaLayerComponent } from './map-rca-layer/map-rca-layer.component';
import { MapSponsoredMarkersComponent } from './map-sponsored-markers/map-sponsored-markers.component';
import { MapSubareaLayerComponent } from './map-subarea-layer/map-subarea-layer.component';
import { MapTournamentsComponent } from './map-tournaments/map-tournaments.component';
import { MapTripLocationComponent } from './map-trip-location/map-trip-location.component';
import { MapTripLogsComponent } from './map-trip-logs/map-trip-logs.component';
import { MapUserLocationComponent } from './map-user-location/map-user-location.component';
import { DateTimePipe, ToDatePipe, ToDateTimePipe } from '@aa/pipes/to-date.pipe';
import { MapPlacesInAreaComponent } from './map-places-in-area/map-places-in-area.component';

// NOTE(cg): This used to be in the environment file... Should it go back?
export const MAP_TILES = [
  {
    name: 'Roads',
    url: 'https://tiles.anglersatlas.com/osm/{z}/{x}/{y}.png',
    icon: '/assets/icons/roadmap.svg',
    iconActive: '/assets/icons/roadmap-active.svg',
    attributions: '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    requiresLogin: false,
    logo: null,
  },
  {
    name: 'Bathymetry',
    url: 'https://tiles.anglersatlas.com/bathymetry/{z}/{x}/{y}.png',
    icon: '/assets/icons/terrain.svg',
    iconActive: '/assets/icons/terrain-active.svg',
    attributions: '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    requiresLogin: true,
    logo: null,
  },
  {
    name: 'Satellite',
    url: 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg?access_token=pk.eyJ1IjoiYW5nbGVyc2F0bGFzIiwiYSI6ImNsbXBteGxvaTBnbXkyd3JxaTk0YjU4bG8ifQ.AVG2l_bYGirfHPX51wIq_w',
    icon: '/assets/icons/satellite.svg',
    iconActive: '/assets/icons/satellite-active.svg',
    attributions: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    requiresLogin: true,
    logo: '<a href="http://mapbox.com/about/maps" class="mapbox-logo" target="_blank"><img src="assets/images/mapbox-logo-white.svg" style="width:65px;height:20px"></a>',
  },
];

@NgModule({
  declarations: [
    MapHighlightPlaceComponent,
    EmptyMapComponent,
    MapPlacesComponent,
    MapIsLoadingComponent,
    MapBoundaryComponent,
    MapMarkersComponent,
    MapTripLogsComponent,
    MapCatchLogsComponent,
    MapHighlightMarkerComponent,
    MapSponsoredMarkersComponent,
    MapCreateMarkerComponent,
    MapPlacesInAreaComponent,
    MapNamedPointComponent,
    MapUserLocationComponent,
    MapTripLocationComponent,
    MapModelCatchLogsComponent,
    MapTournamentsComponent,
    MapOverlayComponent,
    MapRcaLayerComponent,
    MapSubareaLayerComponent,
  ],
  exports: [
    MapHighlightPlaceComponent,
    EmptyMapComponent,
    MapPlacesComponent,
    MapIsLoadingComponent,
    MapBoundaryComponent,
    MapMarkersComponent,
    MapTripLogsComponent,
    MapCatchLogsComponent,
    MapHighlightMarkerComponent,
    MapSponsoredMarkersComponent,
    MapCreateMarkerComponent,
    MapPlacesInAreaComponent,
    MapNamedPointComponent,
    MapUserLocationComponent,
    MapTripLocationComponent,
    MapModelCatchLogsComponent,
    MapTournamentsComponent,
    MapOverlayComponent,
    MapRcaLayerComponent,
    MapSubareaLayerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DateTimePipe,
    ToDatePipe,
    ToDateTimePipe,
  ],
})
export class MapModule {
}

