import { MapModule } from '@aa/map/map.module';
import { LengthUnitsPipe } from '@aa/pipes/length-unit.pipe';
import { DialogModule } from '@angular/cdk/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardsListComponent } from './dashboards-list/dashboards-list.component';
import { LengthsHistogramComponent, TournamentRegionWaterbodyDashboardDialogComponent } from './lengths-histogram/lengths-histogram.component';
import { StatCardComponent, StatCardCountDirective, StatCardTitleDirective } from './stat-card/stat-card.component';
import { TableComponent, TableCustomContentDirective } from './table/table.component';
import { TournamentRegionDashboardComponent } from './tournament-region-dashboard/tournament-region-dashboard.component';
import { TournamentRegionWaterbodyDashboardComponent } from './tournament-region-waterbody-dashboard/tournament-region-waterbody-dashboard.component';
import { HourlyBreakdownComponent } from './hourly-breakdown/hourly-breakdown.component';
import { DropdownComponent, DropdownOptionComponent } from './dropdown/dropdown.component';
import { DateTimePipe, ToDateTimePipe } from '@aa/pipes/to-date.pipe';

@NgModule({
    declarations: [
        StatCardComponent,
        StatCardCountDirective,
        StatCardTitleDirective,
        TableComponent,
        TableCustomContentDirective,
        DashboardsListComponent,
        TournamentRegionDashboardComponent,
        TournamentRegionWaterbodyDashboardComponent,
        TournamentRegionWaterbodyDashboardDialogComponent,
        LengthsHistogramComponent,
        HourlyBreakdownComponent,
    ],
    exports: [
        StatCardComponent,
        StatCardCountDirective,
        StatCardTitleDirective,
        TableComponent,
        TableCustomContentDirective,
        DashboardsListComponent,
        TournamentRegionDashboardComponent,
        TournamentRegionWaterbodyDashboardComponent,
        HourlyBreakdownComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AngularSvgIconModule,
        CdkTableModule,
        ScrollingModule,
        MapModule,
        LengthUnitsPipe,
        BaseChartDirective,
        DialogModule,
        FormsModule,
        DropdownComponent,
        DropdownOptionComponent,
        DateTimePipe,
        ToDateTimePipe,
    ],
})
export class ComponentsModule {
}
