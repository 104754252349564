import { Component, Input, OnChanges, SimpleChanges, Inject, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Feature, Feature as OpenLayerFeature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { EmptyMapComponent, MapLayer } from '../empty-map/empty-map.component';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Icon, Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857'
});

@Component({
  selector: 'app-map-sponsored-markers',
  templateUrl: './map-sponsored-markers.component.html',
  styleUrls: ['./map-sponsored-markers.component.scss'],
})
export class MapSponsoredMarkersComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() markers: any; //geojson

  @Output() selected = new EventEmitter<any>(); // html

  private isReady = false;

  constructor(
    @Inject(EmptyMapComponent) private parent: EmptyMapComponent,
  ) { }

  ngAfterViewInit() {
    const onClick = (feature: OpenLayerFeature) => {
      this.selected.emit(feature.get('body'));
    }

    const layer = new VectorLayer({
      source: new VectorSource(),
      style: function (feature) {
        const style = feature.get('style');
        if (style.icon) {
          // TODO: need imgSize if icon is SVG
          return new Style({
            image: new Icon({
              src: style.icon
            })
          });
        }
        return new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({ color: 'orange' }),
            stroke: new Stroke({ color: 'black', width: 1 })
          })
        });
      }
    });
    this.parent.addLayer({
      key: MapLayer.SponsoredMarkers,
      layer: layer,
      onClick: onClick,
      deselectOnClick: true,
    });

    this.isReady = true;
    this.loadMarkers();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.SponsoredMarkers);
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.isReady) {
      this.loadMarkers();
    }
  }

  private loadMarkers() {
    this.parent.getLayer(MapLayer.SponsoredMarkers).subscribe(layer => {
      if (!layer) { return }

      const source = layer.getSource();
      source.clear();

      if (this.markers && this.markers.hasOwnProperty('type')) {
        const features: Feature[] = [];
        const decoded = DECODER.readFeatures(this.markers);
        for (const it of decoded) {
          if (it instanceof Feature) {
            features.push(it);
          }
        }
        source.addFeatures(features);
      }
    });
  }
}
