import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './authorization-interceptor';

// TODO: If we go standalone, how does injection work?? i.e. scoping to just

export const CLIENT_ID = new InjectionToken<string>('aa-authentication-client-id');
export const CLIENT_SECRET = new InjectionToken<string>('aa-authentication-client-secret');

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    AuthenticationService,
  ],
})
export class AuthenticationModule {
  static forRoot(clientId: string, clientSecret: string): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        { provide: CLIENT_ID, useValue: clientId },
        { provide: CLIENT_SECRET, useValue: clientSecret },
      ],
    };
  }
}
