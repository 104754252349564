<div class="flex justify-end my-4 items-center">
  <dash-dropdown #waterbodyDropdown class="shrink-0 w-full max-w-xs" [selectedValue]="activeWaterbodyIndex != -1 ? waterbodyNames[activeWaterbodyIndex] : ''">
    <dash-dropdown-option
      *ngFor="let waterbody of waterbodyNames; let index = index"
      [text]="waterbody"
      [selected]="index == activeWaterbodyIndex"
      (click)="onClickWaterbody(waterbodyDropdown, index)"
    >
    </dash-dropdown-option>
  </dash-dropdown>
  <div class="grow"></div>
  <dash-dropdown
    #mapTypeDropdown
    *ngIf="!loading && (navigating | async) !== true"
    class="shrink-0 w-full max-w-xs"
    [selectedValue]="activeMapTypeIndex != -1 ? mapTypes[activeMapTypeIndex] : ''"
  >
    <dash-dropdown-option
      *ngFor="let type of mapTypes; let index = index"
      [text]="type"
      [selected]="index == activeMapTypeIndex"
      (click)="onClickMapType(mapTypeDropdown, type, index)"
    />
  </dash-dropdown>
</div>

@if ((navigating | async) === true) {
  <div class="my-4 w-full flex justify-center">
    <svg-icon name="spinner" svgClass="animate-spin size-20" />
  </div>
} @else {
  <div class="flex border border-gray-100 rounded overflow-hidden">
    <div #mapStatsContainer class="bg-white shrink-0 grow w-full max-w-xs flex-col">
      <dash-stat-card
        *ngFor="let metric of metrics; last as isLast; index as index"
        [icon]="metric.icon"
        [colour]="metric.colour"
        [count]="metricCounts[metric.type]"
        [title]="metric.title"
        [loading]="loading || loadingWaterbodies"
        [class.border-b]="!isLast"
        class="w-full h-28 border-b-gray-100"
      >
      </dash-stat-card>
    </div>
    <div class="grow bg-gray-50 relative">
      <div [style.height.px]="mapHeightPx">
        <app-empty-map
          [mapType]="mapTypes[activeMapTypeIndex]"
          [interactionRestrictionsEnabled]="false"
          [additionalPadding]="[15, 15, 15, 15]"
          (mapReady)="onMapReady($event)"
        />
      </div>
    </div>
  </div>

  @if (userIsAdmin) {
    <div class="flex justify-end">
      <div class="mt-4 flex items-center space-x-4 rounded border border-gray-100 bg-white px-4 py-2">
        <h4>Admin Controls:</h4>
        @for (kind of markerKinds; track kind) {
          <div class="flex items-center space-x-2">
            <input
              id="show-{{ kind }}"
              type="checkbox"
              [checked]="markerLayers[kind].visible"
              (change)="toggleMarkerLayerVisible(markerLayers[kind])"
              [disabled]="markerLayers[kind].numMarkers == 0"
            />
            <label for="show-{{ kind }}"> {{ kind | titlecase }} ({{ markerLayers[kind].numMarkers }}) </label>
          </div>
        }
        <button
          class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
          type="button"
          title="Info"
          (click)="onClickAdminControlsInfoButton()"
        >
          <svg-icon name="info" class="size-6 fill-gray-400" svgClass="fill-grey-400" />
        </button>
      </div>
    </div>
  }

  @if (catchData?.length) {
    <div class="h-14"></div>
    <dash-lengths-histogram
      [dashboard]="dashboard"
      [catchData]="catchData"
      [lengthUnits]="lengthUnits"
      (lengthUnitsChange)="onClickUnits($event)"
      [waterbodyName]="this.activeWaterbodyIndex != -1 ? this.waterbodyNames[this.activeWaterbodyIndex].toLowerCase() : null"
    />
  }
  
  @if (dashboard?.hasHourlyAccess) {
    <div class="h-14"></div>
    <dash-hourly-breakdown
			[dashboardId]="dashboard.id"
			[timezones]="dashboard.timezones"
			[waterbodyId]="waterbodyId"
			/>
  }

  @if (tableData) {
    <div class="h-14"></div>
    <div class="flex items-center space-x-2">
      <h3 class="grow truncate">Trip Data</h3>
      <div class="form-input pr-0 h-10 flex items-center bg-white border border-gray-100 rounded">
        <svg-icon name="search" class="size-6" />
        <input
          #speciesSearch
          type="search"
          placeholder="Search by species"
          class="bg-transparent border-0 placeholder:text-gray-300 focus:ring-0"
          (input)="doSearch()"
          (keyup)="doSearch()"
          (click)="doSearch()"
        />
      </div>
      <button
        class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
        type="button"
        (click)="onClickExportTripData(table)"
      >
        Export Trip Data
      </button>
      @if (exportCatchesCsvLink) {
        <a
          [href]="exportCatchesCsvLink"
          class="h-10 truncate bg-white border border-gray-100 rounded px-4 py-2 text-button hover:bg-gray-25 disabled:bg-gray-50 disabled:text-gray-300"
          type="button"
        >
          Export Catch Data
        </a>
      }
    </div>
    <div class="h-4"></div>
    <dash-table #table [data]="tableData" [columns]="tableColumns" [filters]="tableFilters | async"> </dash-table>
  }
}

<ng-template #adminControlsInfoDialog>
  <div class="bg-white border border-gray-100 rounded overflow-hidden px-4 py-2 flex flex-col gap-y-6 min-w-96">
    <div class="w-full flex items-center space-x-2">
      <h3 class="grow">Admin Controls</h3>
      <button
        class="size-10 flex items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-800/10"
        type="button"
        title="Close"
        (click)="onClickCloseAdminControlsInfoDialog()"
      >
        <svg-icon name="close" class="size-6" />
      </button>
    </div>
    <div class="max-w-lg rounded border border-gray-100 p-4 relative flex flex-col space-y-4">
      <h5>Map Legend</h5>
      <div class="flex items-center space-x-4">
        <div class="relative">
          <img src="/assets/markers/boat.png" class="size-6" />
          <img src="/assets/markers/fish-purple.png" class="size-6 absolute top-0 left-3" />
        </div>
        <h6>Trips/Catches shown in both</h6>
      </div>
      <div class="flex items-center space-x-4">
        <div class="relative">
          <img src="/assets/markers/boat-yellow.png" class="size-6" />
          <img src="/assets/markers/fish.png" class="size-6 absolute top-0 left-3" />
        </div>
        <h6>Trips/Catches shown only in totals</h6>
      </div>
      <div class="flex items-center space-x-4">
        <div class="relative">
          <img src="/assets/markers/boat-red.png" class="size-6" />
          <img src="/assets/markers/fish-red.png" class="size-6 absolute top-0 left-3" />
        </div>
        <h6>Trips/Catches shown only in histogram</h6>
      </div>
    </div>
  </div>
</ng-template>
