

export const METRIC_TYPES = [
    'WATERBODIES',
    'ANGLERS',
    'TRIPS',
    'ZEROS',
    'FISH',
    'HOURS_SPENT',
    'CATCH_RATE',
] as const;

export type MetricType = typeof METRIC_TYPES[number];
export type Metric = {
    type: MetricType,

    icon: string;
    colour: string;
    title: string;

    legendTitle?: string;
    legendMarkerClass?: string;
};

export const METRICS: { [key in MetricType]: Metric } = {
    WATERBODIES: {
        type: 'WATERBODIES',
        icon: 'waterbody',
        colour: 'blue',
        title: 'Total Waterbodies'
    },
    ANGLERS: {
        type: 'ANGLERS',
        icon: 'profile',
        colour: 'green',
        title: 'Total Anglers',
        legendTitle: '# of Anglers',
        legendMarkerClass: 'border-green bg-green/10'
    },
    TRIPS: {
        type: 'TRIPS',
        icon: 'trip-boat',
        colour: 'purple',
        title: 'Total Trips',
        legendTitle: '# of Trips',
        legendMarkerClass: 'border-purple bg-purple/10'
    },
    ZEROS: {
        type: 'ZEROS',
        icon: 'fish-no',
        colour: 'gray-600',
        title: 'Total Zero Trips',
        legendTitle: '# of Zero Trips',
        legendMarkerClass: 'border-gray-600 bg-gray-600/10'
    },
    FISH: {
        type: 'FISH',
        icon: 'fish',
        colour: 'yellow',
        title: 'Total Fish Caught',
        legendTitle: '# of Fish Caught',
        legendMarkerClass: 'border-yellow bg-yellow/10'
    },
    HOURS_SPENT: {
        type: 'HOURS_SPENT',
        icon: 'clock',
        colour: 'gray-800',
        title: 'Total Hours Fished',
        legendTitle: 'Hours Fished',
        legendMarkerClass: 'border-gray-800 bg-gray-800/10'
    },
    CATCH_RATE: {
        type: 'CATCH_RATE',
        icon: 'hook',
        colour: 'red',
        title: 'Total Catch Rate',
        legendTitle: 'Catch Rate',
        legendMarkerClass: 'border-red bg-red/10'
    },
};
