<button [cdkMenuTriggerFor]="dropdownMenu"
        type="button"
        class="w-full relative bg-white border border-gray-100 rounded pl-4 pr-2 h-10 text-left overflow-x-clip disabled:bg-gray-50 disabled:text-gray-300"
        [ngClass]="dropdownClass"
        [disabled]="disabled ? true : null">
    <span class="truncate pr-10">
        {{ selectedValue }}
    </span>
    <span class="ml-2 absolute right-0 inset-y-0 flex items-center pointer-events-none">
        <svg-icon name="arrow-down"
                  class="size-6"
                  [svgClass]="disabled ? 'fill-gray-300' : 'fill-gray-400'"></svg-icon>
    </span>
</button>
<ng-template #dropdownMenu>
    <div cdkMenu
         class="max-h-96 bg-white border border-gray-100 rounded text-left mt-2 overflow-y-auto">
         <ng-content></ng-content>
    </div>
</ng-template>

