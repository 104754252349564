import { AuthenticationModule } from '@aa/authentication/authentication.module';
import { MapModule } from '@aa/map/map.module';
import { FullnameOrUsernamePipe } from '@aa/pipes/fullname-or-username.pipe';
import { DateTimePipe, ToDateTimePipe } from '@aa/pipes/to-date.pipe';
import { SvgPreloaderModule } from '@aa/svg-preloader/svg-preloader.module';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from "@environments/environment";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PointWithErrorBar, ScatterWithErrorBarsController } from 'chartjs-chart-error-bars';
import Annotation from 'chartjs-plugin-annotation';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { colors } from '@aa/tailwind.colors';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ErrorComponent } from './error/error.component';
import { DashboardsComponent } from './landing/dashboards/dashboards.component';
import { HomeComponent } from './landing/home/home.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './landing/login/login.component';
import { SVGS } from '@aa/svg-iconset';
import { AppService } from './app.service';

export const COLORS = colors;
export const CHART_GRID_COLOR = COLORS['gray-100'];
export const CHART_FONT_COLOR = COLORS['gray-400'];
export const CHART_FONT = {
  family: 'Cabin',
  size: 20,
  weight: 500,
  lineHeight: 1.25,
};
export const CHART_SCALES_FONT = { ...CHART_FONT, lineHeight: 1.4 };

const API_URL = environment.apiUrl;
const API_CLIENT_ID = environment.apiClientId;
const API_CLIENT_SECRET = environment.apiClientSecret;

export const acceptVersionInterceptor: HttpInterceptorFn = (req, next) => {
  const cloned = req.clone({ setHeaders: { Accept: 'application/json; version=v18;' } });
  return next(cloned);
}

export const apiUrlInterceptor: HttpInterceptorFn = (req, next) => {
  let request = req;
  if (request.url.charAt(0) === '/' && !request.url.startsWith('/assets')) {
    request = req.clone({ url: API_URL + req.url });
  }
  return next(request);
}

export const encryptIdsInterceptor: HttpInterceptorFn = (req, next) => {
  const app = inject(AppService);

  let request = req;

  const idsVisible = app.idsVisible;
  if (idsVisible) {
    const params = req.params.set('ids_visible', 1);
    request = req.clone({ params });
  }
  return next(request);
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DashboardsComponent,
    LoginComponent,
    HomeComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    SvgPreloaderModule.forRoot('assets/svg-icon', SVGS),
    AuthenticationModule.forRoot(API_CLIENT_ID, API_CLIENT_SECRET),
    MapModule,
    CdkTableModule,
    CdkMenuModule,
    ComponentsModule,
    FullnameOrUsernamePipe,
    DateTimePipe,
    ToDateTimePipe,
    AuthenticationModule,
  ],
  providers: [
    provideHttpClient(withInterceptors([apiUrlInterceptor, acceptVersionInterceptor, encryptIdsInterceptor])),
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useValue: apiUrlInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useValue: acceptVersionInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useValue: encryptIdsInterceptor,
    //   multi: true,
    // },
    provideCharts(withDefaultRegisterables([ScatterWithErrorBarsController, PointWithErrorBar, Annotation]))
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
