import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Feature as OpenLayerFeature, Overlay } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { combineLatest } from 'rxjs';
import { getIcon, getOffset, isPlace, Place, PlaceSearchResult } from '@aa/models/place';
import { EmptyMapComponent, MapLayer, MapOverlay } from '../empty-map/empty-map.component';

const DECODER = new GeoJSON({
  featureProjection: 'EPSG:3857',
});

@Component({
  selector: 'app-map-places',
  templateUrl: './map-places.component.html',
  styleUrls: ['./map-places.component.scss'],
})
export class MapPlacesComponent<T extends PlaceSearchResult | Place> implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('highlightElement') highlightElement: ElementRef;

  @Input() places: T[];
  @Input() shouldHover = true;
  @Input() useSmallStyle = false;
  @Input() shouldDeselect = false;

  @Output() placeSelected = new EventEmitter<T>();
  @Output() placeHovered = new EventEmitter<T>();

  private hoverOverlay: Overlay;
  private onHover: (feature: OpenLayerFeature) => void;
  private onClick: (feature: OpenLayerFeature) => void;
  private onDeselect: () => void;
  private isReady = false;

  constructor(@Inject(EmptyMapComponent) private parent: EmptyMapComponent) {}

  ngAfterViewInit() {
    this.hoverOverlay = new Overlay({
      id: MapOverlay.Places,
      element: this.highlightElement.nativeElement,
      autoPan: true,
    });

    if (this.shouldHover) {
      this.onHover = (feature: OpenLayerFeature) => {
        const place = feature?.get('place');
        if (place) {
          this.placeHovered.next(place);
        }

        this.parent.mapContainerElement.nativeElement.style.cursor = !!feature ? 'pointer' : '';

        if (!feature) {
          this.hoverOverlay.setPosition(undefined);
          return;
        }

        const id = feature.getId();
        combineLatest([this.parent.getLayer(MapLayer.Places), this.parent.getOverlay(MapOverlay.Places)]).subscribe(
          ([layer, overlay]) => {
            if (layer && layer.getSource().getFeatureById(id)) {
              overlay.getElement().getElementsByClassName('title')[0].innerHTML = place.name;
              if (this.useSmallStyle) {
                overlay.setOffset([0, 0]);
              } else {
                const small = place.supertype === 'CITY' || place.supertype === 'WATERBODY' || !isPlace(place);
                overlay.setOffset(getOffset(place, small));
              }
              overlay.setPosition((<Point>feature.getGeometry()).getCoordinates());

              return;
            }
          }
        );
      };
    }

    this.onClick = (feature: OpenLayerFeature) => {
      this.placeSelected.emit(feature.get('place'));
    };

    if (this.shouldDeselect) {
      this.onDeselect = () => {
        this.placeSelected.emit(null);
      };
    }

    const layer = new VectorLayer({
      source: new VectorSource(),
    });
    this.parent.addLayer({
      key: MapLayer.Places,
      layer: layer,
      onClick: this.onClick,
      onHover: this.onHover,
      onDeselect: this.onDeselect,
      deselectOnClick: true,
    });
    this.parent.addOverlay(this.hoverOverlay);

    this.isReady = true;
    this.loadPlaces();
  }

  ngOnDestroy() {
    this.parent.removeLayer(MapLayer.Places);
    this.parent.removeOverlay(MapOverlay.Places);
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.isReady) {
      this.loadPlaces();
    }
  }

  private loadPlaces() {
    this.parent.getLayer(MapLayer.Places).subscribe((layer) => {
      const source = layer.getSource();
      source.clear();
      const features = this.places?.map((place) => {
        let geometry: Geometry;
        if (place.hasOwnProperty('centroid')) {
          geometry = DECODER.readGeometry((<Place>place).centroid);
        } else {
          const preview = (<PlaceSearchResult>place).preview;
          const coords = [preview.longitude, preview.latitude];
          geometry = new Point(transform(coords, 'EPSG:4326', 'EPSG:3857'));
        }
        const feature = new OpenLayerFeature({
          geometry: geometry,
          place: place,
        });
        feature.setId('places-' + place.id);

        if (this.useSmallStyle) {
          feature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                stroke: new Stroke({
                  color: 'black',
                  width: 1,
                }),
                fill: new Fill({
                  color: '#6073EB',
                }),
              }),
            })
          );
        } else {
          feature.setStyle(getIcon(place, false));
        }

        return feature;
      });

      if (features?.length ?? 0) {
        source.addFeatures(features);
      }

      this.parent.fitExtent();
    });
  }
}
