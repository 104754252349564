<div class="shrink-0 w-12 h-12 rounded-full flex items-center justify-center" [ngClass]="iconBackgroundClasses">
  <svg-icon [name]="icon" [svgClass]="iconSvgClasses"></svg-icon>
</div>
<div class="grow flex flex-col">
  @if (countContent?.templateRef) {
    <ng-container [ngTemplateOutlet]="countContent.templateRef" />
  } @else {
    <div class="relative">
      <span class="flex items-center space-x-2" [class.invisible]="loading">
        <h3 class="text-gray-600">
          {{ count }}
        </h3>
        @if (extraCount) {
          <h6 class="text-gray-300">({{ extraCount }})</h6>
        }
      </span>
      <div *ngIf="loading" class="absolute inset-0 rounded-full bg-gray-300 w-20 h-2/3 animate-pulse"></div>
    </div>
  }
  @if (titleContent) {
    <ng-container [ngTemplateOutlet]="titleContent.templateRef" />
  } @else {
    <h5 class="text-gray-300 truncate" [class.animate-pulse]="loading">{{ title }}</h5>
  }
</div>
<div *ngIf="clickable" class="shrink-0 relative w-6 h-6 rounded-full outline outline-1 outline-gray-100" [class.invisible]="loading">
  <div *ngIf="active" class="absolute inset-1 rounded-full bg-gray-700"></div>
  <!-- TODO: Active selector thing -->
</div>
