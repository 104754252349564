import { colors as generatedColors } from './tailwind.colors.generated';

export const colors = {
  transparent: 'transparent',
  white: '#fff',
  black: '#000',
  purple: '#6073EB',
  'purple-faint': '#F0F1FD',
  'purple-dark': '#4B5AB7',
  red: '#F24D33',
  'red-dark': '#D8301C',
  'red-faint': '#FFAA90',
  'red-border': '#D8301C',
  blue: '#08B2E3',
  'blue-light': '#3acff8',
  'blue-super-light': '#89e2fb',
  'blue-dark': '#22283F',
  'blue-super-dark': '#191E2F',
  'blue-ultra-dark': '#111422',
  'blue-default': '#007EAC',
  green: '#1C8753',
  'green-light': '#16A05D',
  'green-super-light': '#1DC975',
  'green-ultra-light': '#24FF8A',
  'green-faint': '#EDF6F2',
  'green-border': '#C6E1D4',
  'green-400': '#55AC7A',
  teal: '#4FC0C4',
  'orange-300': '#FB9945',
  yellow: '#FF8800',
  'yellow-light': '#FFF3E5',
  'gray-25': '#F5F6FA',
  'gray-50': '#EBEDF2',
  'gray-100': '#D8DBE5',
  'gray-200': '#B4B9CC',
  'gray-300': '#9299B2',
  'gray-400': '#747C99',
  'gray-500': '#596180',
  'gray-600': '#414966',
  'gray-700': '#2C334D',
  'gray-800': '#1B2033',
  ...generatedColors,
};
