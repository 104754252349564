export const MONTHS: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const TWENTY_FOUR_HOUR_TO_TWELVE_HOUR: { [key: string]: string } = {
  '00': '12',
  '01': '1',
  '02': '2',
  '03': '3',
  '04': '4',
  '05': '5',
  '06': '6',
  '07': '7',
  '08': '8',
  '09': '9',
  '10': '10',
  '11': '11',
  '12': '12',
  '13': '1',
  '14': '2',
  '15': '3',
  '16': '3',
  '17': '5',
  '18': '6',
  '19': '7',
  '20': '8',
  '21': '9',
  '22': '10',
  '23': '11',
};

export const TWENTY_FOUR_HOUR_TO_AM_PM: { [key: string]: string } = {
  '00': 'AM',
  '01': 'AM',
  '02': 'AM',
  '03': 'AM',
  '04': 'AM',
  '05': 'AM',
  '06': 'AM',
  '07': 'AM',
  '08': 'AM',
  '09': 'AM',
  '10': 'AM',
  '11': 'AM',
  '12': 'PM',
  '13': 'PM',
  '14': 'PM',
  '15': 'PM',
  '16': 'PM',
  '17': 'PM',
  '18': 'PM',
  '19': 'PM',
  '20': 'PM',
  '21': 'PM',
  '22': 'PM',
  '23': 'PM',
};


export function convert24HourStringTo12HourString(time: string): string {
  const [hourString, minuteString] = time.split(':');
  const hour: any = TWENTY_FOUR_HOUR_TO_TWELVE_HOUR[hourString];
  const amPm: any = TWENTY_FOUR_HOUR_TO_AM_PM[hourString];
  return `${hour}:${minuteString} ${amPm}`;
}

/**
 * Convert bytes into a human readable string
 * Ex: 123.45 KB
 *
 * @param bytes size to convert
 * @param digits number of digits to print after decimal
 * @returns {string} human readable file size (B, KB, MB, GB)
 */
export function getFileSizeStringFromBytes(bytes: number, digits = 2): string {
  const units: string[] = ['B', 'KB', 'MB', 'GB'];
  let unit = 0;
  while (bytes >= 1024) {
    bytes /= 1024;
    unit++;
  }
  return `${bytes.toFixed(digits)} ${units[unit]}`;
}

export function titleCase(s: string): string {
  if (s.length > 0) {
    return s.replace(/_/g, ' ').replace(/\w\S*/g, txt => {
      return txt[0].toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return '';
}

export function nl2br(str: string, isXhtml = false): string {
  //  discuss at: http://locutus.io/php/nl2br/
  // original by: Kevin van Zonneveld (http://kvz.io)
  // improved by: Philip Peterson
  // improved by: Onno Marsman (https://twitter.com/onnomarsman)
  // improved by: Atli Þór
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Maximusya
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Kevin van Zonneveld (http://kvz.io)
  // bugfixed by: Reynier de la Rosa (http://scriptinside.blogspot.com.es/)
  //    log-input by: Brett Zamir (http://brett-zamir.me)
  //   example 1: nl2br('Kevin\nvan\nZonneveld')
  //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
  //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false)
  //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
  //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true)
  //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
  //   example 4: nl2br(null)
  //   returns 4: ''
  // Some latest browsers when str is null return and unexpected null value
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  // Adjust comment to avoid issue on locutus.io display
  const breakTag =
    isXhtml || typeof isXhtml === 'undefined' ? '<br ' + '/>' : '<br>';
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  );
}

// Taken from http://stackoverflow.com/a/3890175/2881586
export function linkifyStringOld(inputText: string): string {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  // URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank" rel="nofollow">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank" rel="nofollow">$2</a>'
  );

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
}

const _ESCAPED_CHARS: [RegExp, string][] = [
  [/&/g, '&amp;'],
  [/"/g, '&quot;'],
  [/'/g, '&apos;'],
  [/</g, '&lt;'],
  [/>/g, '&gt;']
];

function _escapeXml(inputText: string): string {
  return _ESCAPED_CHARS.reduce(
    (text: string, entry: [RegExp, string]) => text.replace(entry[0], entry[1]),
    inputText
  );
}

export function encodeBody(str: string, escapeXml: boolean = true): string {
  if (escapeXml) {
    str = _escapeXml(str);
  }
  return '<p>' + nl2br(linkifyStringOld(str)).split('<br>').join('</p><p>') + '</p>';
}

const youtube = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
export function matchYouTubeUrls(str?: string): string[] | null {
  if (str) {
    const urls = str.match(youtube);
    if (urls) {
      const ids: string[] = [];
      for (const url of urls) {
        ids.push(url.replace(youtube, '$5'));
      }
      return ids;
    }
  }

  return null;
}

/**
 * Determine if the current user is a robot
 */
export function isRobot(): boolean {
  return /prerender|bot|googlebot|crawler|spider|robot|crawling/i.test(
    navigator.userAgent
  );
}

// =================================================================================================
//  Below taken from Chromium research
//
//  This is the code used to linkify text that appears in the console, but compressed down to just
//  what we need.
// =================================================================================================
/*
 * Copyright (C) 2012 Google Inc. All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 *     * Redistributions of source code must retain the above copyright
 * notice, this list of conditions and the following disclaimer.
 *     * Redistributions in binary form must reproduce the above
 * copyright notice, this list of conditions and the following disclaimer
 * in the documentation and/or other materials provided with the
 * distribution.
 *     * Neither the name of Google Inc. nor the names of its
 * contributors may be used to endorse or promote products derived from
 * this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */
const MaxLengthToIgnoreLinkifier = 10000;
export function linkifyString(string: string): string {
  let result = '';
  const linkStringRegEx = /(?:[a-zA-Z][a-zA-Z0-9+.-]{2,}:\/\/|data:|www\.)[\w$\-_+*'=\|\/\\(){}[\]^%@&#~,:;.!?]{2,}[\w$\-_+*=\|\/\\({^%@&#~]/;
  const pathLineRegex = /(?:\/[\w\.-]*)+\:[\d]+/;

  while (string && string.length < MaxLengthToIgnoreLinkifier) {
    const linkStrings =
      linkStringRegEx.exec(string) || pathLineRegex.exec(string);
    if (!linkStrings) {
      break;
    }

    const linkString = linkStrings[0];
    const linkIndex = string.indexOf(linkString);
    result += string.substring(0, linkIndex);

    const title = linkString;
    const realURL = linkString.startsWith('www.')
      ? 'http://' + linkString
      : linkString;
    result += `<a href="${realURL}" target="_blank" rel="nofollow">${title}</a>`;

    string = string.substring(linkIndex + linkString.length, string.length);
  }

  if (string) {
    result += string;
  }

  return result;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#A_better_solution
export function round(number: number, precision: number = 0) {
  const shift = function (n: number, exponent: number) {
    const numArray = ('' + n).split('e');
    return +(
      numArray[0] +
      'e' +
      (numArray[1] ? +numArray[1] + exponent : exponent)
    );
  };
  return shift(Math.round(shift(number, +precision)), -precision);
}

export function numberWithCommas(n: number): string {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function prettyList(list: string[]): string {
  if (list.length === 0) {
    return '';
  }

  const last = list.pop();

  if (list.length === 0) {
    return last ?? '';
  }

  return list.join(', ') + ' and ' + last;
}

// UUID function https://gist.github.com/LeverOne/1308368
export function uuid(): string {
  let a: any;
  let b: any;
  for(b=a='';a++<36;b+=a*51&52?(a^15?8^Math.random()*(a^20?16:4):4).toString(16):'-');
  return b;
}

let idCounter = 0;
export function uniqueId(): string {
  idCounter += 1;
  return `${idCounter}`;
}

export function uniq(a: any) {
  const prims = {
    'boolean': {},
    'number': {},
    'string': {}
  }
  const objs: any[] = [];

  return a.filter((item: any) => {
    const type = typeof item;
    if (type in prims) {
      return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
    } else {
      return objs.indexOf(item) >= 0 ? false : objs.push(item);
    }
  });
}
