// update this file by running "npm run optimize"
export const SVGS: string[] = [
  'add',
  'admin',
  'android',
  'apple',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'calendar',
  'camera',
  'check',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock',
  'close',
  'computer',
  'copy',
  'device-unknown',
  'download',
  'email',
  'eye-close',
  'eye-open',
  'facebook',
  'filter',
  'fish-hero',
  'fish-logged',
  'fish-no',
  'fish-release',
  'fish-unknown',
  'fish',
  'flag',
  'fullscreen',
  'history',
  'hook',
  'info',
  'instagram',
  'layers',
  'length',
  'list',
  'location',
  'lock-open',
  'lock',
  'logo',
  'mail',
  'map',
  'menu',
  'minus',
  'money',
  'more-vertical',
  'move_down',
  'move_up',
  'pause',
  'pencil',
  'pending',
  'phone',
  'play-video',
  'play',
  'prize',
  'profile-person',
  'profile',
  'refresh',
  'research',
  'review',
  'robot',
  'rotate-left',
  'rotate-right',
  'ruler',
  'search',
  'solid-arrow-down',
  'solid-arrow-up-down',
  'solid-arrow-up',
  'sort-asc',
  'sort-desc',
  'sort',
  'spinner',
  'star',
  'switch-account',
  'tag',
  'ticket',
  'time',
  'trash',
  'trip-boat',
  'trophy',
  'undo',
  'up-down',
  'upload',
  'vertical_align_bottom',
  'vertical_align_top',
  'warning',
  'waterbody-ok',
  'waterbody-unknown',
  'waterbody',
  'youtube',
  'zero'
];