<ng-container *ngIf="dashboards.length">
    <div class="flex flex-col space-y-2">
        <h4 class="text-subheader">DASHBOARDS</h4>
        <ng-container *ngFor="let dashboard of dashboards">
            <ng-template *ngTemplateOutlet="dashboardItem; context: dashboard "></ng-template>
        </ng-container>
    </div>
</ng-container>

<ng-template #dashboardItem
             let-id="id"
             let-logoUrl="logoUrl"
             let-name="name">
    <div class="flex items-center h-12 space-x-4 px-2 hover:cursor-pointer hover:bg-gray-100 rounded"
         (click)="onClickDashboard(id)">
        <img *ngIf="logoUrl"
             [src]="logoUrl"
             class="h-10 w-10 shrink-0 outline bg-white outline-gray-100 rounded-full" />
        <h5 class="text-gray-400 truncate">{{ name }}</h5>
    </div>
</ng-template>